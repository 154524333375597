import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import '../css/ViewCarriers.css'

// ICONS
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LifeAnnuityButtons from "./ViewCarrierComponents/LifeAnnuityBttons";
import EditIcon from '@mui/icons-material/Edit';
import { CleaningServices, Close, ExpandMore } from "@mui/icons-material";
import BackIcon from "../../assets/back-icon.png";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// DIALOG
import DeleteCarrierLevelPopUp from "./ViewCarrierDialogs/DeleteCarrierLevelPopup";
import DeleteCarriarSectionConfirmationDialog from "./ViewCarrierDialogs/DeleteCarriarSectionConfirmationDialog";
import LevelCustomizationDialog from "./ViewCarrierDialogs/LevelCustomizationDialog";
import UnsavedChangesDialog from "./ViewCarrierDialogs/UnsavedChangesDialog";
import GlobalDialog from "../../components/global/GlobalDialog";
import DeleteCarrierSectionDialog from "./ViewCarrierDialogs/DeleteCarrierSectionDialog";
import ResourcesAccordians from "./CarrierComponents/ResourcesAccordians";
import CarrierPDFViewerDialog from "./CarrierComponents/CarrierPDFViewerDialog";
import CriteriaDialog from "./ViewCarrierDialogs/CriteriaDialog";
import FileUploadDialog from "./ViewCarrierDialogs/FileUploadDialog";
import DeleteResourceAlertDialog from "./ViewCarrierDialogs/DeleteResourceAlertDialog";
import ViewCarrierPreviewDialog from "./ViewCarrierDialogs/ViewCarrierPreviewDialog";

// MUI 
import {
  Link,
  Divider as MuiDivider,
  Typography,
  Grid,
  TextField,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
  useMediaQuery,
  Backdrop,
  Tooltip,
  ClickAwayListener,
  Dialog,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import Loader from "../Loader/loader";
import styled from "@emotion/styled";
import Popover from '@mui/material/Popover';

// API
import { CheckCarrierExistence, CreateCarrierAPI, GetCarrierData, GetCarrierDetailsField, GetCarrierLevelColorList, GetCarrierVersionListData, getCriteriaGenericDataAPI, UpdateCarrierAPI } from "../../utils/api/CarriersApi";
import { DeleteResources, GetCarrierDataList } from "../../utils/api/TemplateApi";
import { RevokeDelegateAccessForAdmin } from "../../utils/api/userDetailApi";

// CONTEXT
import { Context as authContext } from "../../contexts/reducerContexts/authContext";
import { Context as templateContext } from "../../contexts/reducerContexts/templateContext";
import { Context as CarrierContext } from "../../contexts/reducerContexts/globalCarrierContext"


const Divider = styled(MuiDivider)(spacing);
const CustomLabelTypography = styled(Typography)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  padding-top:4.5px
`;


const ViewCarrier = () => {

  // CONTEXT
  const { FlagApiLoader, UpdateSnackbarMessage } = useContext(authContext)
  const { userDetail, apiLoader, carrierTypeDetailsForAgency } = useContext(authContext).state
  const { setCarrierData } = useContext(templateContext)
  const { carrierData } = useContext(templateContext).state


  const {
    carrierName,
    criteriaGenericData,
    genericStateList,
  } = useContext(CarrierContext).state

  const {
    setGenericStateList,
    setCriteriaGenericData
  } = useContext(CarrierContext)

  // CONST
  const location = useLocation();
  const navigate = useNavigate();
  const minScreenWidth = useMediaQuery('(max-width:1280px)')
  const PathName = window.location.pathname

  // REF
  const tableContainerRef = useRef(null)
  const tableRef = useRef(null);
  const textFieldRef = useRef(null);

  const subAgency = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance"
  const brokersAllianceAdmin = (userDetail[0]?.user_role_name == "Admin" && userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() === "brokers alliance")
  const userRoleAdmin = userDetail[0]?.user_role_name == "Admin"
  const userRoleMember = userDetail[0]?.user_role_name == "Member"
  const agencyNameBrokersAlliance = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance"
  const brokersAllianceAgent = userDetail[0]?.user_role_name == "Agent"
  const subAgencyAdmin = userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" && userDetail[0]?.user_role_name == "Admin"

  // LOCAL VARIABLE
  const [editDetails, setEditDetails] = useState(window.location.pathname == "/carriers/add_carrier" ? true : false)
  const [carrierVersionList, setCarrierVersionList] = useState()
  const [carrierDetails, setCarrierDetails] = useState({ carrier_details: { carrier_data_column_left: [], carrier_data_column_right: [] } })
  const [carrierNameDetailsDuplicate, setCarrierNameDetailsDuplicate] = useState({ carrier_details: { carrier_data_column_left: [], carrier_data_column_right: [] } })
  const [skipCheckOnBlur, setSkipCheckOnBlur] = useState(false);
  const [carrierExitenceErrorMessage, setCarrierExitenceErrorMessage] = useState("");
  const [flagCarrierNameExist, setFlagCarrierNameExist] = useState(false)
  const [carrierKeyDetails, setCarrierKeyDetails] = useState({
    carrier_data_column_left: [],
    carrier_data_column_right: []
  });
  const [carrierDetailsDuplicate, setCarrierDetailsDuplicate] = useState([])
  const [currentVersion, setCurrentVersion] = useState("")
  const [changeInCarrierType, setChangeInCarrierType] = useState(null)
  const [flagCall, setFlagCall] = useState("default")
  const [flagLevelTablePopup, setFlagLevelTablePopup] = useState(false)
  const [flagPdfPopup, setFlagPdfPopup] = useState(false)
  const [imageUrl, setImageUrl] = useState("")
  let [searchParams, setSearchParams] = useSearchParams();
  const [flagDeletePopup, setFlagDeletePopup] = useState(false);
  const [flagResourceExpand, setFlagResourceExpand] = useState(false);
  const [flagCarrierDetailsExpand, setFlagCarrierDetailsExpand] = useState(true);
  const [flagLevelAccordionExpanded, setFlagLevelAccordionExpanded] = useState(true)
  const [flagFileUploadPopup, setFlagFileUploadPopup] = useState(false);
  const [deleteResourceData, setDeleteresourceData] = useState({ carrier_detail: {}, file_detail: {} })
  const [carrierTypeId, setCarrierTypeId] = useState()
  const [AddColumn, setAddColumn] = useState(null)
  const [addRow, setAddRow] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addLevels, setAddLevels] = useState(null)
  const [anchorElForRow, setAnchorElForRow] = React.useState(null);
  const [anchorElForLevelsColumn, setAnchorElForLevelsColumn] = React.useState({ type: null, columnId: 0 });
  const [selectedRowGroupId, setSelectedRowGroup] = useState({ selectId: null, selectedId: null })
  const [selectedRowGroupIdOfDialog, setSelectedRowGroupOfDialog] = useState({ selectId: null, selectedId: null })
  const [isSelectedRow, setSelectedRow] = useState(null)
  const [flagDialogGloballyRender, setFlagDialogGloballyRender] = useState(false)
  const [flagAfterLevelPopoverRender, setFlagAfterLevelPopoverRender] = useState(false)


  // LEVEL FLOW:----------------------------
  const [levelSelectedData, setLevelSelectedData] = useState(null)
  const [colorList, setColorList] = useState([])
  const [selectedColor, setSelectedColor] = useState({ color: null, hexCode: null })
  const [sectionOrRow, setSectionOrRow] = useState('')
  const [emptyLevelName, setEmptyLevelName] = useState(false)

  // DIALOG:----------------------------
  const [flagDeleteCarriarSectionDialog, setFlagDeleteCarriarSectionDialog] = useState(false)
  const [flagCustomizationCarriarSectionDialog, setFlagCustomizationCarriarSectionDialog] = useState(false)
  const [flagUnsavedChangesDialog, setFlagUnsavedChangesDialog] = useState(false)
  const [selectedLine, setSelectedLine] = useState(null)
  const [selectedLinePopover, setSelectedLinePopover] = useState({ groups: null, index: null })
  const [selectedRowIndex, setSelectedRowIndex] = useState({ groupData: null, levelIdx: null, groupIdx: null })
  const [flagShowCloseLevelSection, setFlagShowCloseLevelSection] = useState(true)
  const [flagAddRow, setFlagAddRow] = useState(false)
  const [flagShowAddIcons, setFlagShowAddIcons] = useState(false)
  const [flagCriteriDialog, setFlagCriteriDialog] = useState(false)
  const [flagCarrierCustomizationGlobalDialog, setFlagCarrierCustomizationGlobalDialog] = useState(false)
  const [flagCarrierCustomizationDeleteDialog, setFlagCarrierCustomizationDeleteDialog] = useState(false)
  const [flagCarrierCustomizationGlobalDeleteDialog, setFlagCarrierCustomizationGlobalDeleteDialog] = useState(false)
  const [deleteRowPopupFlag, setDeleteRowPopupFlag] = useState(false)
  const defaultCriteriaText = {
    carrier_product_id: 0,
    carrier_product_id_custom: 0,
    carrier_product_data_id: 0,
    carrier_product_data_id_custom: 0,
  }
  const [showCriteriaText, setShowCriteriaText] = useState(defaultCriteriaText)
  const [productIds, setProductIds] = useState({ productId: null, productDataIds: null, productDataCustomIds: null, index: 0 })
  const [newLevelData, setNewLevelData] = useState({
    carrier_level_after: null,
    carrier_level_id: null,
    carrier_level_name: "",
    carrier_ba_level_flag: false,
    // carrier_level_sequence: 1,
    carrier_level_color_id: 1,
    carrier_level_color_hex_code: "#1b2430",
    carrier_level_color_name: "Default"
  })
  const [hideTransparentCell, setHideTransperentCell] = useState(false)
  const [deleteRow, setDeleteRow] = useState(null)
  const [carrierLevelLength, setCarrierLevelLength] = useState(false)
  const [slectedAddIcon, setSelectedAddIcon] = useState(null)
  const [isColHover, setColHover] = useState(false)
  const [currentlyAddedCols, setCurrentlyAddedCols] = useState([])

  const rowPopOveropen = Boolean(anchorElForRow);
  const rowId = rowPopOveropen ? 'simple-popover1' : undefined;
  const [sliderCoordinates, setSliderCoordinates] = useState({ x: 0, y: 0 })
  const [disableAddNewGroup, setDisableAddNewGroup] = useState(false)
  const numsOfCarrierLevelCols = carrierDetails?.carrier_level?.length
  const [needsRefocus, setNeedsRefocus] = useState(false);
  const [editingCell, setEditingCell] = useState(null);
  const [criteriaCellData, setCriteriaCellData] = useState(null);
  const [carrierPlaceholderName, setCarrierPlaceholderName] = useState('')



  //  Level Popover
  const columnCellPopoverOpen = Boolean(anchorElForLevelsColumn.type);
  const columnId = columnCellPopoverOpen ? 'simple-popover1' : undefined;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let leftSideHeaders = carrierDetails?.carrier_level?.filter((obj) => obj?.carrier_ba_level_flag == true)
  let rightSideHeaders = carrierDetails?.carrier_level?.filter((obj) => !obj?.carrier_ba_level_flag)
  let Levels
  if (leftSideHeaders && rightSideHeaders) {
    Levels = [...leftSideHeaders, ...rightSideHeaders]
  }

  // USE EFFECTS:---------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (needsRefocus && textFieldRef.current) {
      textFieldRef.current.focus();
      setNeedsRefocus(false); // Reset after focusing
    }
  }, [needsRefocus]);

  useEffect(() => {
    const carrierLevelLen = carrierDetails?.carrier_product?.length || 0;
    setCarrierLevelLength(carrierLevelLen < 2);

    const isSingleCarrierLevel = carrierLevelLen <= 1;
    setDisableAddNewGroup(isSingleCarrierLevel);
  }, [carrierDetails]);

  useEffect(() => {

    getCriteriaGenericDataAPI(
      (response) => {
        console.log("response of generic criteare", response);
        if (response?.status) {
          console.log("response of view carrier", response);
          const responseData = response?.data?.data
          setCriteriaGenericData(responseData)
        }
        else {
          setCriteriaGenericData([])
        }

      },
      (error) => {
        console.log("response of generic criteare", error);
      })


    if (window.location.pathname == "/carriers/add_carrier") {
      FlagApiLoader(true)
      GetCarrierDetailsField((res) => {
        if (res?.data?.status) {
          console.log("response true of GetCarrierDetailsField api", res);

          let carrier_details = {
            carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
            carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
          }

          setCarrierKeyDetails({ ...carrierKeyDetails, ...carrier_details });
          setCarrierDetails({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id, carrier_details: carrier_details,
            carrier_level: [],
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [],
                  }
                ]
              },
            ],
          })
          setCarrierDetailsDuplicate({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id, carrier_details: carrier_details,
            carrier_level: [

            ],
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [
                    ]
                  }
                ]
              },
            ],
          })
          setCarrierNameDetailsDuplicate({
            carrier_name: carrierName,
            carrier_type_id: location?.state?.carrier_type_id, carrier_details: carrier_details,
            carrier_level: [],
            carrier_product: [
              {
                carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                carrier_product_data: [
                  {
                    carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                    carrier_product_name: "",
                    carrier_product_note: "",
                    carrier_product_ba_total_comp: "",
                    carrier_product_commission_data: [
                    ]
                  }
                ]
              },
            ],
          })
          FlagApiLoader(false)
        }
        else {
          console.log("response false of GetCarrierDetailsField api", res);
          FlagApiLoader(false)
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
        (err) => {
          console.log("error of GetCarrierDetailsField api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!",
          });
          FlagApiLoader(false)
        })

      if (userRoleAdmin && agencyNameBrokersAlliance) {
        FlagApiLoader(true)
        GetCarrierLevelColorList(
          (res) => {
            if (res?.data?.status) {
              console.log("response true of GetCarrierLevelColorList api", res);
              setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
              FlagApiLoader(false)
            }
            else {
              console.log("response false of GetCarrierLevelColorList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
              FlagApiLoader(false)
            }
          },
          (err) => {
            console.log("error of GetCarrierLevelColorList api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!",
            });
            FlagApiLoader(false)
          })
      }
    }
    else {
      FlagApiLoader(true)
      let carrierIdExists = searchParams.has("id");
      let versionIdExists = searchParams.has("version_id");

      if (carrierIdExists) {
        let searchParamsCarrierId = searchParams.get("id");
        let searchParamsVersionId = searchParams.get("version_id");

        GetCarrierDataList(undefined,
          (res) => {
            if (res?.data?.status) {
              setCarrierData(res.data.data)
              // FlagApiLoader(false)
            }
            else {
              // FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
            }
          },
          (err) => {
            console.log("err", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something went wrong!",
            });
            // FlagApiLoader(false)
          }
        )

        // 1st call
        // if (userDetail[0]?.user_role_name == "Admin") {
        if (brokersAllianceAdmin && !subAgency) {
          // FlagApiLoader(true)
          GetCarrierVersionListData(searchParamsCarrierId,
            (res) => {
              if (res.data.status) {
                console.log("response true of GetCarrierVersionListData api", res);
                if (searchParamsVersionId) {
                  setCurrentVersion(Number(searchParamsVersionId))
                  // FlagApiLoader(false)
                }
                else {
                  res?.data?.data[0]?.carrier_version_data?.map((row) => {
                    if (row.carrier_version_latest) {
                      setCurrentVersion(row?.carrier_version_id)
                      setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                    }
                  })
                  // FlagApiLoader(false)
                }
                setCarrierVersionList(res.data.data[0].carrier_version_data)
                // FlagApiLoader(false)
              }
              else {
                console.log("response false of GetCarrierVersionListData api", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: res?.data?.error?.message
                  });
                }
                else {
                  UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!"
                  });
                }
                FlagApiLoader(false)
                navigate("/carriers")
              }
            },
            (err) => {
              console.log("error of GetCarrierVersionListData api", err);
              FlagApiLoader(false)
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              navigate("/carriers")
            }
          )
        }

        // 2nd Call
        if (userRoleAdmin && agencyNameBrokersAlliance) {
          // FlagApiLoader(true)
          GetCarrierLevelColorList((res) => {
            if (res?.data?.status) {
              console.log("response true of GetCarrierLevelColorList api", res);
              setColorList(res?.data?.data?.sort((a, b) => a.carrier_level_color_id - b.carrier_level_color_id))
              // FlagApiLoader(false)
            }
            else {
              console.log("response false of GetCarrierLevelColorList api", res);
              if (res?.data?.error?.message) {
                UpdateSnackbarMessage({
                  status: "error",
                  message: res?.data?.error?.message
                });
              }
              else {
                UpdateSnackbarMessage({
                  status: "error",
                  message: "Something Went Wrong!"
                });
              }
            }
          },
            (err) => {
              console.log("error of GetCarrierLevelColorList api", err);
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              // FlagApiLoader(false)
            })
        }

        GetCarrierDetailsField((res) => {
          if (res?.data?.status) {
            console.log("response true of GetCarrierDetailsField api", res);
            let carrier_details = {
              carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
              carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
            }
            setCarrierKeyDetails({ ...carrierKeyDetails, ...carrier_details });
          }
          else {
            console.log("response false of GetCarrierDetailsField api", res);
            FlagApiLoader(false)
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
              FlagApiLoader(false)
            }
          }
        },
          (err) => {
            console.log("error of GetCarrierDetailsField api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!",
            });
            FlagApiLoader(false)
          })

        let obj = {
          carrier_id: searchParamsCarrierId,
          carrier_version_id: searchParamsVersionId,
        }

        // 3rd call
        setTimeout(() => {
          GetCarrierData(obj,
            (res) => {
              FlagApiLoader(false)
              if (res.data.status) {
                const responseData = res?.data?.data[0]
                const updatedCarrierDetails = {
                  ...res?.data?.data[0],
                  carrier_details: {
                    carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                    carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                  },
                  carrier_level: res?.data?.data[0]?.carrier_level?.length > 0 ? res?.data?.data[0].carrier_level : [],
                  carrier_product: res?.data?.data[0]?.carrier_product?.length > 0
                    ? res?.data?.data[0]?.carrier_product.map(product => ({
                      ...product,
                      carrier_product_data: product.carrier_product_data?.sort((a, b) =>
                        parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence)
                      )?.map(data => ({
                        ...data,
                        carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) =>
                          parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)
                        ),
                      })),
                    }))
                    : [
                      {
                        carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        carrier_product_data: [
                          {
                            carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                            carrier_product_name: "",
                            carrier_product_note: "",
                            carrier_product_ba_total_comp: "",
                            carrier_product_commission_data: res?.data?.data[0]?.carrier_level?.map(dt => ({
                              carrier_ba_level_flag: false,
                              carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                              carrier_level_id: dt?.carrier_level_id,
                              carrier_level_name: dt.carrier_level_name,
                              carrier_level_sequence: "",
                              carrier_product_commission_value: "",
                            })),
                            carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : []
                          }
                        ]
                      }
                    ]
                };
                setCarrierDetails(updatedCarrierDetails)
                setCarrierPlaceholderName(updatedCarrierDetails?.carrier_name)
                setCarrierNameDetailsDuplicate(updatedCarrierDetails)
                setCarrierDetailsDuplicate(JSON.parse(JSON.stringify(updatedCarrierDetails)))
                const carrier_type_id = res.data.data[0]?.carrier_type_id
                setCarrierTypeId(carrier_type_id)
              }
              else {
                if (res?.data?.error?.code === 9087) {
                  RevokeFunction()
                }
                else {
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: "Something Went Wrong!"
                    });
                  }
                  FlagApiLoader(false)
                  navigate("/carriers")
                }
              }
            },
            (err) => {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something went wrong!",
              });
              FlagApiLoader(false)
              navigate("/carriers")
            })
        }, 300);
      }
    }


  }, [])

  useEffect(() => {
    setChangeInCarrierType(null)
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }

    const fetchRequireData = async () => {
      if (flagCall != "default") {
        FlagApiLoader(true)
        let carrierIdExists = searchParams.has("id");
        let versionIdExists = searchParams.has("version_id");
        let latestVersionId = ""

        if (carrierIdExists) {
          let searchParamsCarrierId = searchParams.get("id");
          let searchParamsVersionId = searchParams.get("version_id");

          await GetCarrierDataList(undefined,
            (res) => {
              if (res.data.status) {
                console.log("GetCarrierDataList TRUE RESPONSE", res);
                setCarrierData(res.data.data)
                FlagApiLoader(false)
              }
              else {
                FlagApiLoader(false)
                console.log("GetCarrierDataList FALSE RESPONSE", res);
                if (res?.data?.error?.message) {
                  UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                }
                else {
                  UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                }
              }
            },
            (err) => {
              console.log("GetCarrierDataList ERROR RESPONSE", err);
              UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
              FlagApiLoader(false)
            }
          )

          // GetCarrierDetailsField((res) => {
          //   if (res?.data?.status) {
          //     console.log("response true of GetCarrierDetailsField api", res);

          //     let carrier_details = {
          //       carrier_data_column_left: res?.data?.data?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
          //       carrier_data_column_right: res?.data?.data?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
          //     }

          //     setCarrierKeyDetails({
          //       ...carrierKeyDetails,
          //       ...carrier_details
          //     });

          //   }
          //   else {
          //     console.log("response false of GetCarrierDetailsField api", res);
          //     FlagApiLoader(false)
          //     if (res?.data?.error?.message) {
          //       UpdateSnackbarMessage({
          //         status: "error",
          //         message: res?.data?.error?.message
          //       });
          //     }
          //     else {
          //       UpdateSnackbarMessage({
          //         status: "error",
          //         message: "Something Went Wrong!"
          //       });
          //       FlagApiLoader(false)
          //     }
          //   }
          // },
          //   (err) => {
          //     console.log("error of GetCarrierDetailsField api", err);
          //     UpdateSnackbarMessage({
          //       status: "error",
          //       message: "Something Went Wrong!",
          //     });
          //     FlagApiLoader(false)
          //   })

          // if (userDetail[0]?.user_role_name == "Admin") {
          if (brokersAllianceAdmin && !subAgency) {
            FlagApiLoader(true)
            await GetCarrierVersionListData(searchParamsCarrierId,
              (res) => {
                if (res.data.status) {
                  console.log("GetCarrierVersionListData TRUE RESPONSE", res);
                  if (searchParamsVersionId) {
                    res?.data?.data[0]?.carrier_version_data?.map((row) => {
                      if (row.carrier_version_latest) {
                        setCurrentVersion(row?.carrier_version_id)
                        setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                        latestVersionId = row?.carrier_version_id;
                        mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                      }
                    })
                  }
                  else {
                    res?.data?.data[0]?.carrier_version_data?.map((row) => {
                      if (row.carrier_version_latest) {
                        setCurrentVersion(row?.carrier_version_id)
                        setSearchParams({ id: searchParamsCarrierId, version_id: row?.carrier_version_id })
                        latestVersionId = row?.carrier_version_id;
                        mainDataCall(searchParamsCarrierId, row?.carrier_version_id)
                      }
                    })
                  }
                  setCarrierVersionList(res.data.data[0].carrier_version_data)
                }
                else {
                  console.log("GetCarrierVersionListData FLASE RESPONSE", res);
                  if (res?.data?.error?.message) {
                    UpdateSnackbarMessage({
                      status: "error",
                      message: res?.data?.error?.message
                    });
                  }
                  else {
                    UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                  }
                  navigate("/carriers")
                  FlagApiLoader(false)
                }
              },
              (err) => {
                console.log("GetTemplateVersionList ERROR RESPONSE", err);
                UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
                FlagApiLoader(false)
                navigate("/carriers")
              }
            )
          }

          // if (!searchParamsVersionId && userDetail[0]?.user_role_name == "Admin") {
          if (!searchParamsVersionId && brokersAllianceAdmin) {
            return;
          }


          //BELOW GetCarrierData API SHOULD BE CALL FOR EVERY ROLE BECAUSE CARRIER TAB IS AVAILABLE FOR EVERY ROLE.
          // THAT IS WHAY THE  BELOW CONDITIONS ARE COMMENTED.REMOVE THIS LATER 
          let obj = {
            carrier_version_id: searchParamsVersionId,
            carrier_id: searchParamsCarrierId,
          }
          // THIS CALL TRIGGER ONLY USER ROLE AGENT AND MEMBER ONLY 
          if (brokersAllianceAgent || userRoleMember || subAgency) {
            FlagApiLoader(true)
            await GetCarrierData(obj,
              (res) => {
                if (res.data.status) {
                  const responseData = res?.data?.data[0]
                  const updatedCarrierDetails = {
                    ...res?.data?.data[0],
                    carrier_details: {
                      carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
                      carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
                    },
                    carrier_level: res?.data?.data[0]?.carrier_level?.length > 0 ? res?.data?.data[0].carrier_level : [],
                    carrier_product: res?.data?.data[0]?.carrier_product?.length > 0
                      ? res?.data?.data[0]?.carrier_product.map(product => ({
                        ...product,
                        carrier_product_data: product.carrier_product_data?.sort((a, b) =>
                          parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence)
                        )?.map(data => ({
                          ...data,
                          carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) =>
                            parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)
                          ),
                        })),
                      }))
                      : [
                        {
                          carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                          carrier_product_data: [
                            {
                              carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                              carrier_product_name: "",
                              carrier_product_note: "",
                              carrier_product_ba_total_comp: "",
                              carrier_product_commission_data: res?.data?.data[0]?.carrier_level?.map(dt => ({
                                carrier_ba_level_flag: false,
                                carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                                carrier_level_id: dt?.carrier_level_id,
                                carrier_level_name: dt.carrier_level_name,
                                carrier_level_sequence: "",
                                carrier_product_commission_value: "",
                              })),
                              carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                            }
                          ]
                        }
                      ]
                  };
                  setCarrierDetails(updatedCarrierDetails)
                  setCarrierNameDetailsDuplicate(updatedCarrierDetails)
                  setCarrierDetailsDuplicate(updatedCarrierDetails)
                  const carrier_type_id = res.data.data[0]?.carrier_type_id
                  setCarrierTypeId(carrier_type_id)
                  FlagApiLoader(false)
                }
                else {
                  if (res?.data?.error?.code === 9087) {
                    RevokeFunction()
                  }
                  else {
                    if (res?.data?.error?.message) {
                      UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                    }
                    else {
                      UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                    }
                    navigate("/carriers")
                    FlagApiLoader(false)
                  }
                  FlagApiLoader(false)
                }
              },
              (err) => {
                console.log("GetCarrierData ERROR RESPONSE", err);
                FlagApiLoader(false)
                UpdateSnackbarMessage({ status: "error", message: "Something went wrong!", });
                navigate("/carriers")
              })
          }
        }
        setFlagResourceExpand(false);
      }
    }

    fetchRequireData()
  }, [flagCall])

  useEffect(() => {
    if (changeInCarrierType !== null) {
      let carrierId = carrierDetails?.carrier_types_list?.length ? carrierDetails?.carrier_types_list[0]?.carrier_id : ""
      let navigateURL = brokersAllianceAgent ? `/carriers/view_carrier?id=${carrierId}` : `/carriers/view_carrier?id=${carrierId}&version_id=`
      navigate(navigateURL)
      const flag = new Date().getTime()
      setFlagCall(flag)
    }
    console.log('calling Type useEffect')
  }, [changeInCarrierType])

  useEffect(() => {
    if (PathName === "/carriers/add_carrier") {
      setHideTransperentCell(true)
    } else {
      setHideTransperentCell(false)
    }
  }, [])

  const handleFocus = (event) => {
    const input = event.target;
    if (editDetails) {
      input.classList.add("highlight-cell");  // Add the CSS class to highlight the cell
    }
    // Set cursor position to the end of the text
    input.selectionStart = input.selectionEnd = input.value.length;

    input.scrollLeft = input.scrollWidth;

    if (input.readOnly) {
      input.selectionStart = input.selectionEnd;
    }
  };

  const handleBlur = (event) => {
    const input = event.target;
    if (editDetails) {
      input.classList.remove("highlight-cell");  // Remove the CSS class to remove the highlight
    }
    // Clear selection when input loses focus
    input.selectionStart = input.selectionEnd;
  };

  const handleClickInput = (event, groups, groupData, field) => {
    event.target.readOnly = true;
    if (editDetails) {
      if (field == "carrier_product_criteria") {
        setFlagCriteriDialog(true)
        setCriteriaCellData(groups)
        setProductIds({
          productId: groups?.carrier_product_id,
          productDataIds: groupData.carrier_product_data_id,
          productDataCustomIds: groupData.carrier_product_data_id_custom
        })
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const currentInput = event.target;

      if (editDetails) {
        const inputs = Array.from(tableRef.current.querySelectorAll('input'));
        const currentIndex = inputs.indexOf(currentInput);
        const currentTableCell = currentInput.closest('td');
        const currentTableRow = currentTableCell.closest('tr');
        const currentSection = currentTableRow.closest('tbody');
        const allSections = Array.from(tableRef.current.querySelectorAll('tbody'));
        const currentCellIndex = Array.from(currentTableRow.querySelectorAll('td')).indexOf(currentTableCell);
        const currentRowIndex = Array.from(currentSection.querySelectorAll('tr')).indexOf(currentTableRow);
        const sectionIndex = allSections.indexOf(currentSection);

        if (event.key === 'Enter') {
          if (currentInput.getAttribute("name") === "carrier_product_name" && !editingCell) {
            // Open dialog if entering the cell for the first time
            // setFlagCriteriDialog(true);
            setEditingCell(currentInput);  // Mark the input as currently being edited but keep read-only
            event.preventDefault();  // Prevent the default enter key behavior (submitting form, etc.)
          }
          else if (editingCell && editingCell === currentInput) {
            // Handle exiting edit mode for any input
            setEditingCell(null);
            currentInput.readOnly = true;
            let nextInput = null;
            if (currentRowIndex + 1 < currentSection.rows.length) {
              nextInput = currentSection.rows[currentRowIndex + 1].cells[currentCellIndex].querySelector('input');
            } else if (sectionIndex + 1 < allSections.length) {
              nextInput = allSections[sectionIndex + 1].rows[0].cells[currentCellIndex].querySelector('input');
            }
            if (nextInput) {
              nextInput.focus();
              nextInput.readOnly = true;
            }
            event.preventDefault();
          }
          else {
            // Enter edit mode for any other cell
            setEditingCell(currentInput);
            currentInput.readOnly = false;
            event.preventDefault();
          }
        }
        else if (['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'].includes(event.key) && !editingCell) {
          // Handle arrow navigation when not editing
          event.preventDefault();
          const movement = {
            ArrowRight: () => currentIndex + 1 < inputs.length ? inputs[currentIndex + 1] : undefined,
            ArrowLeft: () => currentIndex - 1 >= 0 ? inputs[currentIndex - 1] : undefined,
            ArrowDown: () => currentRowIndex + 1 < currentSection.rows.length ? currentSection.rows[currentRowIndex + 1].cells[currentCellIndex].querySelector('input') : undefined,
            ArrowUp: () => currentRowIndex - 1 >= 0 ? currentSection.rows[currentRowIndex - 1].cells[currentCellIndex].querySelector('input') : undefined,
          };

          const nextInput = movement[event.key]();
          if (nextInput) {
            nextInput.focus();
            nextInput.readOnly = true;
          }
        }
      }
    };

    const table = tableRef.current;
    if (table) {
      table.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (table) {
        table.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [editingCell, editDetails]);  // Only re-run the effect if editingCell changes
  // USE EFFECTS:---------------------------------------------------------------------------------------------------------------------



  // HANDLER FUNCTIONS:-----------------------------------------------------------------------------------------------------------------
  // CARRIER FIELD FUNCTIONS:-------------------------
  const handleCarrierNameExistence = (e) => {

    const trimmedName = carrierDetails?.carrier_name?.trim();
    if (trimmedName != "" && carrierDetails?.carrier_type_id != "") {
      let payload = {
        carrier_name: carrierDetails?.carrier_name?.trim(),
        carrier_type_id: carrierDetails?.carrier_type_id
      }

      FlagApiLoader(true)
      CheckCarrierExistence(payload, (res) => {
        const carrierExistanceResponse = res?.data?.data[0]
        if (res?.data?.status == true && res?.data?.data?.code == 9095) {
          const message = res?.data?.data?.message
          // UpdateSnackbarMessage({ status: "error", message: message })
          // setCarrierExitenceErrorMessage(message)
          setTimeout(() => {
            FlagApiLoader(false)
          }, 300);
          setFlagCarrierNameExist(true)
        }
        else {
          if (carrierExistanceResponse.carrier_exist_flag == true) {
            const error = "This carrier name already exists!";
            // UpdateSnackbarMessage({ status: "error", message: "This carrier name already exists!" })
            setCarrierExitenceErrorMessage(error)
            setTimeout(() => {
              FlagApiLoader(false)
            }, 300);
            setFlagCarrierNameExist(true)
          }
          else {
            setCarrierDetails({
              ...carrierDetails,
              carrier_name: e.target.value
            })
            setCarrierDetailsDuplicate({
              ...carrierDetailsDuplicate,
              carrier_name: e.target.value
            })
            setFlagCarrierNameExist(false)
            setCarrierExitenceErrorMessage("");
          }
          setTimeout(() => {
            FlagApiLoader(false)
          }, 300);
        }
      },
        (err) => {
          console.log('CheckCarrierExistence ERROR RESPONSE====>', err)
          UpdateSnackbarMessage({ status: "error", message: 'Somthing Went Wrong' })
          FlagApiLoader(false)
          setCarrierExitenceErrorMessage("");
        })
    }
  }

  const handleCarrierDetailsChangeLeft = (e, carrier_detail_field_id) => {
    const currentData = carrierDetails?.carrier_details?.carrier_data_column_left || [];

    // Check if the item with the specific id exists
    const foundIndex = currentData.findIndex(det => det.carrier_detail_field_id == carrier_detail_field_id);

    if (foundIndex !== -1) {
      const newData = currentData.map((det, index) =>
        index === foundIndex ? { ...det, carrier_detail_field_value: e.target.value } : det
      );

      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_left: newData
        }
      });
    }
    else {
      // Add new item if it doesn't exist
      const newData = [...currentData, {
        carrier_detail_field_id: carrier_detail_field_id,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
        carrier_detail_field_value: e.target.value,
        carrier_detail_field_name: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_field_name,
        carrier_detail_flag_internal_info: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_internal_info,
        carrier_detail_flag_show_field: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_show_field,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_left?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
      }];
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_left: newData
        }
      });
    }
  };

  const handleCarrierDetailsChangeRight = (e, carrier_detail_field_id) => {
    const currentData = carrierDetails?.carrier_details?.carrier_data_column_right || [];

    // Check if the item with the specific id exists
    const foundIndex = currentData.findIndex(det => det.carrier_detail_field_id == carrier_detail_field_id);

    if (foundIndex !== -1) {
      const newData = currentData.map((det, index) =>
        index === foundIndex ? { ...det, carrier_detail_field_value: e.target.value } : det
      );
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_right: newData
        }
      });
    }
    else {
      // Add new item if it doesn't exist
      const newData = [...currentData, {
        carrier_detail_field_id: carrier_detail_field_id,
        carrier_detail_field_value: e.target.value,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
        carrier_detail_field_name: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_field_name,
        carrier_detail_flag_internal_info: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_internal_info,
        carrier_detail_flag_show_field: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_flag_show_field,
        carrier_detail_id: carrierKeyDetails?.carrier_data_column_right?.find(ele => ele?.carrier_detail_field_id == carrier_detail_field_id)?.carrier_detail_id,
      }];
      setCarrierDetails({
        ...carrierDetails,
        carrier_details: {
          ...carrierDetails.carrier_details,
          carrier_data_column_right: newData
        }
      });
    }
  };

  // CARRIER NORMAL FUNCTIONS:-------------------------
  const handleClick = (event, id) => {
    setSelectedRowGroup({
      selectId: id,
      selectedId: null
    })
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedLine(null)
  };

  const handleOpenColumnPopover = (event, i, ele) => {
    if (ele) {
      setAnchorElForLevelsColumn({ type: ele, columnId: 7 });
    } else {
      setAnchorElForLevelsColumn({ type: event.currentTarget, columnId: i });
    }
  };

  const handleCloseColumnPopover = () => {
    setAnchorElForLevelsColumn({ type: null, columnId: 0 });
  };

  const handleAddRowPopOverClose = () => {
    setAnchorElForRow(null);
  };

  const handleDeletePopOverExtraFuncs = () => {
    setHideTransperentCell(true)
    setSelectedRowGroup({
      selectId: null,
      selectedId: null
    })
    setSelectedRow(null)
  }

  const removeUnsavedChangesHandler = () => {
    setSelectedAddIcon(null)
    setFlagShowAddIcons(false)
    setEditDetails(false)
    document.getElementById('addLevelPopup').style.display = "none"
  }
  // HANDLER FUNCTIONS:---------------------------------------------------------------------------------------------------------------------



  // CARRIER TABLE FUNCTIONS:---------------------------------------------------------------------------------------
  const handleAddRowPopOverClick = (event, groupData, levelIdx, groupIdx) => {
    setAnchorElForRow(event.currentTarget);
    setSelectedRowIndex({
      groupData: groupData,
      levelIdx: levelIdx,
      groupIdx: groupIdx
    })
  };

  const handleCarrierLevelData = (e, carrier_product_id, carrier_product_data_id_custom, name) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_product: carrierDetails?.carrier_product?.map((group) => {
        if (group?.carrier_product_id == carrier_product_id || group?.carrier_product_id_custom == carrier_product_id) {
          return {
            ...group,
            carrier_product_data: group?.carrier_product_data?.map((groupData) => {
              if (groupData.carrier_product_data_id_custom == carrier_product_data_id_custom || groupData?.carrier_product_data_id == carrier_product_data_id_custom) {
                return { ...groupData, [name]: e.target.value }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }
    setCarrierDetails(newCarrierDetails)
  }

  const handleCarrierLevelCommissionData = (e, carrier_product_id, carrier_product_data_id_custom, carrier_product_commission_id) => {
    const newCarrierDetails = {
      ...carrierDetails,
      carrier_product: carrierDetails?.carrier_product?.map((group) => {
        if (group?.carrier_product_id == carrier_product_id || group?.carrier_product_id_custom == carrier_product_id) {
          return {
            ...group,
            carrier_product_data: group?.carrier_product_data?.map((groupData) => {
              if (groupData.carrier_product_data_id == carrier_product_data_id_custom || groupData.carrier_product_data_id_custom == carrier_product_data_id_custom) {
                return {
                  ...groupData,
                  carrier_product_commission_data: groupData?.carrier_product_commission_data?.map((commission) => {
                    if (commission?.carrier_product_commission_id == carrier_product_commission_id || commission?.carrier_commission_value_id_custom == carrier_product_commission_id) {
                      return { ...commission, carrier_product_commission_value: e.target.value }
                    }
                    return commission
                  })
                }
              }
              return groupData
            })
          }
        }
        return group;
      })
    }
    setCarrierDetails(newCarrierDetails)

  }

  const handleAddNewLevel = () => {
    // let idToFind = newLevelData?.carrier_level_after == null ? carrierDetails?.carrier_level.length > 0 ? carrierDetails?.carrier_level[0].carrier_level_id : null : newLevelData?.carrier_level_after;

    if (newLevelData?.carrier_level_name == "") {
      setEmptyLevelName(true)
      UpdateSnackbarMessage({
        status: "error",
        message: "Please add level name"
      })
      return;
    }

    let duplicateFound = carrierDetails?.carrier_level?.find(level => level?.carrier_level_id != newLevelData?.carrier_level_id && level?.carrier_level_name == newLevelData?.carrier_level_name)
    if (duplicateFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please select unique level name"
      })
      setEmptyLevelName(true)
      return
    }

    let prevCarrierLevel = [...carrierDetails.carrier_level];
    let findCurrLevelIdx = prevCarrierLevel?.findIndex(level =>
      (level?.carrier_level_id != null && level?.carrier_level_id == newLevelData?.carrier_level_after) ||
      (level?.carrier_level_id_custom != null && level?.carrier_level_id_custom == newLevelData?.carrier_level_after)
    );

    let new_custom_id = new Date().getTime() + parseInt(Math.random() * 1000000000000000);

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((section) => {
      return {
        ...section,
        carrier_product_data: section?.carrier_product_data?.map((group) => {
          let newCommission = [...group.carrier_product_commission_data]
          newCommission.splice(findCurrLevelIdx + 1, 0, {
            carrier_level_id_custom: new_custom_id,
            carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
            carrier_level_name: newLevelData?.carrier_level_name,
            carrier_ba_level_flag: false,
            // carrier_level_sequence: 1,
            carrier_product_commission_value: ""
          })
          // // console.log("newCommision", newCommission)
          return { ...group, carrier_product_commission_data: newCommission }
        })
      }
    });

    if (findCurrLevelIdx != -1) {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": false,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
        "carrier_level_color_name": newLevelData?.carrier_level_color_name
      })
    }
    else {
      prevCarrierLevel.splice(findCurrLevelIdx + 1, 0, {
        "carrier_level_id_custom": new_custom_id,
        "carrier_level_name": newLevelData?.carrier_level_name,
        "carrier_ba_level_flag": false,
        // "carrier_level_sequence": 1,
        // "carrier_level_color_id": 3,
        "carrier_level_color_hex_code": newLevelData?.carrier_level_color_hex_code,
        carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
        "carrier_level_color_name": newLevelData.carrier_level_color_name
      })
    }

    setCarrierDetails({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_product: newCarrierLevelData })
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level: prevCarrierLevel, carrier_level_data: newCarrierLevelData })
    setNewLevelData({
      carrier_level_after: null,
      "carrier_level_id": null,
      "carrier_level_name": "",
      "carrier_ba_level_flag": false,
      // "carrier_level_sequence": 1,
      "carrier_level_color_id": 1,
      "carrier_level_color_hex_code": "#1b2430",
      "carrier_level_color_name": "Default"
    })
    // handleCloseColumnPopover()
    document.getElementById("addLevelPopup").style.display = "none"
    setAnchorElForLevelsColumn({ type: null, columnId: 0 })
    setFlagAfterLevelPopoverRender(false)
    document.getElementById('addLevelPopup').style.display = "none"
    currentlyAddedCols.push(new_custom_id)
    setSelectedAddIcon(null)

  }

  const addNewRowForSection = (currGroup, levelIdx, groupIdx) => {

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((level, i) => {
      if (i === levelIdx) {
        let newGroupData = { ...level }.carrier_product_data;
        const carrierProductId = level.carrier_product_id || new Date().getTime() + parseInt(Math.random() * 1000000000000000);

        let newObj = {
          ...currGroup,
          carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
          carrier_product_name: "",
          carrier_product_note: "",
          carrier_product_ba_total_comp: "",
          carrier_product_commission_data: currGroup?.carrier_product_commission_data?.map((commission) => {
            let carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
            return {
              carrier_commission_value_id_custom:
                new Date().getTime() + parseInt(Math.random() * 1000000000000000),
              [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
              carrier_level_name: commission?.carrier_level_name,
              carrier_ba_level_flag: commission?.carrier_ba_level_flag,
              carrier_product_commission_value: "",
            };
          }),
          // Empty the criteria when adding a new row
          carrier_product_criteria: [],

        };

        delete newObj?.carrier_product_data_id;
        newGroupData.splice(groupIdx, 0, newObj);

        return {
          ...level,
          carrier_product_data: newGroupData,
          carrier_product_id: carrierProductId,
        };
      }
      return level;
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    handleAddRowPopOverClose();
  };

  const addNewSection = (currGroup, levelIdx) => {
    const newCarrierLevelData = [...carrierDetails?.carrier_product];
    const currGroupData = { ...currGroup.carrier_product_data[0] };
    const newObj = JSON.parse(JSON.stringify(currGroupData));

    newObj.carrier_product_data_id_custom = new Date().getTime() + parseInt(Math.random() * 1000000000000000);
    newObj.carrier_product_name = "";
    newObj.carrier_product_note = "";
    newObj.carrier_product_ba_total_comp = "";
    delete newObj?.carrier_product_data_id;

    newObj.carrier_product_commission_data = newObj.carrier_product_commission_data.map((commission) => {
      const carrier_level_id_field = commission?.carrier_level_id ? "carrier_level_id" : "carrier_level_id_custom";
      return {
        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
        [carrier_level_id_field]: commission?.carrier_level_id || commission?.carrier_level_id_custom,
        carrier_level_name: commission?.carrier_level_name,
        carrier_ba_level_flag: commission?.carrier_ba_level_flag,
        carrier_product_commission_value: ""
      };
    });

    newObj.carrier_product_criteria = [];
    newCarrierLevelData.splice(levelIdx, 0, {
      carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
      carrier_product_data: [newObj]
    });

    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData });
    // setCarrierDetailsDuplicate({ ...carrierDetails, carrier_level_data: newCarrierLevelData });
    handleClose();
  };

  const deleteRowForSection = () => {
    const updateCarrierLevelData = carrierDetails?.carrier_product?.reduce((car_level_arr, car_data, index) => {
      if (index === selectedRowIndex?.levelIdx) {
        const updateGroupData = car_data?.carrier_product_data?.filter((grp_data, ind) => ind !== selectedRowIndex?.groupIdx);
        if (updateGroupData?.length !== 0) {
          car_level_arr.push({
            ...car_data, carrier_product_data: updateGroupData
          });
        }
      } else { car_level_arr.push(car_data); }

      return car_level_arr;
    }, []);

    setCarrierDetails({ ...carrierDetails, carrier_product: updateCarrierLevelData })
    setDeleteRowPopupFlag(false)
    handleClose()
  }

  const deleteSection = () => {
    let newCarrierLevelData = [...carrierDetails?.carrier_product];
    newCarrierLevelData?.splice(selectedLinePopover?.index, 1)
    setCarrierDetails({ ...carrierDetails, carrier_product: newCarrierLevelData })
    setDeleteRowPopupFlag(false)
  }

  const handleRevertColumnAddition = () => {
    let newCarrierLevel = { ...carrierDetails }.carrier_level?.filter(level => !currentlyAddedCols.includes(level?.carrier_level_id_custom));

    let newCarrierLevelData = carrierDetails?.carrier_product?.map((section) => {
      return {
        ...section,
        carrier_product_data: section?.carrier_product_data?.map((group) => {
          let newCommissionData = [...group.carrier_product_commission_data].filter(commission => !currentlyAddedCols.includes(commission?.carrier_level_id_custom))

          return { ...group, carrier_product_commission_data: newCommissionData }
        })
      }
    });

    setCurrentlyAddedCols([])
    setCarrierDetails({ ...carrierDetails, carrier_level: newCarrierLevel, carrier_product: newCarrierLevelData })
  }
  // CARRIER TABLE FUNCTIONS:---------------------------------------------------------------------------------------



  const saveCriteareDetails = (criteriaData, childProductIds) => {

    for (let i in criteriaData) {
      // if (criteriaData[i]?.criteria_name == "Sub Type") {
      //   // if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value))) {
      //   //   delete criteriaData[i];
      //   // }
      //   if (!criteriaData[i]?.carrier_product_criteria_data?.length) {
      //     delete criteriaData[i];
      //   }
      // }
      if (criteriaData[i]?.criteria_name == "Sub Type") {
        if (!criteriaData[i]?.carrier_product_criteria_data?.length ||
          !criteriaData[i]?.carrier_product_criteria_data?.some(data => data.criteria_field_value && data.criteria_field_value.trim() != "")) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Issue Age") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i]?.criteria_name == "Renewal Year") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_value && !rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
      else if (criteriaData[i].criteria_name == "State") {
        if (criteriaData[i]?.carrier_product_criteria_data?.find(rr => (!rr.criteria_field_option_id))) {
          delete criteriaData[i];
        }
      }
    }

    if (childProductIds.productId && childProductIds.productDataIds) {
      setCarrierDetails((prevData) => {
        const carrProductData = prevData?.carrier_product?.map((prodData, index) => {
          if (prodData?.carrier_product_id == childProductIds?.productId) {
            const updateCarrierProdData = prodData?.carrier_product_data?.map(dt => {
              if (dt?.carrier_product_data_id == childProductIds?.productDataIds) {
                return {
                  ...dt,
                  carrier_product_criteria: [...criteriaData.filter(rr => (rr))]
                }
              }
              return dt;
            })
            return {
              ...prodData,
              carrier_product_data: updateCarrierProdData
            }
          }

          return prodData;
        })
        return {
          ...prevData, carrier_product: carrProductData
        }
      })
    }
    else {
      setCarrierDetails((prevData) => {
        const carrProductData = prevData?.carrier_product?.map((prodData, index) => {
          const updateCarrierProdData = prodData?.carrier_product_data?.map(dt => {
            if (dt.carrier_product_data_id_custom == childProductIds?.productDataCustomIds) {
              return {
                ...dt,
                carrier_product_criteria: [...criteriaData.filter(rr => (rr))]
              }
            }
            return dt;
          })
          return {
            ...prodData,
            carrier_product_data: updateCarrierProdData
          }
        })
        return {
          ...prevData, carrier_product: carrProductData
        }
      })
    }
  };

  const generateTooltipContent = (criteria) => {
    if (!criteria || criteria.length == 0) return "";
    const criteriaMap = {};

    criteria?.forEach((crit) => {
      const { criteria_name, carrier_product_criteria_data } = crit;

      if (!criteriaMap[criteria_name]) {
        criteriaMap[criteria_name] = [];
      }

      switch (criteria_name) {
        // case "Sub Type":
        //   const subtypeValue = carrier_product_criteria_data?.map((data) => data.criteria_field_value)?.join(", ");
        //   criteriaMap[criteria_name]?.push(subtypeValue);
        //   break;

        case "Sub Type":
          const subtypeValue = carrier_product_criteria_data
            ?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")
            ?.map(data => data.criteria_field_value)
            ?.join(", ");

          if (subtypeValue) {
            if (!criteriaMap[criteria_name]) {
              criteriaMap[criteria_name] = [];
            }
            criteriaMap[criteria_name].push(subtypeValue);
          }
          break;

        case "Issue Age":
          const ageOrYearValues = carrier_product_criteria_data?.map(element => {
            if (element?.criteria_field_name == "From Age") {
              return element?.criteria_field_value
            }
            else {
              return (
                element?.criteria_field_option_value == "Not Applicable" ? "" :
                  element?.criteria_field_option_value == "Lifetime" ? "+" :
                    element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
                      " "
              );
            }
          })
          criteriaMap[criteria_name]?.push(ageOrYearValues?.join(""));
          break;

        case "Renewal Year":
          // Handle Issue Age and Renewal Year.
          const ageOrYearValues1 = carrier_product_criteria_data?.map(element => {
            if (element?.criteria_field_name == "Start Year") {
              return element?.criteria_field_value
            }
            else {
              return (
                element?.criteria_field_option_value == "Not Applicable" ? "" :
                  element?.criteria_field_option_value == "Lifetime" ? "+" :
                    element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
                      " "
              );
            }
          })
          criteriaMap[criteria_name]?.push(ageOrYearValues1.join(""));
          break;

        case "State":
          // Handle State.
          const stateValues = carrier_product_criteria_data?.filter((data) => data?.criteria_field_option_value)

          if (stateValues?.filter(element => element?.criteria_field_option_value == "Select All")?.length) {
            criteriaMap[criteria_name].push("All Available");
          } else {
            criteriaMap[criteria_name].push(stateValues?.filter((val) => val)?.map(element => element?.criteria_field_option_value).join(", "));
          }
          break;
        default:
      }
    });

    // OLD CODE BFR TABLE
    // const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
    //   const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    //   return `
    //       <div style="margin-bottom: 5px">
    //         <table>
    //           <thead>
    //           <tbody>
    //             <tr>
    //             <td>
    //               <span
    //               class="input-span"
    //               style="font-size: 13px;
    //               font-weight: 500 !important;
    //               color: #A0A0A0;
    //               margin-bottom: 5px"
    //           >
    //             ${capitalizedKey}:
    //              </span>
    //             </td>
    //             <td>
    //               <span
    //               style="font-size: 13px;
    //               font-weight: 500 !important;
    //               color: #FFFFFF;"
    //           >
    //             ${values.join(", ")}
    //           </span>
    //             </td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </div>
    //   `;
    // });

    Object.keys(criteriaMap)?.forEach(key => {
      if (!criteriaMap[key]?.length) {
        delete criteriaMap[key];
      }
    });

    const criteriaDescriptions = Object.entries(criteriaMap)?.map(([key, values]) => {
      const capitalizedKey = key?.charAt(0)?.toUpperCase() + key?.slice(1)?.toLowerCase();
      return `
    <div style="margin-bottom: 5px">
      <table>
        <thead></thead> <!-- Empty thead for structure -->
        <tbody>
          <tr>
            <td
             style="
                text-overflow: ellipsis; 
                padding-right: 10px; 
                vertical-align: baseline;
                min-width: 98px;
              "
            >
              <span
                class="input-span"
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #A0A0A0;
                margin-bottom: 5px"
              >
                ${capitalizedKey}:
              </span>
            </td>
            <td>
              <span
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #FFFFFF;"
              >
                ${values.join(", ")} <!-- Combine all values into one row -->
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      `;
    });
    return criteriaDescriptions.join("")
  }

  const generateInputContent = (criteria) => {
    if (!criteria || criteria.length === 0) return "";

    const criteriaMap = {};

    criteria.forEach((crit) => {
      const { criteria_name, carrier_product_criteria_data } = crit;

      if (!criteriaMap[criteria_name]) {
        criteriaMap[criteria_name] = [];
      }

      switch (criteria_name) {
        // case "Sub Type":
        //   // Handle Sub Type directly using criteria_field_value.
        //   const subtypeValue = carrier_product_criteria_data
        //     .map((data) => data.criteria_field_value)
        //     .join(", ");
        //   criteriaMap[criteria_name].push(subtypeValue);
        //   break;

        case "Sub Type":
          const subtypeValue = carrier_product_criteria_data
            ?.filter(data => data.criteria_field_value !== null && data.criteria_field_value !== "")
            ?.map(data => data.criteria_field_value)
            ?.join(", ");

          if (subtypeValue) {
            if (!criteriaMap[criteria_name]) {
              criteriaMap[criteria_name] = [];
            }
            criteriaMap[criteria_name]?.push(subtypeValue);
          }
          break;

        case "Issue Age":
          const issueAgeValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "From Age") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(issueAgeValues.join(""));
          break;

        case "Renewal Year":
          const renewalYearValues = carrier_product_criteria_data.map((rr) => {
            if (rr.criteria_field_name == "Start Year") {
              return rr.criteria_field_value;
            } else {
              return rr.criteria_field_option_value === "Not Applicable"
                ? ""
                : rr.criteria_field_option_value === "Lifetime"
                  ? "+"
                  : rr.criteria_field_option_value === "Custom Text Field"
                    ? " to " + rr.criteria_field_value
                    : " ";
            }
          });
          criteriaMap[criteria_name].push(renewalYearValues.join(""));
          break;

        case "State":
          const stateValues = carrier_product_criteria_data.filter(
            (data) => data.criteria_field_option_value
          );
          if (stateValues.filter((rr) => rr.criteria_field_option_value == "Select All").length) {
            criteriaMap[criteria_name].push("All Available");
          } else {
            criteriaMap[criteria_name].push(
              stateValues
                .filter((val) => val)
                .map((rr) => rr.criteria_field_option_value)
                .join(", ")
            );
          }
          break;

        default:
          break;
      }
    });

    Object.keys(criteriaMap)?.forEach(key => {
      if (!criteriaMap[key]?.length) {
        delete criteriaMap[key];
      }
    });

    // Convert the map into a readable format.
    const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      return `${capitalizedKey}: ${values.join(", ")}`;
    });

    return criteriaDescriptions.join(" | ");
  };



  // API FUNCTIONS:---------------------------------------------------------------------------------------------------------------------
  const handleValidation = () => {
    let outerArr = []
    let errorFound = false;
    carrierDetails?.carrier_product?.map((level) => {
      level?.carrier_product_data?.map((group) => {
        let innerArr = [];
        group.carrier_product_name == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_product_note == "" ? innerArr.push(0) : innerArr.push(1)
        group.carrier_product_ba_total_comp == "" ? innerArr.push(0) : innerArr.push(1)
        group?.carrier_product_commission_data?.map((commission) => {
          commission?.carrier_product_commission_value == "" ? innerArr.push(0) : innerArr.push(1)
        })
        outerArr.push(innerArr)
      })

    })

    for (let i = 0; i < outerArr.length; i++) {
      for (let j = 0; j < outerArr[i].length; j++) {
        if (outerArr[i][j] == 0) {
          let rowEmptyFound = outerArr[i].every((val) => val == 0);
          let colEmptyFound = outerArr.every((row) => row[j] === 0);
          if (rowEmptyFound && colEmptyFound) {
            errorFound = true
            break;
          }
        }
      }
    }

    if (errorFound) {
      UpdateSnackbarMessage({
        status: "error",
        message: "Please fill all data"
      })
      return
    }
    else {
      handleCarrierSaveUpdate()
    }
  }

  const handleCarrierSaveUpdate = () => {
    if (window.location.pathname == "/carriers/add_carrier") {
      const filteredCarrierProductLevelData = carrierDetails?.carrier_product?.filter(group => group?.carrier_product_data?.length > 0);

      filteredCarrierProductLevelData.map(filterData => {
        if (filterData.carrier_product_id_custom) {
          delete filterData.carrier_product_id_custom;
          delete filterData.carrier_product_id;
        }
        return filterData;
      })

      let payload = {
        ...carrierDetails,
        carrier_product: filteredCarrierProductLevelData,
        carrier_details: [
          ...carrierDetails?.carrier_details?.carrier_data_column_left,
          ...carrierDetails?.carrier_details?.carrier_data_column_right
        ]
      }


      if (!subAgency) {
        FlagApiLoader(true)
        CreateCarrierAPI(payload, (res) => {
          console.log("CreateTemplate SUCCESS RESPONSE ===>", res);
          if (res?.data?.status) {
            navigate("/carriers")
            UpdateSnackbarMessage({
              status: "success",
              message: "New Carrier Created Successfully"
            })
            FlagApiLoader(false)
          }
          else {
            console.log("response false of CreateCarrierAPI api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
        }, (err) => {
          console.log("error of CreateCarrierAPI api", err);
          UpdateSnackbarMessage({
            status: "error",
            message: err?.data?.error?.message
          })
          FlagApiLoader(false)

        })
      }
    }
    else {
      const filteredCarrierLevelData = carrierDetails?.carrier_product?.filter(group => group?.carrier_product_data?.length > 0);
      filteredCarrierLevelData.map(filterData => {
        if (filterData.carrier_product_id_custom) {
          delete filterData.carrier_product_id;
          delete filterData.carrier_product_id_custom;
        }
        return filterData;
      })

      let payload = {
        ...carrierDetails,
        carrier_product: filteredCarrierLevelData,
        carrier_details: [
          ...carrierDetails?.carrier_details?.carrier_data_column_left,
          ...carrierDetails?.carrier_details?.carrier_data_column_right
        ],
        carrier_level: carrierDetails.carrier_level.map((ele) => ({
          ...ele,
          carrier_level_color_id: Number(ele.carrier_level_color_id),
          carrier_level_id: Number(ele.carrier_level_id)
        }))
      };

      // OLD CODE
      // let payload = {
      //   ...carrierDetails,
      //   carrier_details: [...carrierDetails?.carrier_details?.carrier_data_column_left, ...carrierDetails?.carrier_details?.carrier_data_column_right]
      // }

      if (!subAgency) {
        FlagApiLoader(true)
        UpdateCarrierAPI(payload, (res) => {
          if (res?.data?.status) {
            console.log("response true of UpdateCarrierAPI api", res);
            setFlagCall(new Date().getTime())
            setEditDetails(false)
            setHideTransperentCell(false)
            UpdateSnackbarMessage({
              status: "success",
              message: "Carrier Updated Successfully"
            })
            FlagApiLoader(false)
            setFlagShowAddIcons(false)
          }
          else {
            console.log("response false of UpdateCarrierAPI api", res);
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
        },
          (err) => {
            console.log("error of UpdateCarrierAPI api", err);
            UpdateSnackbarMessage({
              status: "error",
              message: err?.data?.error?.message
            })
            FlagApiLoader(false)
          })
      }
    }
  }

  const DeleteResouceFile = () => {
    setFlagDeletePopup(false)
    FlagApiLoader(true)
    let obj = {
      carrier_id: deleteResourceData?.carrier_detail?.carrier_id,
      carrier_resource_id: deleteResourceData?.file_detail.carrier_resource_id
    }
    DeleteResources(obj,
      (res) => {
        if (res?.data?.status) {
          console.log("response true of DeleteResources api", res);
          setFlagCall(new Date().getTime())
          UpdateSnackbarMessage({
            status: "success",
            message: res?.data?.data?.message
          });
        }
        else {
          console.log("response false of DeleteResources api", res);
          if (res?.data?.error?.message) {
            UpdateSnackbarMessage({
              status: "error",
              message: res?.data?.error?.message
            });
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: "Something Went Wrong!"
            });
          }
        }
      },
      (err) => {
        console.log("error of GetTemplateVersionList api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
      })
  }

  const mainDataCall = (searchParamsCarrierId, latestVersionId) => {

    let obj = {
      carrier_id: searchParamsCarrierId,
      carrier_version_id: latestVersionId
    }
    FlagApiLoader(true)
    GetCarrierData(obj,
      (res) => {
        if (res.data.status) {
          console.log("Response of change carrier in dropdown", res);
          const responseData = res?.data?.data[0]
          // const updatedCarrierDetails = {
          //   ...res?.data?.data[0],
          //   carrier_details: {
          //     carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info == false),
          //     carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info == true),
          //   }
          // };

          const newDetails = res.data.data[0].carrier_details;
          const leftDetails = newDetails.filter(carrier => !carrier.carrier_detail_flag_internal_info);
          const rightDetails = newDetails.filter(carrier => carrier.carrier_detail_flag_internal_info);
          // Maps for quick lookup
          const newLeftDetailsMap = leftDetails?.reduce((acc, detail) => {
            acc[detail.carrier_detail_field_id] = detail;
            return acc;
          }, {});

          const newRightDetailsMap = rightDetails?.reduce((acc, detail) => {
            acc[detail.carrier_detail_field_id] = detail;
            return acc;
          }, {});

          const updatedLeftDetails = carrierKeyDetails?.carrier_data_column_left?.map(detail => ({
            ...detail,
            carrier_detail_field_value: newLeftDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || '',
            carrier_detail_id: newLeftDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_id || detail.carrier_detail_id
          }));

          const updatedRightDetails = carrierKeyDetails?.carrier_data_column_right?.map(detail => ({
            ...detail,
            carrier_detail_field_value: newRightDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || '',
            carrier_detail_id: newRightDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_id || detail.carrier_detail_id
          }));

          const updatedCarrierDetails = {
            ...res.data.data[0],
            carrier_details: {
              carrier_data_column_left: updatedLeftDetails,
              carrier_data_column_right: updatedRightDetails
            },
            carrier_level: res?.data?.data[0]?.carrier_level?.length > 0 ? res?.data?.data[0].carrier_level : [],
            carrier_product: res?.data?.data[0]?.carrier_product?.length > 0
              ? res?.data?.data[0]?.carrier_product.map(product => ({
                ...product,
                carrier_product_data: product.carrier_product_data?.sort((a, b) =>
                  parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence)
                )?.map(data => ({
                  ...data,
                  carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) =>
                    parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)
                  ),
                })),
              }))
              : [
                {
                  carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                  carrier_product_data: [
                    {
                      carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                      carrier_product_name: "",
                      carrier_product_note: "",
                      carrier_product_ba_total_comp: "",
                      carrier_product_commission_data: res?.data?.data[0]?.carrier_level?.map(dt => ({
                        carrier_ba_level_flag: false,
                        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        carrier_level_id: dt?.carrier_level_id,
                        carrier_level_name: dt.carrier_level_name,
                        carrier_level_sequence: "",
                        carrier_product_commission_value: "",
                      })),
                      carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                    }
                  ]
                }
              ]
          };

          // Update or reset values for left and right details
          // const updatedLeftDetails = carrierKeyDetails.carrier_data_column_left.map(detail => ({
          //   ...detail,
          //   carrier_detail_field_value: newLeftDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || ''
          // }));

          // const updatedRightDetails = carrierKeyDetails.carrier_data_column_right.map(detail => ({
          //   ...detail,
          //   carrier_detail_field_value: newRightDetailsMap[detail.carrier_detail_field_id]?.carrier_detail_field_value || ''
          // }));
          setCarrierDetails(updatedCarrierDetails)
          setCarrierDetailsDuplicate(updatedCarrierDetails)
          setCarrierNameDetailsDuplicate(updatedCarrierDetails)
          const carrier_type_id = res.data.data[0]?.carrier_type_id
          setCarrierTypeId(carrier_type_id)
          setFlagCall("default")
          FlagApiLoader(false)
        } else {
          if (res?.data?.error?.code === 9087) {
            RevokeFunction()
          }
          else {
            UpdateSnackbarMessage({
              status: "error",
              message: res.data.error.message,
            });
            FlagApiLoader(false)
            navigate("/carriers")
          }
        }

      }, (err) => {
        FlagApiLoader(false)
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
        navigate("/carriers")
      })
  }

  const RevokeFunction = () => {
    FlagApiLoader(true)
    RevokeDelegateAccessForAdmin((res) => {
      if (res?.data?.status) {
        console.log("response true of RevokeDelegateAccessForAdmin api", res);
        window.location.href = "/"
      }
      else {
        console.log("response false of RevokeDelegateAccessForAdmin api", res);
        if (res?.data?.error?.message) {
          UpdateSnackbarMessage({
            status: "error",
            message: res?.data?.error?.message
          });
        }
        else {
          UpdateSnackbarMessage({
            status: "error",
            message: "Something Went Wrong!"
          });
        }
      }
      FlagApiLoader(false)
    },
      (err) => {
        console.log("error of RevokeDelegateAccessForAdmin api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: err?.message
        });
        FlagApiLoader(false)
      })
  }

  const handleChangeVersion = (e) => {
    FlagApiLoader(true)
    let carrierId = searchParams.get("id");
    let obj = {
      carrier_id: carrierId,
      carrier_version_id: e.target.value
    }
    GetCarrierData(obj,
      (res) => {
        if (res?.data?.status) {
          // alert('4')
          const responseData = res?.data?.data[0]
          const updatedCarrierDetails = {
            ...res?.data?.data[0],
            carrier_details: {
              carrier_data_column_left: res?.data?.data[0]?.carrier_details?.filter(carrier => !carrier?.carrier_detail_flag_internal_info),
              carrier_data_column_right: res?.data?.data[0]?.carrier_details?.filter(carrier => carrier?.carrier_detail_flag_internal_info),
            },
            carrier_level: res?.data?.data[0]?.carrier_level?.length > 0 ? res?.data?.data[0].carrier_level : [],
            carrier_product: res?.data?.data[0]?.carrier_product?.length > 0
              ? res?.data?.data[0]?.carrier_product.map(product => ({
                ...product,
                carrier_product_data: product.carrier_product_data?.sort((a, b) =>
                  parseInt(a.carrier_product_sequence) - parseInt(b.carrier_product_sequence)
                )?.map(data => ({
                  ...data,
                  carrier_product_commission_data: data.carrier_product_commission_data.sort((a, b) =>
                    parseInt(a.carrier_level_sequence) - parseInt(b.carrier_level_sequence)
                  ),
                })),
              }))
              : [
                {
                  carrier_product_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                  carrier_product_data: [
                    {
                      carrier_product_data_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                      carrier_product_name: "",
                      carrier_product_note: "",
                      carrier_product_ba_total_comp: "",
                      carrier_product_commission_data: res?.data?.data[0]?.carrier_level?.map(dt => ({
                        carrier_ba_level_flag: false,
                        carrier_commission_value_id_custom: new Date().getTime() + parseInt(Math.random() * 1000000000000000),
                        carrier_level_id: dt?.carrier_level_id,
                        carrier_level_name: dt.carrier_level_name,
                        carrier_level_sequence: "",
                        carrier_product_commission_value: "",
                      })),
                      carrier_product_criteria_data: responseData?.carrier_product_criteria_data?.length > 0 ? responseData?.carrier_product_criteria_data : [],
                    }
                  ]
                }
              ]
          };
          setCarrierDetails(updatedCarrierDetails)
          setCarrierDetailsDuplicate(updatedCarrierDetails)
          setCarrierNameDetailsDuplicate(updatedCarrierDetails)
          setCurrentVersion(obj.carrier_version_id)
          setSearchParams({ id: carrierId, version_id: obj.carrier_version_id })
          FlagApiLoader(false)
        }
        else {
          if (res?.data?.error?.code === 9087) {
            RevokeFunction()
          }
          else {
            if (res?.data?.error?.message) {
              UpdateSnackbarMessage({
                status: "error",
                message: res?.data?.error?.message
              });
            }
            else {
              UpdateSnackbarMessage({
                status: "error",
                message: "Something Went Wrong!"
              });
            }
            FlagApiLoader(false)
          }
        }
      },
      (err) => {
        console.log("error of GetCarrierData api", err);
        UpdateSnackbarMessage({
          status: "error",
          message: "Something went wrong!",
        });
        FlagApiLoader(false)
      }
    )
  }
  // API FUNCTIONS:---------------------------------------------------------------------------------------------------------------------

  return (
    <div class="temp">
      {apiLoader && <Loader />}

      {flagCriteriDialog && (
        <GlobalDialog
          open={flagCriteriDialog}
          maxWidth={"xl"}
          data={
            <CriteriaDialog
              flagCriteriDialog={flagCriteriDialog}
              setFlagCriteriDialog={setFlagCriteriDialog}
              carrierDetails={carrierDetails}
              setCarrierDetails={setCarrierDetails}
              saveCriteareDetails={saveCriteareDetails}
              productIds={productIds}
              setCriteriaCellData={setCriteriaCellData}
              criteriaCellData={criteriaCellData}
            />
          }
        />
      )}

      {flagLevelTablePopup && (
        <GlobalDialog
          maxWidth={"false"}
          open={flagLevelTablePopup}
          data={
            <ViewCarrierPreviewDialog
              leftSideHeaders={leftSideHeaders}
              rightSideHeaders={rightSideHeaders}
              editDetails={editDetails}
              flagAfterLevelPopoverRender={flagAfterLevelPopoverRender}
              flagCustomizationCarriarSectionDialog={flagCustomizationCarriarSectionDialog}
              flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
              deleteRowPopupFlag={deleteRowPopupFlag}
              isSelectedRow={isSelectedRow}
              hideTransparentCell={hideTransparentCell}
              flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
              selectedRowGroupIdOfDialog={selectedRowGroupIdOfDialog}
              setFlagLevelTablePopup={setFlagLevelTablePopup}
              setSelectedRowGroupOfDialog={setSelectedRowGroupOfDialog}
              carrierDetails={carrierDetails}
              userRoleAdmin={userRoleAdmin}
              userRoleMember={userRoleMember}
              agencyNameBrokersAlliance={agencyNameBrokersAlliance}
              brokersAllianceAgent={brokersAllianceAgent}
            />
          }
        />
      )}

      {flagPdfPopup && (
        <Dialog
          open={flagPdfPopup}
          maxWidth={"xxl"}
          sx={{
            zIndex: 2005,
            "& .MuiPaper-root": { width: '100% !important', }
          }}
        >
          <CarrierPDFViewerDialog
            setFlagPdfPopup={setFlagPdfPopup}
            imageUrl={imageUrl}
          />
        </Dialog>
      )}

      {flagFileUploadPopup && (
        <GlobalDialog
          open={flagFileUploadPopup}
          data={
            <FileUploadDialog
              flagFileUploadPopup={flagFileUploadPopup}
              setFlagFileUploadPopup={setFlagFileUploadPopup}
              carrierDetails={carrierDetails}
              setFlagCall={setFlagCall}
              flagCall={flagCall}
            />
          }
          maxWidth={"sm"}
        />
      )}

      {flagDeletePopup && (
        <GlobalDialog
          open={flagDeletePopup}
          data={
            <DeleteResourceAlertDialog
              deleteResourceData={deleteResourceData}
              setFlagDeletePopup={setFlagDeletePopup}
              DeleteResouceFile={DeleteResouceFile}
            />
          }
          maxWidth={"sm"}
        />
      )}

      {flagDeleteCarriarSectionDialog && (
        <GlobalDialog
          onClose={() => {
            setFlagDialogGloballyRender(false)
          }}
          open={flagDeleteCarriarSectionDialog}
          data={<DeleteCarriarSectionConfirmationDialog />}
          maxWidth={"xs"}
        />
      )}

      {true && (
        <GlobalDialog
          open={flagUnsavedChangesDialog}
          data={
            <UnsavedChangesDialog
              setFlagUnsavedChangesDialog={setFlagUnsavedChangesDialog}
              setEditDetails={setEditDetails}
              setHideTransperentCell={setHideTransperentCell}
              setCarrierDetails={setCarrierDetails}
              carrierDetailsDuplicate={carrierDetailsDuplicate}
              removeUnsavedChangesHandler={removeUnsavedChangesHandler}
            />}
          className={"unsaved-changes-backdrop"}
          maxWidth={"sm"}
        />
      )}

      {flagDialogGloballyRender && (
        <Backdrop
          sx={{ color: '#fff', zIndex: 108 }}
          open={flagDialogGloballyRender}
        >
        </Backdrop>
      )}

      {flagAfterLevelPopoverRender && (
        <Backdrop
          sx={{ color: '#fff', zIndex: 1101 }}
          open={flagAfterLevelPopoverRender}
        >
        </Backdrop>
      )}

      {flagCarrierCustomizationGlobalDialog && (
        <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
      )}

      {flagCarrierCustomizationGlobalDeleteDialog &&
        <div style={{ position: "absolute", top: "0", left: "0", height: '100vh', width: '100%', zIndex: '100' }}></div>
      }

      <div id="deleteHighlighter" style={{ position: "fixed", top: "0", left: "0", border: "1px solid #1565c0", width: "0px", height: "40px", zIndex: "108" }}></div>

      <Helmet title="myAdvisorGrids | Carrier View" />

      {/* NAVIGATION SECTION */}
      <Grid container justifyContent="center" className="mainPageStickyHeader">
        <Grid item xs={12} sm={12} md={12} lg={11.6} xl={12}>
          <Grid container rowGap={2} alignItems="center" width="100%" marginTop={"7px"}>
            <Grid item
              xs={editDetails ? 12 : 11}
              sm={editDetails ? 8 : 8.5}
              md={editDetails ? 8 : 8.5}
              lg={editDetails ? 6 : 3}
              xl={editDetails ? 6 : 4}
            >
              <Grid container >

                <Grid item xs={12}>
                  <Grid container sx={{ alignContent: "center" }}>
                    {!editDetails &&
                      <IconButton
                        sx={{ padding: "0px 10px 0px 0px" }}
                        onClick={() => navigate("/carriers")}
                      >
                        <img
                          src={BackIcon}
                          alt="Logo"
                          width="28px"
                          height="28px"
                          text-align="center"
                          justify-content="center"
                        />
                      </IconButton>
                    }

                    {!editDetails &&
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                      >
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          className="vc-nav-carrier-select"
                          disableUnderline
                          value={searchParams.get("id")}
                          MenuProps={{
                            className: 'vc-nav-carrier-dropdown-backdrop',
                          }}
                          PaperProps={
                            { className: 'vc-nav-carrier-dropdown-paper' }
                          }
                          onChange={(e) => {
                            let navigateURL = brokersAllianceAgent ? `/carriers/view_carrier?id=${e.target.value}` : `/carriers/view_carrier?id=${e.target.value}&version_id=`
                            navigate(navigateURL)
                            let value = e.target.value
                            setFlagCall(e.target.value)
                          }}
                        >
                          {
                            carrierData?.map((option) => {

                              const matchedCarrierId = carrierTypeDetailsForAgency?.find(data => data.carrier_type_id == carrierTypeId)?.carrier_type_id || null
                              const carrierTypes = option?.carrier_types
                              let carrier = undefined
                              if (matchedCarrierId) {
                                carrier = carrierTypes?.find(carrier => carrier?.carrier_type_id == matchedCarrierId)
                                if (carrier == undefined) {
                                  carrier = carrierTypes?.find(carrier => carrier?.carrier_type_id != matchedCarrierId)
                                }
                              }
                              // if (carrierTypeId === "life") {
                              //   value = option?.carrier_types?.filter(carrier => carrier.carrier_type_flag)[0]?.carrier_id
                              //   if (value == undefined) { value = option?.carrier_types?.filter(carrier => !carrier.carrier_type_flag)[0]?.carrier_id }
                              // }
                              // else {
                              //   value = option?.carrier_types?.filter(carrier => !carrier.carrier_type_flag)[0]?.carrier_id
                              //   if (value == undefined) { value = option?.carrier_types?.filter(carrier => carrier.carrier_type_flag)[0]?.carrier_id }
                              // }
                              return (
                                <MenuItem
                                  key={option?.carrier_name}
                                  value={carrier?.carrier_id}
                                >
                                  {option.carrier_name}
                                </MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                    }
                    {editDetails &&
                      <Grid item>
                        <Grid container sx={{ flexDirection: "column" }}>
                          <Grid item sx={{ margin: "0px 0px 2px" }}>
                            <TextField
                              autoComplete="off"
                              variant="standard"
                              className="vc-edit-carrier-field"
                              value={carrierDetails?.carrier_name}
                              placeholder={carrierPlaceholderName}
                              onBlur={(e) => {
                                const trimmedName = carrierDetails?.carrier_name?.trim();
                                const trimmedNameDuplicate = carrierNameDetailsDuplicate?.carrier_name?.trim();
                                // if (!skipCheckOnBlur) {
                                if (trimmedName != trimmedNameDuplicate) {
                                  handleCarrierNameExistence(e); // Call API only if trimmed name is not empty
                                } else {
                                  setFlagCarrierNameExist(false); // Reset if no change or only whitespace
                                }
                                // }
                                // setSkipCheckOnBlur(false)
                              }}
                              onFocus={(e) => {
                                const trimmedNameDuplicate = carrierNameDetailsDuplicate?.carrier_name?.trim();
                                if (e?.target?.value?.trim() == trimmedNameDuplicate) {
                                  setFlagCarrierNameExist(false); // Disable save/cancel if value matches original
                                } else {
                                  setFlagCarrierNameExist(true); // Enable save/cancel if value is different
                                }
                              }}
                              onChange={(e) => {
                                setCarrierDetails({
                                  ...carrierDetails,
                                  carrier_name: e.target.value
                                })
                                if (e.target.value == carrierNameDetailsDuplicate?.carrier_name) {
                                  setFlagCarrierNameExist(false); // Disable save/cancel if value matches original
                                } else {
                                  setFlagCarrierNameExist(true); // Enable save/cancel if value is different
                                }
                                setCarrierExitenceErrorMessage("");
                              }}
                            />
                          </Grid>
                          {carrierExitenceErrorMessage &&
                            <Grid item className="vc-carrier-error-item">
                              <Typography variant="span">
                                {carrierExitenceErrorMessage ? carrierExitenceErrorMessage : ""}
                              </Typography>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {!editDetails &&
              <Grid item xs={1} sm={3.5} md={3.5} lg={4} xl={4} justifyContent={"end"}>
                <div className="displaySliderButtonAbove">
                  <LifeAnnuityButtons
                    editDetails={editDetails}
                    carrierTypeId={carrierTypeId}
                    setChangeInCarrierType={setChangeInCarrierType}
                    setCarrierTypeId={setCarrierTypeId}
                    carrierDetails={carrierDetails}
                  />
                </div>

                {carrierDetails.carrier_version_latest && userRoleAdmin &&
                  <div className="displayEditButtonAbove">
                    <Button
                      className="viewCarrier_EditIconBtn"
                      variant="contained"
                      onClick={() => setEditDetails(true)}
                      size="small"
                    >
                      <EditIcon />
                    </Button>
                  </div>
                }

                {(carrierDetails.carrier_version_latest && userRoleAdmin && agencyNameBrokersAlliance) &&
                  <div className="displayEditCarrierButtonAbove">
                    <Button
                      className="viewCarrier_EditBtn"
                      startIcon={<EditIcon />}
                      sx={{
                        size: "small",
                        borderRadius: "4px",
                        backgroundColor: "#1565C0",
                        marginRight: "20px",
                        padding: "8px 20px",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#1565C0"
                        }
                      }}
                      onClick={() => {
                        setEditDetails(true)
                        setHideTransperentCell(true)
                        setFlagShowAddIcons(false)
                      }}
                    >
                      Edit Carrier
                    </Button>
                  </div>
                }
              </Grid>
            }

            <Grid item
              xs={12}
              sm={editDetails ? 4 : 12}
              md={editDetails ? 4 : 12}
              lg={editDetails ? 6 : 5}
              xl={editDetails ? 6 : 4}
              display={'flex'}
              justifyContent={'end'}
            >
              <Grid container>
                <Grid item xs={12} >
                  {editDetails ?
                    <Box className="viewCarrier_SaveCancel_Box" sx={{ justifyContent: "end" }}>
                      <Button
                        disabled={flagShowAddIcons || flagCarrierNameExist}
                        variant="outlined"
                        onClick={() => {
                          if (tableContainerRef.current) {
                            tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                          }
                          setFlagUnsavedChangesDialog(true)
                        }}
                        sx={{ padding: "7px 40px", marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        disabled={flagShowAddIcons || flagCarrierNameExist}
                        onClick={(e) => {
                          if (currentlyAddedCols && currentlyAddedCols?.length > 0 && flagShowAddIcons) {
                            UpdateSnackbarMessage({
                              status: "error",
                              message: "Please first finish level addition process"
                            })
                            return;
                          }
                          handleValidation()
                        }}
                        sx={{
                          padding: "7px 40px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                          backgroundColor: "#1565C0",
                          color: "white",
                          "&:hover": {
                            color: "white",
                            backgroundColor: "#1565C0"
                          }
                        }}>
                        {PathName == "/carriers/add_carrier" ? "Create" : "Save"}
                      </Button>
                    </Box>
                    :
                    <Box className="viewCarrier_EditBtnVersionList_Container" sx={{ justifyContent: "end" }}>
                      {(carrierDetails.carrier_version_latest && userRoleAdmin && agencyNameBrokersAlliance) ?
                        (
                          <div className="displayEditButtonBelow">
                            <Button
                              className="viewCarrier_EditBtn"
                              startIcon={<EditIcon />}
                              sx={{
                                size: "small",
                                borderRadius: "4px",
                                backgroundColor: "#1565C0",
                                marginRight: "20px",
                                padding: "8px 20px",
                                color: "white",
                                "&:hover": {
                                  color: "white",
                                  backgroundColor: "#1565C0"
                                }
                              }}
                              onClick={() => {
                                setEditDetails(true)
                                setHideTransperentCell(true)
                                setFlagShowAddIcons(false)
                              }}>
                              Edit Carrier
                            </Button>
                          </div>
                        ) : ("")
                      }

                      <div className="displaySliderButtonBelow">
                        <LifeAnnuityButtons
                          editDetails={editDetails}
                          carrierTypeId={carrierTypeId}
                          setChangeInCarrierType={setChangeInCarrierType}
                          setCarrierTypeId={setCarrierTypeId}
                          carrierDetails={carrierDetails}
                        />
                      </div>

                      {userRoleAdmin && agencyNameBrokersAlliance &&
                        <FormControl className="viewCarrier_VersionList_Dropdown">
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                              backgroundColor: "#EFEFF0",
                              "& .MuiSelect-select": {
                                padding: "10px 14px",
                              },
                              "& fieldset": {
                                border: "none"
                              }
                            }}
                            value={currentVersion}
                            onChange={(e) => {
                              handleChangeVersion(e)
                            }}
                          >
                            {carrierVersionList?.map((element) => {
                              const currentVersionList = new Date(element?.carrier_version_timestamp);
                              const day = currentVersionList.getUTCDate();
                              const month = currentVersionList.getUTCMonth() + 1; // Add 1 to month since it's zero-indexed
                              const year = currentVersionList.getUTCFullYear();
                              const hours = currentVersionList.getUTCHours();
                              const minutes = currentVersionList.getUTCMinutes();
                              const seconds = currentVersionList.getUTCSeconds();
                              let formattedDate = "";
                              formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day} ${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds} ${element.carrier_version_latest ? '(Current version)' : ''}`.trim()

                              return (
                                <MenuItem value={element?.carrier_version_id}>{formattedDate}</MenuItem>
                              )
                            })
                            }
                          </Select>
                        </FormControl>
                      }
                    </Box>
                  }
                </Grid>

                {editDetails &&
                  <Grid item xs={12} className="cg-crs-text-item" sx={{ textAlign: 'end' }}>
                    <Typography component={'span'} sx={{ color: "#777777", fontSize: "12px", fontWeight: "500" }}>
                      Note: Changes such as Create, Edit and Delete will only take effect when the
                      <Typography component={'span'} sx={{ color: "#000", fontSize: "12px", fontWeight: "500" }}> Save </Typography>button is pressed.
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider my={2} mb={4} />
        </Grid>
      </Grid>
      {/* NAVIGATION SECTION */}



      {/* CONTENT SECTION */}
      <Grid container spacing={2} mt={1} sx={{ maxWidth: { xs: "100vw", md: "87vw" }, margin: "auto" }}>
        <Grid item xs={12} id={"carrier_view_container"} className="main_box_shadow cg-view-carrier-inner-scrollable-wrapper" sx={{ backgroundColor: "#FFFFFF", padding: "15px !important", }}>
          <Grid container sx={{ maxHeight: "100%", }}>

            {/* ----------- CARRIER DETAILS SECTION ----------- */}
            <Grid item xs={12}>
              <Accordion
                disableGutters
                className="carrier_dertails_accordian"
                sx={{
                  backgroundColor: "#EFEFF0",
                  padding: "0px 9px 15px",
                  marginBottom: "20px !important",
                }}
                expanded={!editDetails ? flagCarrierDetailsExpand : true}
              >
                <AccordionSummary
                  expandIcon={!editDetails && <ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ color: "#1565C0", fontWeight: "600", fontSize: "16px", paddingLeft: editDetails ? "9px" : "25px" }}
                  onClick={() => {
                    if (editDetails) {
                      return
                    }
                    setFlagCarrierDetailsExpand(!flagCarrierDetailsExpand)
                    setEditDetails(false)
                  }}
                >
                  Carrier Details
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container rowGap={4} padding={editDetails ? "0px" : "0px 25px"} >
                    <Grid item xs={12} sm={12} md={12} lg={8.7} xl={editDetails ? 7.5 : userRoleAdmin && agencyNameBrokersAlliance ? 9 : 12}>
                      <Grid container spacing={5} justifyContent={"space-between"} sx={{ height: "100%" }}>
                        <Grid item xs={12}>
                          <Grid container
                            sx={{
                              display: "grid",
                              gridTemplateRows: "repeat(6, auto)",
                              gridAutoFlow: "column",
                              gridTemplateColumns: `repeat(${Math.ceil(carrierKeyDetails.carrier_data_column_left?.length / 6)}, 1fr)`,
                              gap: "8px",
                              columnGap: '20px',
                              '@media (max-width: 840px)': {
                                gridTemplateRows: `repeat(${carrierKeyDetails.carrier_data_column_left?.length}, auto)`,
                                gridTemplateColumns: "repeat(1, 1fr)"
                              }
                            }}
                          >
                            {carrierKeyDetails?.carrier_data_column_left?.map((ele, ind) => {
                              const fatchDetails = carrierDetails?.carrier_details?.carrier_data_column_left?.find(dt => dt.carrier_detail_field_id == ele?.carrier_detail_field_id)
                              return (
                                <>
                                  <Grid item key={ind} sx={{ height: 'fit-content' }}>
                                    <Grid container alignItems={"start"} sx={{}} >

                                      <Grid item className="carrierVersion_DetailsLable">
                                        <CustomLabelTypography sx={{ color: "#7A7A7A" }}>
                                          {ele?.carrier_detail_field_name}
                                        </CustomLabelTypography>
                                      </Grid>

                                      <Grid item className="carrierVersion_DetailsContent">
                                        <TextField
                                          key={ind}
                                          id="standard-read-only-input"
                                          multiline
                                          value={fatchDetails?.carrier_detail_field_value}
                                          variant="standard"
                                          fullWidth
                                          InputProps={{
                                            className: editDetails && "vc-version-custom-textfield",
                                            readOnly: !editDetails,
                                            style: { fontSize: "15px" },
                                            disableUnderline: !editDetails,
                                          }}
                                          onChange={(e) => {
                                            handleCarrierDetailsChangeLeft(e, ele?.carrier_detail_field_id)
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )
                            })}
                          </Grid>
                        </Grid>
                        {carrierDetails?.carrier_data_column_left?.filter(dt => dt?.carrier_detail_field_id == 15)[0].carrier_detail_flag_show_field == true && (
                          <>
                            {
                              !editDetails && <Grid item xs={12} display={"flex"} alignItems={'center'} justifyContent={'center'} >
                                <Typography
                                  className="carrier-details-footer-Note"
                                >
                                  All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                                </Typography>
                              </Grid>
                            }
                          </>
                        )
                        }
                        {editDetails ? (<></>) : (
                          <>
                            <Grid item xs={12} sx={{ textAlign: "center", marginBottom: '10px' }}>
                              <Typography variant="span" sx={{ color: "#1565C0", fontSize: "13px", fontWeight: "500" }}>
                                All percentages are subject to change. This grid does not serve as a binding contract. Please contact your BA recruiter with any questions you may have.
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    {userRoleAdmin && agencyNameBrokersAlliance ? (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={0.3} xl={0.3} container direction={"row"} justifyContent="center">
                          <Divider orientation={minScreenWidth ? 'horizontal' : 'vertical'} sx={minScreenWidth ? { display: 'flex', width: "100%" } : {}} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={editDetails ? 4.2 : 2.7}>
                          <Grid
                            container
                            flexDirection="column"
                            rowGap={3}
                          >
                            <Grid item xs={12} textAlign={"center"}>
                              <Typography variant="span"
                                sx={{
                                  textTransform: "none",
                                  color: "#00345E",
                                  fontSize: "15px",
                                  fontWeight: "600"
                                }}>
                                Internal Info Only
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container rowGap={editDetails ? 3 : 0}>
                                {
                                  carrierKeyDetails?.carrier_data_column_right?.map((ele, index) => {
                                    const fatchDetails = carrierDetails?.carrier_details?.carrier_data_column_right?.find(dt => dt.carrier_detail_field_id == ele?.carrier_detail_field_id)
                                    return (
                                      <>
                                        <Grid key={index} item xs={12}>
                                          <Grid container className="viewCarrier_Internalinfo_Container" alignItems={'start'}>
                                            <Grid item className="viewCarrier_Internalinfo_itemLable" >
                                              <CustomLabelTypography sx={{ color: "#7A7A7A", marginRight: "10px" }}>
                                                {ele?.carrier_detail_field_name}
                                              </CustomLabelTypography>
                                            </Grid>
                                            <Grid item className="viewCarrier_Internalinfo_itemContent" sx={{ wordBreak: "break-all" }}>
                                              {
                                                fatchDetails?.carrier_detail_field_name == 'Drive Folder' ?
                                                  (
                                                    editDetails ?
                                                      <TextField
                                                        key={index}
                                                        id="standard-read-only-input"
                                                        defaultValue={fatchDetails?.carrier_detail_field_value}
                                                        variant="standard"
                                                        fullWidth
                                                        multiline
                                                        InputProps={{
                                                          className: editDetails && "vc-version-custom-textfield",
                                                          readOnly: !editDetails,
                                                          style: { fontSize: "15px" },
                                                          disableUnderline: !editDetails,
                                                        }}
                                                        onChange={(e) => {
                                                          handleCarrierDetailsChangeRight(e, ele?.carrier_detail_field_id)
                                                        }}
                                                      />
                                                      :
                                                      <Link href={fatchDetails?.carrier_detail_field_value} target="_blank" >
                                                        {fatchDetails?.carrier_detail_field_value}
                                                      </Link>
                                                  )
                                                  :
                                                  (
                                                    <TextField
                                                      key={index}
                                                      id="standard-read-only-input"
                                                      // defaultValue={row?.carrier_detail_field_value}
                                                      value={fatchDetails?.carrier_detail_field_value}
                                                      variant="standard"
                                                      fullWidth
                                                      multiline
                                                      InputProps={{
                                                        className: editDetails && "vc-version-custom-textfield",
                                                        readOnly: !editDetails,
                                                        style: { fontSize: "15px" },
                                                        disableUnderline: !editDetails,
                                                      }}
                                                      onChange={(e) => {
                                                        handleCarrierDetailsChangeRight(e, ele?.carrier_detail_field_id)
                                                      }}
                                                    />
                                                  )
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )
                                  }

                                  )
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (<></>)}
                  </Grid>
                  {/* New GRID END */}
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* ----------- CARRIER DETAILS SECTION ----------- */}


            {/* ----------- RESOURCE SECTION ----------- */}
            {!editDetails &&
              <Grid item xs={12}>
                <ResourcesAccordians
                  userRole={userDetail[0]?.user_role_name}
                  carrierDetails={carrierDetails}
                  setImageUrl={setImageUrl}
                  imageUrl={imageUrl}
                  flagResourceExpand={flagResourceExpand}
                  setFlagResourceExpand={setFlagResourceExpand}
                  setFlagFileUploadPopup={setFlagFileUploadPopup}
                  setFlagCall={setFlagCall}
                  flagCall={flagCall}
                  setFlagPdfPopup={setFlagPdfPopup}
                  setDeleteresourceData={setDeleteresourceData}
                  setFlagDeletePopup={setFlagDeletePopup}
                />
              </Grid>
            }
            {/* ----------- RESOURCE SECTION ----------- */}


            {/* ----------- TABLE SECTION ----------- */}
            <Grid item xs={12} className={"vw-levels-table-main-item"}>
              <Grid container>

                {/* COLUMN LEVEL POPOVER*/}
                <div>
                  <Popover
                    id={columnId}
                    open={columnCellPopoverOpen}
                    anchorEl={anchorElForLevelsColumn.type}
                    onClose={handleCloseColumnPopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId) <= 6 ? 'left' : "right"
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: PathName === "/carriers/view_carrier" ? (carrierDetails?.carrier_level?.length - anchorElForLevelsColumn?.columnId <= 6 ? 'right' : "left") : "left"
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '300px',
                        borderRadius: '10px',
                      }
                    }}
                    className="vw-action-popover"
                    transitionDuration={500}
                  >
                    <Grid container>
                      <Grid item xs={12} sx={{ padding: "10px" }}>
                        <Grid container>
                          {
                            carrierDetails?.carrier_level?.length != 0 &&
                            <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Select
                                    fullWidth
                                    variant="standard"
                                    size="medium"
                                    value={newLevelData?.carrier_level_after}
                                    onChange={(e) => {
                                      setLevelSelectedData({
                                        name: e.target.value,
                                        value: e.target.value
                                      })
                                    }}
                                  >
                                    {carrierDetails?.carrier_level?.map((level) => (
                                      <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                <Typography variant="span" sx={{ color: "red", fontSize: "12px", fontWeight: "500" }}>*</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  size="small"
                                  variant="standard"
                                  fullWidth
                                  error={emptyLevelName}
                                  value={newLevelData.carrier_level_name}
                                  onChange={(e) => {
                                    setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                  }}
                                ></TextField>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Select
                                  fullWidth
                                  variant="standard"
                                  size="medium"
                                  MenuProps={{ sx: { zIndex: 2002 } }}
                                  value={{
                                    carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                    carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
                                    carrier_level_color_name: newLevelData?.carrier_level_color_name,
                                  }}
                                  renderValue={() =>
                                    <div>
                                      <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      </span>
                                      <span>{newLevelData.carrier_level_color_name}</span>
                                    </div>}
                                  onChange={(e) => {
                                    setNewLevelData({ ...newLevelData, carrier_level_color_id: Number(e.target.value?.carrier_level_color_id), carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name })
                                  }}
                                >
                                  {colorList?.map((color) => {
                                    return (
                                      <MenuItem
                                        value={{
                                          carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                          carrier_level_color_id: Number(color.carrier_level_color_id),
                                          carrier_level_color_name: color.carrier_level_color_name,
                                        }}
                                      >
                                        <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}></span>
                                        <span value={color}>{color?.carrier_level_color_name}</span>
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "25px 0px 10px" }}>
                            <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                              <Grid item xs={5}>
                                <Button
                                  variant="outlined"
                                  size="medium"
                                  fullWidth
                                  onClick={() => {
                                    setFlagAfterLevelPopoverRender(false)
                                    handleCloseColumnPopover();
                                    setNewLevelData({
                                      carrier_level_after: null,
                                      carrier_level_id: null,
                                      carrier_level_name: "",
                                      carrier_ba_level_flag: false,
                                      // carrier_level_sequence: 1,
                                      carrier_level_color_id: 1,
                                      carrier_level_color_hex_code: "#1b2430",
                                      carrier_level_color_name: "Default"
                                    })
                                    setEmptyLevelName(false)
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Grid>
                              <Grid item xs={5}>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  fullWidth
                                  onClick={() => { handleAddNewLevel() }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>


                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>
                {/* COLUMN LEVEL POPOVER*/}


                {/* INSERT ROW POPOVER*/}
                <div>
                  <Popover
                    id={rowId}
                    open={rowPopOveropen}
                    anchorEl={anchorElForRow}
                    onClose={handleAddRowPopOverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '150px',
                        borderRadius: '10px'
                      }
                    }}
                    className="vw-action-popover"
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11}>
                                <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Add New Row</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <IconButton sx={{ padding: "2px", }} onClick={() => handleAddRowPopOverClose()}>
                                  <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Typography
                                  variant="span"
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    setAnchorElForRow(null)
                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx)
                                  }}
                                >
                                  Insert Above
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Typography
                                  variant="span"
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    setAnchorElForRow(null)
                                    addNewRowForSection(selectedRowIndex?.groupData, selectedRowIndex?.levelIdx, selectedRowIndex?.groupIdx + 1)
                                  }}
                                >
                                  Insert Below
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>
                {/* INSERT ROW POPOVER*/}


                {/* ADD NEW SECTION POPOVER */}
                <div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      style: {
                        padding: "8px 15px 10px",
                        width: '180px',
                        borderRadius: '10px'
                      }
                    }}
                    className="vw-action-popover"
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11}>
                                <Typography variant="span" sx={{ fontSize: "11px", fontWeight: "400", color: "#909090" }}>Action Name</Typography>
                              </Grid>
                              <Grid item xs={1} sx={{ textAlign: "center" }}>
                                <IconButton sx={{ padding: "2px", }}>
                                  <CloseIcon style={{ fontSize: "15px", color: "#909090", fontWeight: "600" }} onClick={() => handleClose()} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button
                                  variant="text"
                                  fullWidth
                                  className="vw-popover-action-text"
                                  onClick={() => {
                                    addNewSection(selectedLinePopover.groups, selectedLinePopover.index)
                                  }}
                                >
                                  Add New Section
                                </Button>
                              </Grid>

                              <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                <Button
                                  variant="text"
                                  // disabled = {}
                                  fullWidth
                                  className={
                                    // ADDED : LAST ROW OF THE TABLE IS REMAINING 
                                    PathName === "/carriers/add_carrier" && carrierLevelLength ? "vw-vw-popover-btn-disable" : disableAddNewGroup ? "vw-vw-popover-btn-disable" : "vw-popover-action-text"
                                    // PathName === "/carriers/add_carrier" ? (carrierLevelLength ? "vw-vw-popover-btn-disable" : "vw-popover-action-text") : "vw-popover-action-text"
                                  }
                                  disabled={
                                    // (PathName === "/carriers/add_carrier" && carrierDetails?.carrier_level_data?.length == 1) ? true : false}
                                    (PathName === "/carriers/add_carrier" && carrierDetails?.carrier_product?.length == 1) ? true : disableAddNewGroup ? true : false}
                                  onClick={() => {
                                    setDeleteRowPopupFlag(true)
                                    setSelectedRowGroup({
                                      selectId: null,
                                      selectedId: selectedRowGroupId.selectId
                                    })
                                    handleClose()
                                    handleClose()
                                    setSectionOrRow('section')
                                    handleClose()
                                    setTimeout(() => {
                                      let rowToHighLight = document.querySelector(`[data-section-id="${selectedRowGroupId.selectId}"]`);
                                      let rect = rowToHighLight.getBoundingClientRect();
                                      let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                      let tableContainer = document.getElementById('custom-table').getBoundingClientRect();

                                      let sectionToHighLight = carrierDetails?.carrier_product?.filter(group => group?.carrier_product_id == selectedRowGroupId.selectId || group?.carrier_product_id_custom == selectedRowGroupId.selectId)[0]
                                      let top = rect.y;
                                      let left = tableContainer.left + 24;
                                      let width = tableContainer.width - 22;
                                      let height = 40 * sectionToHighLight?.carrier_product_data?.length;
                                      hiddenHighLighter.style.top = `${top}px`;
                                      hiddenHighLighter.style.width = `${width}px`;
                                      hiddenHighLighter.style.left = `${left}px`;
                                      hiddenHighLighter.style.height = `${height}px`;
                                      hiddenHighLighter.style.border = `1px solid #376fd0`;

                                      document.body.style.overflow = 'hidden';
                                      document.getElementById("carrier_view_container").style.overflow = "hidden"
                                    }, 700)

                                    document.body.style.overflow = 'hidden';
                                    document.getElementById("carrier_view_container").style.overflow = "hidden"
                                  }}>
                                  Delete Section
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Popover>
                </div>
                {/* ADD NEW SECTION POPOVER */}

                <Grid item xs={12} className="vw-levels-heading-main-item">
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography className="accordionSummury_Label" >
                        Levels
                      </Typography>
                    </Grid>
                    <Grid item display={editDetails ? "inline" : flagLevelAccordionExpanded ? "inline" : "none"}>
                      {editDetails ?
                        (<>
                          <Grid container>
                            {flagShowAddIcons ?
                              (
                                <Grid container gap={"0.5rem"}>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      size="medium"
                                      disabled={currentlyAddedCols && currentlyAddedCols?.length == 0}
                                      onClick={() => {
                                        setFlagShowAddIcons(!flagShowAddIcons)
                                        setCurrentlyAddedCols([])
                                      }}
                                    >
                                      Continue
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      size="medium"
                                      onClick={() => {
                                        if (tableContainerRef.current) {
                                          tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
                                        }
                                        handleRevertColumnAddition();
                                        setFlagShowAddIcons(!flagShowAddIcons)
                                        setCurrentlyAddedCols([])
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                </Grid>

                              ) : (
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => {
                                      carrierDetails?.carrier_level?.length > 0 ?
                                        setFlagShowAddIcons(!flagShowAddIcons) :
                                        carrierDetails?.carrier_level?.length === 0 ?
                                          handleOpenColumnPopover(undefined, 1, document.getElementById('blank-tablecell'))
                                          :
                                          setAddLevels(false)
                                      setFlagShowAddIcons(true)

                                    }}
                                  >
                                    Levels
                                  </Button>
                                </Grid>
                              )
                            }
                          </Grid>
                        </>
                        ) : (
                          <>
                            <Grid container sx={{ gap: "10px" }}>
                              <Grid item>
                                <ZoomOutMapIcon sx={{ cursor: 'pointer' }} onClick={() => setFlagLevelTablePopup(true)} />
                              </Grid>
                              <Grid item>
                                {flagShowCloseLevelSection ?
                                  <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} /> :
                                  <KeyboardArrowDownIcon sx={{ cursor: "pointer" }} onClick={() => { setFlagShowCloseLevelSection(!flagShowCloseLevelSection) }} />
                                }
                              </Grid>
                            </Grid>
                          </>
                        )
                      }
                    </Grid>
                  </Grid>
                </Grid>

                {flagShowCloseLevelSection ? (
                  <>
                    <Grid item xs={12} className="custom_grid" id="custom-grid" textAlign="center" sx={{ position: "relative" }}>
                      {deleteRowPopupFlag && (
                        <DeleteCarrierSectionDialog
                          sectionOrRow={sectionOrRow}
                          deleteRowPopupFlag={deleteRowPopupFlag}
                          setDeleteRowPopupFlag={setDeleteRowPopupFlag}
                          deleteSection={deleteSection}
                          deleteRowForSection={deleteRowForSection}
                          extraFunction={handleDeletePopOverExtraFuncs}
                          flagDialogGloballyRender={flagDialogGloballyRender}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                          setFlagAfterLevelPopoverRender={setFlagAfterLevelPopoverRender}
                        />
                      )}

                      {flagCarrierCustomizationDeleteDialog && (
                        <DeleteCarrierLevelPopUp
                          flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                          setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}

                          flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                          setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                          carrierDetails={carrierDetails}
                          setCarrierDetails={setCarrierDetails}
                          levelSelectedData={levelSelectedData}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}
                          setHideTransperentCell={setHideTransperentCell}
                        />
                      )}

                      {flagCustomizationCarriarSectionDialog && (
                        <LevelCustomizationDialog
                          carrierDetails={carrierDetails}
                          setCarrierDetails={setCarrierDetails}
                          levelSelectedData={levelSelectedData}
                          colorList={colorList}
                          setFlagCustomizationCarriarSectionDialog={setFlagCustomizationCarriarSectionDialog}
                          setFlagCarrierCustomizationGlobalDialog={setFlagCarrierCustomizationGlobalDialog}

                          flagCarrierCustomizationDeleteDialog={flagCarrierCustomizationDeleteDialog}
                          setFlagCarrierCustomizationDeleteDialog={setFlagCarrierCustomizationDeleteDialog}
                          setFlagDialogGloballyRender={setFlagDialogGloballyRender}

                          flagCarrierCustomizationGlobalDeleteDialog={flagCarrierCustomizationGlobalDeleteDialog}
                          setFlagCarrierCustomizationGlobalDeleteDialog={setFlagCarrierCustomizationGlobalDeleteDialog}
                          carrierId={searchParams.get("id")}
                          setSelectedAddIcon={setSelectedAddIcon}
                          setHideTransperentCell={setHideTransperentCell}
                          setCurrentlyAddedCols={setCurrentlyAddedCols}
                        />
                      )}

                      {/* --------------- TABLE JSX PART STARTS HERE -------------- */}
                      <Grid container className="" sx={{ justifyContent: "center", position: "relative" }}>
                        <TableContainer
                          className="vc-main-table-contanier "
                          sx={{ height: "55vh", position: "relative", paddingTop: "1px" }}
                          component={Paper}
                          id="custom-table"
                          ref={tableContainerRef}
                        >

                          <div style={{ position: "absolute", top: "40px", left: sliderCoordinates?.x, zIndex: "2002", transition: "all 1s", display: "none", textAlign: "left", width: "325px" }} id="addLevelPopup">
                            <Box className={`AddColumn_Popper ${(slectedAddIcon > carrierDetails?.carrier_level?.length - 7 && carrierDetails?.carrier_level?.length - 7 > 0) ? "position-right" : "position-left"} `}>
                              <Grid item xs={12} sx={{ padding: "10px" }}>
                                <Grid container>
                                  {carrierDetails?.carrier_level?.length != 0 &&
                                    <Grid item xs={12} sx={{ margin: "0px 0px 5px" }}>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>After Level</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Select
                                            fullWidth
                                            variant="standard"
                                            size="medium"
                                            MenuProps={{
                                              className: 'vc-addLevel-dropdown-backdrop',
                                            }}
                                            value={newLevelData?.carrier_level_after == "None" ? "None" : newLevelData?.carrier_level_after}
                                            onChange={(e) => {
                                              setSelectedAddIcon(null)
                                              setNewLevelData({ ...newLevelData, carrier_level_after: e.target.value })
                                              if (e.target.value === "None") {
                                                setSelectedAddIcon("nullPlus")
                                                setLevelSelectedData({
                                                  name: e.target.name,
                                                  value: e.target.value
                                                })
                                              } else {
                                                const levelIndex = carrierDetails?.carrier_level.find((level) => level.carrier_level_id == e.target.value || level?.carrier_level_id_custom == e.target.value)
                                                setSelectedAddIcon(levelIndex.carrier_level_id || levelIndex.carrier_level_id_custom)
                                                setLevelSelectedData({
                                                  name: e.target.name,
                                                  value: e.target.value
                                                })
                                              }
                                              let dataIdAttr = e.target.value == "None" ? (carrierDetails?.carrier_level[0].carrier_level_id || carrierDetails?.carrier_level[0].carrier_level_id_custom) : e.target.value
                                              const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                              const popup = document.getElementById('addLevelPopup')
                                              element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                              setSliderCoordinates({
                                                x: e.target.value == "None" ? element.offsetLeft - 8 : (element.offsetLeft + popup.clientWidth) > document.getElementById("vc-level-table").clientWidth ? (element.offsetLeft + element.offsetWidth - popup.clientWidth - 8) : element.offsetLeft + element.offsetWidth - 8,
                                                y: element.x
                                              })

                                              document.getElementById('addLevelPopup').style.position = "none"
                                            }}
                                          >
                                            <MenuItem value={"None"} label={"None"}>None</MenuItem>
                                            {carrierDetails?.carrier_level?.map((level) => (
                                              <MenuItem value={level.carrier_level_id || level.carrier_level_id_custom} label={level?.carrier_level_name}>{level?.carrier_level_name}</MenuItem>
                                            ))}
                                          </Select>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  }

                                  <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container sx={{ alignItems: "center" }}>
                                          <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Level Name</Typography>
                                          <Typography variant="span" sx={{ color: "red", fontSize: "14px", fontWeight: "500" }}>*</Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          size="small"
                                          variant="standard"
                                          error={emptyLevelName}
                                          fullWidth
                                          value={newLevelData.carrier_level_name}
                                          onChange={(e) => {
                                            setNewLevelData({ ...newLevelData, carrier_level_name: e.target.value })
                                          }}
                                        ></TextField>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} sx={{ margin: "5px 0px" }}>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Typography variant="span" sx={{ color: "#7A7A7A", fontSize: "12px", fontWeight: "500" }}>Select Color</Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Select
                                          fullWidth
                                          variant="standard"
                                          size="medium"
                                          value={{
                                            carrier_level_color_hex_code: newLevelData?.carrier_level_color_hex_code,
                                            carrier_level_color_id: Number(newLevelData?.carrier_level_color_id),
                                            carrier_level_color_name: newLevelData?.carrier_level_color_name,
                                          }}
                                          MenuProps={{
                                            className: 'vc-addLevel-dropdown-backdrop',
                                            sx: { zIndex: 2002 }
                                          }}
                                          onChange={(e) => {
                                            setNewLevelData({ ...newLevelData, carrier_level_color_id: Number(e.target.value?.carrier_level_color_id), carrier_level_color_hex_code: e.target.value, carrier_level_color_name: e.target.name })
                                            setSelectedColor({
                                              color: e.target.value.carrier_level_color_name,
                                              hexCode: e.target.value.carrier_level_color_hex_code
                                            })
                                            setNewLevelData({ ...newLevelData, carrier_level_color_hex_code: e.target.value.carrier_level_color_hex_code, carrier_level_color_name: e.target.value.carrier_level_color_name, carrier_level_color_id: Number(e.target.value.carrier_level_color_id) })

                                          }}
                                          renderValue={() =>
                                            <div>
                                              <span className='lcd-dropdown-colorbox' style={{ background: newLevelData.carrier_level_color_hex_code }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              </span>
                                              <span>{newLevelData.carrier_level_color_name}</span>
                                            </div>}
                                        >
                                          {
                                            colorList?.map((color, i) => {
                                              return (
                                                <MenuItem key={i} value={{
                                                  carrier_level_color_hex_code: color.carrier_level_color_hex_code,
                                                  carrier_level_color_id: Number(color.carrier_level_color_id),
                                                  carrier_level_color_name: color.carrier_level_color_name,

                                                }}>
                                                  <span className='lcd-dropdown-colorbox' style={{ background: color?.carrier_level_color_hex_code }}>

                                                  </span>
                                                  <span value={color}>{color?.carrier_level_color_name}</span>
                                                </MenuItem>
                                              )
                                            })
                                          }
                                        </Select>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={12} sx={{ margin: "25px 0px 10px" }}>
                                    <Grid container sx={{ justifyContent: "center", gap: "10px" }}>
                                      <Grid item xs={5}>
                                        <Button
                                          variant="outlined"
                                          size="medium"
                                          fullWidth
                                          onClick={() => {
                                            setSelectedAddIcon(null)
                                            document.getElementById('addLevelPopup').style.display = "none"
                                            setHideTransperentCell(true)
                                            // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                            setFlagAfterLevelPopoverRender(false)
                                            setNewLevelData({
                                              carrier_level_after: null,
                                              carrier_level_id: null,
                                              carrier_level_name: "",
                                              carrier_ba_level_flag: false,
                                              // carrier_level_sequence: 1,
                                              carrier_level_color_id: 1,
                                              carrier_level_color_hex_code: "#1b2430",
                                              carrier_level_color_name: "Default"
                                            })
                                            setFlagAfterLevelPopoverRender(false)
                                            setEmptyLevelName(false)
                                            tableContainerRef.current.style.overflow = 'auto'
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <Button
                                          variant="contained"
                                          size="medium"
                                          fullWidth
                                          onClick={() => {
                                            handleAddNewLevel()
                                            setHideTransperentCell(true)
                                            setFlagDialogGloballyRender(false)
                                            // HERE PUT THE BACKDROP CONDITION FOR HIDE APPNAVBAR
                                            tableContainerRef.current.style.overflow = 'auto'

                                          }}
                                        >
                                          Add
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>

                          <Table
                            id="vc-level-table"
                            sx={{ position: "relative", width: "auto !important", minWidth: "100%" }}
                            className="templateCarrierTable"
                            aria-label="a dense table"
                            size="small"
                            stickyHeader
                            ref={tableRef}
                          >
                            <TableHead>
                              {/*********************  HIDE LEVEL COLUMN **********************/}
                              {(flagShowAddIcons && !deleteRowPopupFlag) &&
                                <TableRow className="leveltable_IconRow vc_icons_row table_icon_row">
                                  <TableCell className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-1" sx={{ border: "none", paddingRight: "8px" }} data-type="header-plus-static-cells">
                                    {/* blank */}
                                  </TableCell>

                                  <TableCell className="leveltable_IconCell-staticsticky-table-head sticky-carrier-table-head-2" data-type="header-plus-static-cells">
                                    {/* types */}
                                  </TableCell>

                                  <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-3"
                                    stickyHeader
                                  >
                                    {/* state */}
                                  </TableCell>

                                  <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-4"
                                    stickyHeader
                                    sx={{ borderRight: leftSideHeaders?.length == 0 ? "2px solid #EFEFF0" : "" }}
                                  >
                                    {/* Product */}
                                  </TableCell>

                                  {/* <TableCell
                                    data-type="header-plus-static-cells"
                                    className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-5"
                                    stickyHeader
                                    sx={{ borderRight: "2px solid white", display: 'block', border: "none" }}
                                  >
                                    sub name 5
                                  </TableCell> */}

                                  {(userRoleAdmin || userRoleMember) ?
                                    (
                                      <TableCell
                                        // className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-6 add-level-plus-icon-cell"
                                        // THIS IS UPDATED BECAUSE CLASS NAME IS INTERCHANGE AFTER STICKY COLUMN LENGTH FROM 5 TO 4 
                                        className="leveltable_IconCell-static sticky-table-head sticky-carrier-table-head-6-updated"
                                        sx={{ borderRight: "2px solid white", display: 'block', border: "none" }}
                                        data-type="header-plus-static-cells"
                                      >
                                        {/* ba total cc */}
                                      </TableCell>
                                    ) : (
                                      <>
                                        {leftSideHeaders?.map((leftCell, leftIndex) => {
                                          return (
                                            <>
                                              {flagShowAddIcons && slectedAddIcon === leftIndex ?
                                                (<TableCell
                                                  className="leveltable_IconCell addLeft_Border carrier-left-head-tablecell sticky-table-head sticky-carrier-table-head-6"
                                                  stickyHeader
                                                  onClick={(e) => {
                                                    document.getElementById('addLevelPopup').style.display = "block";
                                                    const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                    setSliderCoordinates({
                                                      x: element.offsetLeft + element.offsetWidth - 8,
                                                      y: element.x
                                                    })
                                                    setLevelSelectedData(leftCell)
                                                    setFlagCustomizationCarriarSectionDialog(true)
                                                    setFlagAfterLevelPopoverRender(true)
                                                    setHideTransperentCell(false)
                                                    setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })

                                                  }}
                                                  sx={{
                                                    borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                    background: `${leftCell?.carrier_level_color_hex_code} !important`,
                                                    cursor: editDetails ? "pointer" : "auto"
                                                  }}
                                                >
                                                  <IconButton className={(editDetails && slectedAddIcon == leftIndex) ? "buleIcon" : "leveltable_IconButton"}  >
                                                    <AddCircleIcon />
                                                  </IconButton>
                                                </TableCell >)
                                                : (<></>)
                                              }
                                            </>
                                          )
                                        })}
                                      </>
                                    )
                                  }

                                  {(userRoleAdmin || userRoleMember) ?
                                    carrierDetails?.carrier_level?.map((levelRow, i) => {
                                      let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                      const firstCondition = slectedAddIcon == carrierId;
                                      const secondCondition = isColHover == carrierId
                                      const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                      return (
                                        <>
                                          {flagShowAddIcons ?
                                            (<TableCell
                                              sx={{
                                                borderLeft:
                                                  (i == 0 && slectedAddIcon == null && (isColHover == null || isColHover !== "nullPlus")) ? "1.5px solid #c0c0c0"
                                                    : (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0"
                                                      : "none",
                                                textAlign: slectedAddIcon === 'nullPlus' ? "left !important" : "right !important"
                                              }}
                                              className={
                                                isBlueLeftBorder ? "blue-left-border zero-Padding "
                                                  : (slectedAddIcon === null) ? "addLeft_Border pointer-event leveltable_IconCell"
                                                    : (slectedAddIcon === 'nullPlus') ? "leveltable_IconCell" : "leveltable_IconCell"
                                              }
                                              stickyHeader
                                              data-parent-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                              onClick={(e) => {
                                              }}
                                            >
                                              <Grid container>
                                                <Grid item xs={6}>
                                                  {i == 0 && (
                                                    <Grid container position={"relative"}>
                                                      <IconButton
                                                        data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                        aria-describedby={columnId}
                                                        sx={{
                                                          display: slectedAddIcon != null && slectedAddIcon != "nullPlus" ? "none" : "",
                                                        }}
                                                        className={(editDetails && slectedAddIcon === "nullPlus") || (isColHover == "nullPlus") ? "first-blue-Addicon " : "first-leveltable_IconButton"}
                                                        onClick={(e) => {
                                                          if (tableContainerRef.current) {
                                                            tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                            tableContainerRef.current.style.overflow = 'hidden'
                                                          }
                                                          setSelectedAddIcon('nullPlus')
                                                          setAddColumn(levelRow.carrier_level_id)
                                                          setFlagAfterLevelPopoverRender(true)
                                                          document.getElementById('addLevelPopup').style.display = "block";
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                          element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft - 8,
                                                            y: element.x
                                                          })
                                                          setNewLevelData({ ...newLevelData, carrier_level_after: null })
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setColHover("nullPlus")
                                                          let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                          const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);

                                                          if (element) {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft - 8,
                                                              y: element.x
                                                            })
                                                            let showPoover = document.getElementById('addLevelPopup');
                                                            showPoover.classList.add('addLevelPopup-popover-animation')
                                                          }
                                                        }}
                                                        onMouseLeave={() => setColHover(null)}
                                                      >
                                                        <AddCircleIcon
                                                          fontSize="10px"
                                                        />
                                                      </IconButton>
                                                    </Grid>
                                                  )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <Grid container display={"flex"} justifyContent={"end"} position={"relative"}>
                                                    <IconButton
                                                      disableRipple
                                                      aria-describedby={columnId}
                                                      data-id={levelRow?.carrier_level_id || levelRow?.carrier_level_id_custom}
                                                      onClick={(e) => {
                                                        if (tableContainerRef.current) {
                                                          tableContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
                                                          tableContainerRef.current.style.overflow = 'hidden'
                                                        }
                                                        setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                        setAddColumn(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                                        setFlagAfterLevelPopoverRender(true)
                                                        let dataIdAttr = e.currentTarget.getAttribute('data-id')
                                                        const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                        document.getElementById('addLevelPopup').style.display = "block";
                                                        const butnRect = element.getBoundingClientRect()
                                                        const totalWidth = butnRect.left + 325 - 50
                                                        const gridItem = document.getElementById("custom-grid")
                                                        const gridItemWidth = gridItem.clientWidth + 50
                                                        if (totalWidth >= gridItemWidth) {
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                            y: element.x
                                                          })
                                                        } else {
                                                          setSliderCoordinates({
                                                            x: element.offsetLeft + element.offsetWidth - 8,
                                                            y: element.x
                                                          })
                                                        }
                                                        setNewLevelData({ ...newLevelData, carrier_level_after: dataIdAttr })
                                                      }}

                                                      onMouseEnter={(e) => {
                                                        setColHover(levelRow.carrier_level_id || levelRow?.carrier_level_id_custom);
                                                        let dataIdAttr = e.currentTarget.getAttribute('data-id');
                                                        const element = document.querySelector(`[data-parent-id="${dataIdAttr}"]`);
                                                        const butnRect = element.getBoundingClientRect()
                                                        const totalWidth = butnRect.left + 325 - 50
                                                        const gridItemWidth = document.getElementById("custom-grid").clientWidth + 50
                                                        if (element) {
                                                          if (totalWidth >= gridItemWidth) {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8 - 325,
                                                              y: element.x
                                                            })
                                                          } else {
                                                            setSliderCoordinates({
                                                              x: element.offsetLeft + element.offsetWidth - 8,
                                                              y: element.x
                                                            })
                                                          }
                                                          let showPoover = document.getElementById('addLevelPopup');
                                                          showPoover.classList.add('addLevelPopup-popover-animation')
                                                        }
                                                      }}
                                                      onMouseLeave={() => setColHover(null)}
                                                      sx={{ display: slectedAddIcon !== null && slectedAddIcon !== carrierId ? "none" : "" }}
                                                      className={isBlueLeftBorder ? "blue-Addicon" : "leveltable_IconButton"}
                                                      disableElevation={true}
                                                    >
                                                      <AddCircleIcon
                                                        fontSize="10px"
                                                      />
                                                    </IconButton>

                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </TableCell >) : (<></>)
                                          }

                                        </>
                                      );

                                    })
                                    :
                                    rightSideHeaders?.map((rightCell, i) => {
                                      return (
                                        <>
                                          {flagShowAddIcons ?
                                            <TableCell
                                              align="left"
                                              stickyHeader
                                              className="leveltable_IconCell addLeft_Border"
                                              data-id={rightCell?.carrier_level_id || rightCell?.carrier_level_id_custom}
                                              onClick={(e) => {
                                                setHideTransperentCell(false)
                                                setFlagAfterLevelPopoverRender(true)
                                                document.getElementById('addLevelPopup').style.display = "block";
                                                const element = document.querySelector(`[data-id="${e.currentTarget.getAttribute('data-id')}"]`);
                                                element.scrollIntoView({ behavior: "smooth", inline: "center", block: "nearest" });
                                                setSliderCoordinates({
                                                  x: element.offsetLeft + element.offsetWidth - 8,
                                                  y: element.x
                                                })
                                                setNewLevelData({ ...newLevelData, carrier_level_after: e.currentTarget.getAttribute('data-id') })
                                              }}
                                            >
                                              <IconButton className="leveltable_IconButton">
                                                <AddCircleIcon />
                                              </IconButton>
                                            </TableCell> : <></>
                                          }
                                        </>
                                      );
                                    })
                                  }
                                  {900 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                    <TableCell
                                      id="blank-tablecell"
                                      data-type="header-plus-static-cells"
                                      className={`sticky-table-head new-carrier-blank-row ${editDetails ? "addLeft_Border" : ""}`}
                                      sx={{
                                        width: `${900 - carrierDetails?.carrier_level?.length * 60}px`,
                                        backgroundColor: "transparent",
                                        color: "#ffffff"
                                      }}
                                      colSpan={4}
                                    >
                                    </TableCell>
                                  }
                                </TableRow>
                              }
                              {/*********************  HIDE LEVEL COLUMN **********************/}


                              {/*********************  CARRIER HEADER  **********************/}
                              <TableRow className={flagShowAddIcons && !deleteRowPopupFlag ? "vc_header_row-top" : "vc_header_row"}>
                                {(hideTransparentCell && !flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                  <TableCell
                                    className={`sticky-table-head  table-right-table-cells hide-vc-tbl-cell-by-bg sticky-carrier-table-head-1 `}
                                    sx={{
                                      border: "none",
                                      borderBottom: "none",
                                      paddingRight: "8px"
                                    }}
                                  >
                                  </TableCell>
                                }

                                <TableCell
                                  stickyHeader
                                  className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-2 ${hideTransparentCell ? "sticky-carrier-table-head-2" : "cell-with-left-prop"} ${editDetails ? "addLeft_Border" : ""}`}
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}
                                >
                                  Product
                                </TableCell>

                                {/* <TableCell
                                  stickyHeader
                                  className={`sticky-carrier-table-head-3 carrier-head-columns-padding ${editDetails ? "addLeft_Border" : ""} sticky-table-head`}
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}
                                >
                                  AI Product ID
                                </TableCell> */}

                                <TableCell
                                  stickyHeader
                                  className={`sticky-carrier-table-head-3 carrier-head-columns-padding ${editDetails ? "addLeft_Border" : ""} sticky-table-head`}
                                  // className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-4 ${editDetails ? "addLeft_Border" : ""}`}
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    color: "#ffffff"
                                  }}
                                >
                                  Criteria
                                </TableCell>

                                <TableCell
                                  stickyHeader
                                  // className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-5 ${editDetails ? "addLeft_Border" : ""}`}
                                  className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-4 ${editDetails ? "addLeft_Border" : ""}`}
                                  sx={{
                                    backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                    borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0" : "",
                                    color: "#ffffff"
                                  }}
                                >
                                  Notes
                                </TableCell>

                                {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                  <TableCell
                                    stickyHeader
                                    // THIS IS UPDATED BECAUSE CLASS NAME IS INTERCHANGE AFTER STICKY COLUMN LENGTH FROM 5 TO 4 
                                    className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-6-updated ${editDetails ? "addLeft_Border" : ""}`}
                                    // className={`sticky-table-head carrier-head-columns-padding sticky-carrier-table-head-6 ${editDetails ? "addLeft_Border" : ""}`}
                                    sx={{
                                      borderRight: carrierDetails?.carrier_level?.length == 0 ? "2px solid white" : (!editDetails ? "3px solid #EFEFF0" : editDetails && '1px solid #000000'),
                                      color: "#ffffff",
                                      // HERE UPDATE BG COLOR FOR THIS COLUMN 
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#F1C232" : deleteRowPopupFlag ? "#F1C232" : "#F1C232",
                                    }}
                                  >
                                    BA Total Comp
                                  </TableCell>
                                  :
                                  <>
                                    {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                    {/* {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" && */}
                                    <>
                                      {leftSideHeaders?.map((leftCell, leftIndex) => {
                                        return (
                                          <TableCell
                                            className={`header-color carrier-head-columns-padding carrier-table-head-6-updated-agent ${editDetails ? "addLeft_Border" : ""}`}
                                            sx={{
                                              background: `${(slectedAddIcon === leftCell.carrier_level_id || slectedAddIcon === leftCell.carrier_level_id_custom) ? "#1b2430" : leftCell?.carrier_level_color_hex_code} !important`,
                                              cursor: editDetails ? "pointer" : "auto",
                                              borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                            }}
                                            stickyHeader
                                            onDoubleClick={() => {
                                              if (editDetails) {
                                                setLevelSelectedData(leftCell)
                                                setFlagCustomizationCarriarSectionDialog(true)
                                                setSelectedAddIcon(leftCell.carrier_level_id || leftCell.carrier_level_id_custom)
                                                setFlagShowAddIcons(false)
                                                setHideTransperentCell(false)
                                              }
                                            }}
                                          >
                                            <div>
                                              {leftCell?.carrier_level_name}
                                            </div>
                                          </TableCell>
                                        )
                                      })}
                                    </>
                                    {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                    {/* } */}
                                  </>
                                }

                                {(userRoleAdmin || userRoleMember) ?
                                  carrierDetails?.carrier_level?.map((levelRow, i) => {
                                    let carrierId = levelRow.carrier_level_id || levelRow.carrier_level_id_custom
                                    const firstCondition = slectedAddIcon == carrierId;
                                    const secondCondition = isColHover == carrierId
                                    const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                    return (
                                      <TableCell
                                        className={`header-color cell-width  ${isBlueLeftBorder ? "blue-left-border" : editDetails ? "addLeft_Border add_padding" : ""} `}
                                        align="left"
                                        stickyHeader
                                        sx={{
                                          padding: "0px",
                                          zIndex: slectedAddIcon == null ? "2" :
                                            ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && !flagCustomizationCarriarSectionDialog) ? "1300" :
                                              ((slectedAddIcon == levelRow.carrier_level_id || slectedAddIcon == levelRow?.carrier_level_id_custom) && flagCustomizationCarriarSectionDialog) ? "2000" : "2",
                                          cursor: editDetails ? "pointer" : "auto",
                                          background: `${levelRow?.carrier_level_color_hex_code} !important`,
                                          borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : (i === 0 && editDetails) && "1.5px solid #EFEFF0",
                                        }}
                                        onDoubleClick={() => {
                                          if (editDetails) {
                                            setLevelSelectedData(levelRow)
                                            setFlagCustomizationCarriarSectionDialog(true)
                                            setSelectedAddIcon(levelRow.carrier_level_id || levelRow.carrier_level_id_custom)
                                            setFlagShowAddIcons(false)
                                            setHideTransperentCell(false)
                                          }
                                        }}
                                      >
                                        <div className="vc-tablecell-header-div">{levelRow?.carrier_level_name}</div>
                                      </TableCell>
                                    );
                                  })
                                  :
                                  rightSideHeaders?.map((rightCell, i) => {
                                    return (
                                      <TableCell
                                        className={`header-color scrolling_header_cell ${editDetails ? "addLeft_Border" : ""}`}
                                        align="left"
                                        stickyHeader
                                        onDoubleClick={() => {
                                          if (editDetails) {
                                            setLevelSelectedData(rightCell)
                                            setFlagCustomizationCarriarSectionDialog(true)
                                            setSelectedAddIcon(rightCell.carrier_level_id || rightCell.carrier_level_id_custom)
                                            setFlagShowAddIcons(false)
                                            setHideTransperentCell(false)
                                          }
                                        }}
                                        sx={{
                                          background: `${(slectedAddIcon == rightCell.carrier_level_id || slectedAddIcon == rightCell.carrier_level_id_custom) ? "#1b2430" : rightCell?.carrier_level_color_hex_code} !important`,
                                          cursor: editDetails ? "pointer" : "auto",
                                          borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1px solid #1565c0" : "",
                                        }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          {rightCell?.carrier_level_name}
                                        </div>
                                      </TableCell>
                                    );
                                  })
                                }

                                {900 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                  <TableCell
                                    id="blank-tablecell"
                                    className={`sticky-table-head ${editDetails ? "addLeft_Border" : ""}`}
                                    colSpan={4}
                                    sx={{
                                      width: `${900 - carrierDetails?.carrier_level?.length * 60}px`,
                                      backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                      color: "#ffffff"
                                    }}
                                  >
                                  </TableCell>
                                }
                              </TableRow>
                              {/*********************  CARRIER HEADER  **********************/}
                            </TableHead>

                            <ClickAwayListener onClickAway={() => setSelectedRowGroup((prevId) => ({ ...prevId, selectedId: null }))} >
                              <TableBody>
                                {(hideTransparentCell && !deleteRowPopupFlag && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                  <TableRow>
                                    <TableCell
                                      className={`vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                      sx={{
                                        position: "sticky",
                                        zIndex: "110 !important",
                                        left: "0",
                                        background: flagCarrierCustomizationDeleteDialog || flagCustomizationCarriarSectionDialog || deleteRowPopupFlag ? 'transparent' : '#efeff0',
                                      }} >
                                      <Grid container>
                                        <Grid item xs={6}>
                                          <Grid container justifyContent={'end'} >
                                            <div
                                              className={`${(selectedLine == 0) ? "selected-dot-icon" : "default-dot-icon"}`}
                                              onClick={(e) => {
                                                setSelectedRowGroup({
                                                  selectId: carrierDetails?.carrier_product[0].carrier_product_id || carrierDetails?.carrier_product[0].carrier_product_id_custom,
                                                  selectedId: null
                                                })
                                                setSelectedLinePopover({
                                                  groups: carrierDetails?.carrier_product[0],
                                                  index: 0
                                                })
                                                setSelectedLine(0)
                                                setAnchorEl(e.currentTarget)
                                              }}
                                              onMouseEnter={() => {
                                                setSelectedLine(0)
                                              }}
                                              onMouseLeave={() => {
                                                if (anchorEl == null) {
                                                  setSelectedLine(null)
                                                }
                                              }}
                                            >
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={6} margin={'auto'}>
                                          {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                        </Grid>
                                      </Grid>
                                    </TableCell>

                                    <TableCell
                                      colSpan={numsOfCarrierLevelCols + 6}
                                      className={`shubh-2 vc-table-transparent-cell sticky-table-body first-add-section-cell`}
                                    >
                                      {selectedLine == 0 && <Divider fullWidth color="#1565C0" className="vc-rowgroup-divider" />}
                                    </TableCell>
                                  </TableRow>
                                }

                                {carrierDetails?.carrier_product?.map((groups, j) => {
                                  return (
                                    groups?.carrier_product_data?.map((groupData, i) => {

                                      let flagSaprator = groups?.carrier_product_data.length == i + 1 ? true : false;
                                      let leftSideData = groupData?.carrier_product_commission_data?.filter((obj) => obj?.carrier_ba_level_flag == true)
                                      let rightSideData = groupData?.carrier_product_commission_data?.filter((obj) => !obj?.carrier_ba_level_flag)

                                      const selectedGroupId = groups?.carrier_product_id || groups.carrier_product_id_custom
                                      const sectedRowId = groups?.carrier_product_data[i].carrier_product_data_id || groups?.carrier_product_data[i].carrier_product_data_id_custom
                                      const carrierGroupDataLen = groups?.carrier_product_data?.length
                                      let present = groups?.carrier_product_id || groups?.carrier_product_id_custom
                                      return (
                                        <>
                                          <TableRow
                                            data-row-id={groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}
                                            data-section-id={present}
                                            key={groups?.carrier_product_id || groups?.carrier_product_id_custom}
                                            className={`${editDetails ? 'vc-editable-tabel-row vc-editable-tabel-row-highlight' : "vc-table-row"} 
                                              ${selectedGroupId === selectedRowGroupId.selectedId ? carrierGroupDataLen <= 1 ? "border-all border-container" :
                                                `${i === 0 ? "border-top" : flagSaprator ? "border-bottom" : "bordered-left-right"} border-container` :
                                                sectedRowId === isSelectedRow ? "border-all border-container" : ""
                                              }`
                                            }
                                            onClick={() => {
                                              setSelectedRowGroup((prevId) => ({ ...prevId, selectedId: selectedGroupId }))
                                            }}
                                            onMouseEnter={() => {
                                              if (editDetails) {
                                                if (groupData.carrier_product_data_id) {
                                                  setAddRow(groupData.carrier_product_data_id)
                                                }
                                                else if (groupData?.carrier_product_data_id_custom) {
                                                  setAddRow(groupData.carrier_product_data_id_custom)
                                                }
                                                else {
                                                  setAddRow(null)
                                                }
                                              }
                                            }}
                                            onMouseLeave={editDetails ? () => { anchorElForRow == null ? setAddRow(null) : console.log("..") } : () => { }}>
                                            {(hideTransparentCell && (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog)) &&
                                              <TableCell
                                                className={`vc-table-transparent-cell 
                                                sticky-table-body 
                                                sticky-carrier-table-body-1 
                                                add_padding hide-vc-tbl-cell-by-bg `}
                                                sx={{
                                                  border: "none",
                                                  borderBottom: "none",
                                                  paddingRight: "8px !important"
                                                }}
                                              >
                                                {(addRow === groupData.carrier_product_data_id || addRow === groupData.carrier_product_data_id_custom) &&
                                                  <Box className="vc-addremove-btn-container" aria-describedby={selectedGroupId} >
                                                    <IconButton
                                                      className="vc-addremove-buttons"
                                                      onClick={(e) => {
                                                        handleAddRowPopOverClick(e, groupData, j, i)
                                                      }}
                                                    >
                                                      <AddCircleOutlineIcon className="vc-addremove-buttons" />
                                                    </IconButton>

                                                    <IconButton
                                                      className="vc-addremove-buttons"
                                                      // ADDED AFTER : LAST ROW OF THE TABLE IS REMAINING 
                                                      disabled={PathName === "/carriers/add_carrier" ? (carrierGroupDataLen <= 1 || carrierLevelLength >= 2) : (carrierDetails?.carrier_product.length <= 1 && carrierGroupDataLen <= 1)}
                                                      // disabled={PathName === "/carriers/add_carrier" ? (carrierGroupDataLen <= 1 || carrierLevelLength >= 2) : false}
                                                      onClick={() => {
                                                        setSelectedRowIndex({
                                                          groupData: groupData,
                                                          levelIdx: j,
                                                          groupIdx: i
                                                        })
                                                        setSectionOrRow("row")
                                                        setSelectedRow(sectedRowId)
                                                        setFlagDialogGloballyRender(true)
                                                        setDeleteRowPopupFlag(true)
                                                        setDeleteRow(groupData.carrier_product_data_id_custom)
                                                        setTimeout(() => {
                                                          let rowToHighLight = document.querySelector(`[data-row-id="${groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}"]`);
                                                          let rect = rowToHighLight.getBoundingClientRect();
                                                          let hiddenHighLighter = document.getElementById('deleteHighlighter')
                                                          let tableContainer = document.getElementById('custom-table').getBoundingClientRect();
                                                          let top = rect.y;
                                                          let left = tableContainer.left + 24;
                                                          let width = tableContainer.width - 22;
                                                          let height = 40;
                                                          hiddenHighLighter.style.top = `${top}px`;
                                                          hiddenHighLighter.style.width = `${width}px`;
                                                          hiddenHighLighter.style.left = `${left}px`;
                                                          hiddenHighLighter.style.height = `${height}px`;
                                                          hiddenHighLighter.style.border = `1px solid #376fd0`;
                                                        }, 900)
                                                        document.body.style.overflow = 'hidden';
                                                        document.getElementById("carrier_view_container").style.overflow = "hidden"

                                                      }}>
                                                      <RemoveCircleOutlineIcon className="vc-addremove-buttons" />
                                                    </IconButton>
                                                  </Box>
                                                }
                                              </TableCell>
                                            }

                                            <TableCell
                                              key={'carrier_product_name-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                              className={`sticky-table-body sticky-table-color table-left-padding-tableCell sticky-carrier-table-body-2 ${hideTransparentCell ? "sticky-carrier-table-body-2" : "cell-with-left-prop"} ${editDetails && "addLeft_Border"} add_padding `}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                              }}
                                              onKeyUp={(e) => {
                                                handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_name')
                                              }}
                                            >
                                              <Tooltip
                                                arrow
                                                title={groupData?.carrier_product_name}
                                                disableHoverListener={editDetails || groupData?.carrier_product_name?.length < 26}
                                                disableFocusListener={editDetails || groupData?.carrier_product_name?.length < 26}
                                                PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                placement="right-end"
                                              >
                                                <input
                                                  // disabled={!editDetails}
                                                  onFocus={handleFocus}  // Apply background when input is focused
                                                  onBlur={handleBlur}
                                                  onClick={handleClickInput}
                                                  className="vc-table-right-table-cells-input vc-table-product-tooltip-styles"
                                                  style={{ cursor: !editDetails ? 'default' : 'text', }}
                                                  readOnly={!editDetails}
                                                  value={groupData?.carrier_product_name}
                                                  onChange={(e) => {
                                                    handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_name')
                                                  }}
                                                />
                                              </Tooltip>
                                            </TableCell>

                                            {/* ******* BEFORE: 2ND (STATE COLUMN) *********
                                            <TableCell
                                            className={`sticky-table-body sticky-table-color sticky-carrier-table-body-3 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                              key={'carrier_product_state-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                              }}
                                            >
                                              <Tooltip
                                                arrow
                                                title={groupData?.carrier_product_state}
                                                disableHoverListener={editDetails || groupData?.carrier_product_state?.length < 20}
                                                disableFocusListener={editDetails || groupData?.carrier_product_state?.length < 20}
                                                PopperProps={{ className: 'vc-tabel-tooltip', }}
                                                placement="bottom"
                                              >
                                                <input
                                                  // disabled={!editDetails}
                                                  onFocus={handleFocus}
                                                  onBlur={handleBlur}
                                                  onClick={handleClickInput}
                                                  className="vc-table-right-table-cells-input"
                                                  value={groupData?.carrier_product_state}
                                                  style={{ cursor: !editDetails ? 'default' : 'text', }}
                                                  readOnly={!editDetails}
                                                  onChange={(e) => {
                                                    handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_state')
                                                  }}
                                                />
                                              </Tooltip>
                                            </TableCell>
                                            ********************************************* */}


                                            {/* ****** BEFORE: 3RD (PRODUCT COLUMN) ********
                                            <TableCell
                                              key={'carrier_product_name-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                              className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                              }}
                                            >
                                              {groupData?.carrier_product_name ?
                                                <Tooltip
                                                  arrow
                                                  title={groupData?.carrier_product_name}
                                                  disableHoverListener={editDetails || groupData?.carrier_product_name?.length < 20}
                                                  disableFocusListener={editDetails || groupData?.carrier_product_name?.length < 20}
                                                  PopperProps={{ className: 'vc-tabel-tooltip', }}
                                                  placement="bottom"
                                                >
                                                  <input
                                                    // disabled={!editDetails}
                                                    name="carrier_product_name"
                                                    onFocus={handleFocus}  // Apply background when input is focused
                                                    onBlur={handleBlur}
                                                    onClick={handleClickInput}
                                                    className="vc-table-right-table-cells-input vc-table-content-input-wrapper"
                                                    readOnly={!editDetails}
                                                    style={{ cursor: !editDetails ? 'default' : 'text', }}
                                                    onChange={(e) => {
                                                      handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_name')
                                                    }}
                                                    value={groupData?.carrier_product_name}
                                                  />
                                                </Tooltip>
                                                :
                                                <input
                                                  // disabled={!editDetails}
                                                  name="carrier_product_name"
                                                  onFocus={handleFocus}  // Apply background when input is focused
                                                  onBlur={handleBlur}
                                                  onClick={(event) => handleClickInput(event, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                                  className="vc-table-right-table-cells-input vc-add-criteria-input"
                                                  readOnly={!editDetails}
                                                  style={{ cursor: !editDetails ? 'default' : 'text', }}
                                                  value={"CritearAdd ea"}
                                                  onKeyDown={(event) => {
                                                    setFlagCriteriDialog(true)
                                                    setCriteriaCellData(groups)
                                                    setProductIds({
                                                      productId: groups?.carrier_product_id,
                                                      productDataIds: groupData.carrier_product_data_id
                                                    })
                                                  }}
                                                />
                                              }
                                            </TableCell>  
                                            ********************************************** */}

                                            <TableCell
                                              id={`criteria-field-${groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom}`}
                                              key={'carrier_product_criteria-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                              }}
                                              onMouseEnter={() => {
                                                if (editDetails) {
                                                  if (groupData?.carrier_product_criteria?.length > 0) {
                                                    setShowCriteriaText({
                                                      carrier_product_id: groups?.carrier_product_id || 0,
                                                      carrier_product_id_custom: groups?.carrier_product_id_custom || 0,
                                                      carrier_product_data_id: groupData?.carrier_product_data_id || 0,
                                                      carrier_product_data_id_custom: groupData?.carrier_product_data_id_custom || 0,
                                                    })
                                                  }
                                                  else { setShowCriteriaText(defaultCriteriaText) }
                                                }
                                              }}
                                              onMouseLeave={() => {
                                                if (editDetails) { setShowCriteriaText(defaultCriteriaText) }
                                              }}
                                              className={`sticky-table-body sticky-table-color sticky-carrier-table-body-3 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                            // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                            >
                                              {groupData?.carrier_product_criteria?.length > 0 ?
                                                <Tooltip
                                                  arrow
                                                  placement="right-end"
                                                  PopperProps={{ className: 'criteria-tooltip' }}
                                                  title={<div dangerouslySetInnerHTML={{ __html: generateTooltipContent(groupData?.carrier_product_criteria) }} />}
                                                  componentsProps={{
                                                    tooltip: {
                                                      sx: {
                                                        bgcolor: "#1B2430",
                                                        color: "#ffffff",
                                                        padding: "10px",
                                                        maxWidth: "350px",
                                                        "& .MuiPopper-root": {
                                                          zIndex: 2100,
                                                        },
                                                      },
                                                    },
                                                  }}
                                                >
                                                  {(
                                                    (showCriteriaText['carrier_product_id'] == groups?.carrier_product_id && showCriteriaText['carrier_product_data_id'] == groupData?.carrier_product_data_id) ||
                                                    (showCriteriaText['carrier_product_id_custom'] == groups?.carrier_product_id_custom && showCriteriaText['carrier_product_data_id_custom'] == groupData?.carrier_product_data_id_custom)
                                                  ) ?
                                                    <input
                                                      name="carrier_product_criteria"
                                                      autoComplete="off"
                                                      onFocus={handleFocus}
                                                      onBlur={handleBlur}
                                                      onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                      className="vc-table-right-table-cells-input vc-table-content-input-wrapper vc-add-criteria-input"
                                                      readOnly={!editDetails}
                                                      style={{ cursor: "pointer" }}
                                                      value={groupData?.carrier_product_criteria.length > 0 ? "Edit Criteria" : ""}
                                                      onKeyDown={(event) => {
                                                        if (editDetails) {
                                                          if (event.key === "Enter") {
                                                            setFlagCriteriDialog(true)
                                                            setCriteriaCellData(groups)
                                                            setProductIds({
                                                              productId: groups?.carrier_product_id,
                                                              productDataIds: groupData.carrier_product_data_id,
                                                              productDataCustomIds: groupData.carrier_product_data_id_custom
                                                            })
                                                          }
                                                        }
                                                      }}
                                                    />
                                                    :
                                                    <input
                                                      onFocus={handleFocus}  // Apply background when input is focused
                                                      onBlur={handleBlur}
                                                      onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                      className={`vc-table-right-table-cells-input vc-table-content-input-wrapper`}
                                                      value={generateInputContent(groupData?.carrier_product_criteria)}
                                                      style={{
                                                        maxWidth: "165px",
                                                        textOverflow: "ellipsis",
                                                        flexWrap: "nowrap",
                                                        overflow: "hidden",
                                                        display: "inline-block",
                                                        border: "none",
                                                        cursor: editDetails ? "pointer" : "default"
                                                      }}
                                                      onKeyDown={(event) => {
                                                        if (editDetails) {
                                                          if (event.key === "Enter") {
                                                            setFlagCriteriDialog(true)
                                                            setCriteriaCellData(groups)
                                                            setProductIds({
                                                              productId: groups?.carrier_product_id,
                                                              productDataIds: groupData.carrier_product_data_id,
                                                              productDataCustomIds: groupData.carrier_product_data_id_custom
                                                            })
                                                          }
                                                        }
                                                      }}
                                                    />
                                                  }
                                                </Tooltip>
                                                :
                                                <input
                                                  // disabled={!editDetails}
                                                  name="carrier_product_criteria"
                                                  autoComplete="off"
                                                  onFocus={handleFocus}  // Apply background when input is focused
                                                  onBlur={handleBlur}
                                                  className="vc-table-right-table-cells-input vc-add-criteria-input"
                                                  readOnly={!editDetails}
                                                  style={{ cursor: editDetails ? 'pointer' : 'default' }}
                                                  value={brokersAllianceAdmin && editDetails ? "Add Criteria" : ""}
                                                  onClick={(event) => handleClickInput(event, groups, groupData, "carrier_product_criteria")}
                                                  onKeyDown={(event) => {
                                                    if (editDetails) {
                                                      if (event.key === "Enter") {
                                                        setFlagCriteriDialog(true)
                                                        setCriteriaCellData(groups)
                                                        setProductIds({
                                                          productId: groups?.carrier_product_id,
                                                          productDataIds: groupData.carrier_product_data_id,
                                                          productDataCustomIds: groupData.carrier_product_data_id_custom
                                                        })
                                                      }
                                                    }
                                                  }}
                                                />
                                              }
                                            </TableCell>

                                            <TableCell
                                              key={'carrier_product_note-' + (groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom)}
                                              sx={{
                                                borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                borderRight: ((userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0"
                                                  : (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) && editDetails ? '1px solid #000000' : '')
                                              }}
                                              className={`sticky-table-body sticky-table-color sticky-carrier-table-body-4 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                            // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-5 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                            >
                                              <Tooltip
                                                arrow
                                                // title={groupData?.carrier_product_note}
                                                title={groupData?.carrier_product_note?.replace(/->/g, "")}
                                                disableHoverListener={editDetails || groupData?.carrier_product_note?.length < 25}
                                                disableFocusListener={editDetails || groupData?.carrier_product_note?.length < 25}
                                                PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                placement="right-end"
                                              >
                                                <input
                                                  // disabled={!editDetails}
                                                  onFocus={handleFocus}
                                                  onBlur={handleBlur}
                                                  onClick={handleClickInput}
                                                  readOnly={!editDetails}
                                                  style={{
                                                    cursor: !editDetails ? 'default' : 'text',
                                                    textOverflow: groupData?.carrier_product_note?.length > 25 ? "ellipsis" : "",
                                                    overflow: groupData?.carrier_product_note?.length > 25 ? "hidden" : "auto"
                                                  }}
                                                  className="vc-table-right-table-cells-input"
                                                  onChange={(e) => {
                                                    handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_note')
                                                  }}
                                                  // value={groupData?.carrier_product_note}
                                                  value={editDetails ? groupData?.carrier_product_note?.replace(/->/g, "") : groupData?.carrier_product_note?.replace(/->/g, "")}
                                                />
                                              </Tooltip>
                                            </TableCell>

                                            {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                              (
                                                <TableCell
                                                  // className={`sticky-table-body sticky-table-color sticky-carrier-table-body-6 table-left-padding-tableCell  ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                  className={`sticky-table-body sticky-table-color sticky-carrier-table-body-5 table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                  sx={{
                                                    borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                    borderRight: (!editDetails ? "2px solid #EFEFF0" : editDetails && '1px solid #000000')
                                                  }}
                                                >
                                                  <Tooltip
                                                    arrow
                                                    title={groupData?.carrier_product_ba_total_comp}
                                                    disableHoverListener={editDetails || groupData?.carrier_product_ba_total_comp?.length < 15}
                                                    disableFocusListener={editDetails || groupData?.carrier_product_ba_total_comp?.length < 15}
                                                    PopperProps={{ className: 'vc-tabel-tooltip' }}
                                                    placement="right-end"
                                                  >
                                                    <input
                                                      onFocus={handleFocus}  // Apply background when input is focused
                                                      onBlur={handleBlur}
                                                      onClick={handleClickInput}
                                                      value={groupData?.carrier_product_ba_total_comp}
                                                      className="vc-table-right-table-cells-input"
                                                      readOnly={!editDetails}
                                                      style={{
                                                        cursor: !editDetails ? 'default' : 'text',
                                                        textOverflow: groupData?.carrier_product_ba_total_comp?.length > 15 ? "ellipsis" : "",
                                                        overflow: groupData?.carrier_product_ba_total_comp?.length > 15 ? "hidden" : "auto"
                                                      }}
                                                      onChange={(e) => {
                                                        handleCarrierLevelData(e, groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom, 'carrier_product_ba_total_comp')
                                                      }}
                                                    />
                                                  </Tooltip>
                                                </TableCell>
                                              ) : (
                                                <>
                                                  {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                                  {/* {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" && */}
                                                  <>
                                                    {leftSideData?.map((leftDataCell, i) => {
                                                      return (
                                                        <TableCell
                                                          sx={{
                                                            borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                            // borderRight: i == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                            borderRight: i == 0 ? "2px solid #EFEFF0" : "",
                                                            // HERE BORDER LEFT COMMENTED BCZ IN AGENT ISSUE IS OCCUED DURING SCROLL ******
                                                            borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                          }}
                                                          className={`sticky-table-color table-left-padding-tableCell ${((editDetails && slectedAddIcon == i) || isColHover === i) ? "blue-left-border" : editDetails ? " addLeft_Border add_padding" : ""}`}
                                                        >
                                                          {editDetails ?
                                                            <input
                                                              onFocus={handleFocus}  // Apply background when input is focused
                                                              onBlur={handleBlur}
                                                              onClick={handleClickInput}
                                                              className="vc-table-right-table-cells-input"
                                                              disabled={!editDetails}
                                                              value={leftDataCell?.carrier_product_commission_value}
                                                              onChange={(e) => {
                                                                handleCarrierLevelCommissionData(
                                                                  e,
                                                                  groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                                  groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                                  leftDataCell?.carrier_product_commission_id || leftDataCell?.carrier_commission_value_id_custom
                                                                )
                                                              }}
                                                            />
                                                            :
                                                            leftDataCell?.carrier_product_commission_value
                                                          }
                                                        </TableCell>
                                                      )
                                                    })
                                                    }
                                                  </>
                                                  {/* } */}
                                                </>
                                              )
                                            }

                                            {(userRoleAdmin || userRoleMember) ?
                                              groupData?.carrier_product_commission_data?.map((commissionData, ind) => {
                                                let carrierId = commissionData.carrier_level_id || commissionData.carrier_level_id_custom
                                                const firstCondition = slectedAddIcon == carrierId;
                                                const secondCondition = isColHover == carrierId

                                                const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog
                                                const isAddLeftBorder = editDetails;
                                                return (
                                                  <TableCell
                                                    suppressContentEditableWarning
                                                    className={`vc-table-right-table-cells table-left-padding-tableCell ${isBlueLeftBorder ? "blue-left-border" : isAddLeftBorder ? "addLeft_Border add_padding" : ""}`}
                                                    sx={{
                                                      borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                      zIndex: slectedAddIcon == null ? "2" : (slectedAddIcon == commissionData.carrier_level_id || slectedAddIcon == commissionData?.carrier_level_id_custom) ? "2000" : "2",
                                                      borderLeft: (ind === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                    }}
                                                  >
                                                    {editDetails ?
                                                      <input className="vc-table-right-table-cells-input"
                                                        onFocus={handleFocus}  // Apply background when input is focused
                                                        onBlur={handleBlur}
                                                        onClick={handleClickInput}
                                                        disabled={!editDetails}
                                                        onChange={(e) => {
                                                          handleCarrierLevelCommissionData(
                                                            e,
                                                            groups?.carrier_product_id || groups?.carrier_product_id_custom, groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                            commissionData?.carrier_product_commission_id || commissionData?.carrier_commission_value_id_custom
                                                          )
                                                        }}
                                                        value={commissionData?.carrier_product_commission_value}
                                                      />
                                                      :
                                                      commissionData?.carrier_product_commission_value
                                                    }
                                                  </TableCell>
                                                )
                                              })
                                              :
                                              // HERE IS THE BA LEVEL BODY DATA SAPERATION AND APPLY BORDER RIGHT FOR DIFFERENTIATION
                                              rightSideData?.map((rightDataCell, i) => {
                                                let carrierId = rightDataCell.carrier_level_id || rightDataCell.carrier_level_id_custom
                                                const firstCondition = slectedAddIcon == carrierId;
                                                const secondCondition = isColHover == carrierId
                                                const isBlueLeftBorder = editDetails && (firstCondition || secondCondition) && !flagCustomizationCarriarSectionDialog

                                                return (
                                                  <TableCell
                                                    className={`vc-table-right-table-cells table-left-padding-tableCell ${(editDetails && slectedAddIcon == null) ? "addLeft_Border add_padding" : isBlueLeftBorder ? "blue-left-border" : ""}`}
                                                    sx={{
                                                      borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                      borderRight: i == 0 ? "2px solid  #EFEFF0" : "",
                                                      // HERE BORDER LEFT COMMENTED BCZ IN AGENT ISSUE IS OCCUED DURING SCROLL ******
                                                      borderLeft: (i === 0) && (isColHover == 'nullPlus' || slectedAddIcon === "nullPlus") ? "1.5px solid #1565c0" : "none"
                                                    }}

                                                  >
                                                    {editDetails ?
                                                      <input
                                                        className="vc-table-right-table-cells-input"
                                                        onFocus={handleFocus}  // Apply background when input is focused
                                                        onBlur={handleBlur}
                                                        onClick={handleClickInput}
                                                        value={rightDataCell?.carrier_product_commission_value}
                                                        disabled={!editDetails}
                                                        onChange={(e) => {
                                                          handleCarrierLevelCommissionData(
                                                            e,
                                                            groups?.carrier_product_id || groups?.carrier_product_id_custom,
                                                            groupData?.carrier_product_data_id || groupData?.carrier_product_data_id_custom,
                                                            rightDataCell?.carrier_product_commission_id || rightDataCell?.carrier_commission_value_id_custom
                                                          )
                                                        }}
                                                      />
                                                      :
                                                      rightDataCell?.carrier_product_commission_value
                                                    }
                                                  </TableCell>
                                                )
                                              })
                                            }

                                            { // HERE ADD CONDITION FOR ADDING AND DELETING TABLE CELLS SO PUT THE OR CONDITION 
                                              ((carrierDetails?.carrier_level?.length > 0 || carrierDetails?.carrier_level?.length == 0) && (900 - carrierDetails?.carrier_level?.length * 60 >= 60)) &&
                                              (<TableCell
                                                className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                colSpan={4}
                                                sx={{
                                                  borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                  // borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                  width: `${900 - carrierDetails?.carrier_level?.length * 60}px `
                                                }}
                                              >
                                              </TableCell>)
                                            }
                                          </TableRow>

                                          {hideTransparentCell &&
                                            <>
                                              {i == groups?.carrier_product_data.length - 1 && (
                                                <TableRow>
                                                  <TableCell
                                                    className={`vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                    sx={{
                                                      padding: '0px',
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      height: "5px",
                                                      background: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog || deleteRowPopupFlag ? "transparent" : "#efeff0",
                                                      position: "sticky",
                                                      zIndex: "110 !important",
                                                      left: "0"
                                                    }}
                                                  >
                                                    {
                                                      (!flagCustomizationCarriarSectionDialog && !flagCarrierCustomizationDeleteDialog) &&
                                                      <div className={"vw-img-div vw-img-click-div"}
                                                        onClick={(e) => {
                                                          if (carrierDetails?.carrier_product?.length == 1) {
                                                            setSelectedRowGroup({
                                                              selectId: carrierDetails?.carrier_product[0].carrier_product_id || carrierDetails?.carrier_product[0].carrier_product_id_custom,
                                                              selectedId: null
                                                            })
                                                            setSelectedLinePopover({
                                                              groups: groups,
                                                              index: j + 1
                                                            })
                                                            setAnchorEl(e.currentTarget)
                                                          }
                                                          else {
                                                            let idTempArr = j == carrierDetails?.carrier_product?.length - 1 ? carrierDetails?.carrier_product[j] : carrierDetails?.carrier_product[j + 1];
                                                            handleClick(e, idTempArr.carrier_product_id || idTempArr.carrier_product_id_custom)
                                                            setSelectedLinePopover({
                                                              groups: groups,
                                                              index: j + 1
                                                            })
                                                          }
                                                          setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                          setFlagAddRow(true)
                                                          setSectionOrRow("section")
                                                        }}
                                                      >
                                                        <div
                                                          style={{ background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "#1B243080" }}
                                                          className={`${(selectedLine == groups?.carrier_product_id || selectedLine == groups?.carrier_product_id_custom) ? "selected-dot-icon" : "default-dot-icon"}`}
                                                          onClick={() => {
                                                            let idTempArr = j == carrierDetails?.carrier_product?.length - 1 ? carrierDetails?.carrier_product[j - 2] : carrierDetails?.carrier_product[j + 1];
                                                            setSelectedRowGroup({
                                                              selectId: idTempArr?.carrier_product_id || idTempArr?.carrier_product_id_custom,
                                                              selectedId: null
                                                            })
                                                            setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                            setFlagAddRow(true)
                                                            setSelectedLinePopover({
                                                              groups: groups,
                                                              index: j + 1
                                                            })
                                                            setSectionOrRow("section")
                                                          }}
                                                          onMouseEnter={() => {
                                                            setSelectedLine(groups?.carrier_product_id || groups.carrier_product_id_custom)
                                                          }}
                                                          onMouseLeave={() => {
                                                            if (anchorEl == null) {
                                                              setSelectedLine(null)
                                                            }
                                                            else {
                                                            }
                                                          }}
                                                        >
                                                        </div>
                                                      </div>
                                                    }
                                                    {(selectedLine == present) &&
                                                      <Divider sx={{ width: "54%", background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent" }} className="vc-rowgroup-divider" />
                                                    }


                                                  </TableCell>

                                                  <TableCell
                                                    className={`shubh-1 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                    colSpan={leftSideData.length + rightSideData.length}
                                                    sx={{
                                                      padding: '0px',
                                                      height: "1px",
                                                    }}
                                                  >
                                                    {(selectedLine == present) &&
                                                      <Divider
                                                        fullWidth
                                                        className="vc-rowgroup-divider"
                                                        sx={{
                                                          background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                        }}
                                                      />
                                                    }
                                                  </TableCell>

                                                  {PathName == '/carriers/view_carrier' ? (
                                                    <>
                                                      <TableCell
                                                        className={`shubh-2 vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                        colSpan={leftSideData.length + rightSideData.length < 10 ? 10 : leftSideData.length + rightSideData.length}
                                                        sx={{
                                                          padding: '0px',
                                                          height: "1px",
                                                        }}
                                                      >
                                                        {(
                                                          selectedLine == present) &&
                                                          <Divider
                                                            fullWidth
                                                            className="vc-rowgroup-divider"
                                                            sx={{
                                                              background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                            }}
                                                          />
                                                        }
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <TableCell
                                                      className={`vc-table-transparent-cell sticky-table-body highlight-add-section-cell`}
                                                      colSpan={20}
                                                      sx={{
                                                        padding: '0px',
                                                        height: "1px",
                                                      }}
                                                    >
                                                      {(selectedLine == present) &&
                                                        <Divider
                                                          fullWidth
                                                          className="vc-rowgroup-divider"
                                                          sx={{
                                                            background: (selectedLine !== null && (selectedLine === groups?.carrier_product_id || selectedLine === groups?.carrier_product_id_custom)) ? "#1565C0" : "transparent"
                                                          }}
                                                        />
                                                      }
                                                    </TableCell>
                                                  )
                                                  }
                                                </TableRow>
                                              )}
                                            </>
                                          }
                                        </>
                                      )
                                    }))
                                })}
                              </TableBody>
                            </ClickAwayListener>
                          </Table>
                        </TableContainer>
                      </Grid>
                      {/* --------------- TABLE JSX PART STARTS HERE -------------- */}

                    </Grid>
                  </>
                ) : (<></>)}

              </Grid>
            </Grid>
            {/* ----------- TABLE SECTION ----------- */}

          </Grid>
        </Grid>
      </Grid>
      {/* CONTENT SECTION */}
    </div >
  );
};

export default ViewCarrier;
