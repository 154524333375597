import React from 'react'
import '../../css/CarriersPopup.css'
import { Close } from '@mui/icons-material'
import { Grid } from '@mui/material'
import FileUpload from '../../../components/global/FileUpload'

const FileUploadDialog = (props) => {

    const { setFlagFileUploadPopup, flagCall, carrierDetails, setFlagCall } = props

    return (
        <>
            <Grid item xs={12}>
                <Grid container justifyContent="center" className="cp-head-container">
                    <Grid item xs={11} textAlign="center" className="cp-head-item">
                        <span className="cp-label" style={{ marginLeft: "20px" }} >Upload Document</span>
                    </Grid>
                    <Grid item xs={1} className="cp-head-item">
                        <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagFileUploadPopup(false) }} />
                    </Grid>

                    <Grid item xs={10} marginTop="20px">
                        <Grid
                            container
                            sx={{
                                backgroundColor: "#efeff0",
                                marginTop: { xs: "15px", md: "0" },
                                marginBottom: "20px",
                                padding: "10px",
                                borderRadius: "5px !important"
                            }}
                        >
                            <FileUpload
                                type="file"
                                carrierDetails={carrierDetails}
                                setFlagCall={setFlagCall}
                                flagCall={flagCall}
                                setFlagFileUploadPopup={setFlagFileUploadPopup}
                                saveData={(e) => {
                                    // onChangeSave("widget_logo_id", e);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FileUploadDialog