import React from 'react'

export default function Icons(props) {

    const { iconName, iconWidth, iconHeight, iconColor } = props

    return (
        <>
            {iconName == "deleteIcon" &&
                <svg width={iconWidth ? iconWidth : "15"} height={iconHeight ? iconHeight : "15"} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8V12M7.5 8V12M10 8V12" stroke={iconColor ? iconColor : "black"} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3.5 6H1V3H14V6H11.5" stroke={iconColor ? iconColor : "black"} stroke-width="0.8" stroke-linejoin="round" />
                    <path d="M2.97232 14.0294L2.53114 6.52936C2.51425 6.24217 2.74259 6 3.03028 6H11.9697C12.2574 6 12.4858 6.24217 12.4689 6.52936L12.0277 14.0294C12.0121 14.2936 11.7933 14.5 11.5285 14.5H3.47145C3.20671 14.5 2.98786 14.2936 2.97232 14.0294Z" stroke={iconColor ? iconColor : "black"} stroke-width="0.8" stroke-linejoin="round" />
                    <path d="M4.5 3L5 1H10L10.5 3" stroke={iconColor ? iconColor : "black"} stroke-width="0.8" stroke-linejoin="round" />
                </svg>

            }
        </>
    )
}
