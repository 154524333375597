import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../../css/Agency/AgencyCarrier.css"
import {
    Grid, Typography, Box, Button, Divider,
    Select, FormControl, InputLabel, MenuItem,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Menu,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';


// ICONS && IMAGES
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import deleteAlert from "../../../assets/Delete-alert.png"

// COMPONENTS
import GlobalDialog from "../../../components/global/GlobalDialog";
import GlobalAlertPopUp from "../../../components/global/GlobalAlertPopUp";
import SelectCarrierPopUp from "./AgencyDialogs/SelectCarrierPopUp";

// API
import {
    AssignTemplateToAgency,
    GetAgencyCarrierData,
    RemoveTemplateToAgencyAPI,
    CreateUpdateAgencyCarrierDataAPI,
} from '../../../utils/api/AgencyDetailsAPI'


// CONTEXT
import { Context as agencyContext } from '../../../contexts/reducerContexts/agencyContext';
import { Context as authContext } from '../../../contexts/reducerContexts/authContext';


const AgencyCarrier = ({
    fecthAgencyCarrierLevelDataList, carrierCheckBoxes, alreadyCheckedKey, setCheckedItems,
    checkedItems, setSelectedTemplate, selectedTemplate, setCarrierCheckBoxes
}) => {
    const {
        FlagApiLoader,
        UpdateSnackbarMessage
    } = useContext(authContext)
    const {
        apiLoader,
        carrierTypeDetailsForAgency,
    } = useContext(authContext).state

    const {
        setAgencyCarrierDetails,
        setAgencyCarrierLevelList,
        setFlagRenderTableData
    } = useContext(agencyContext)

    const {
        agencyTemplateDataList,
        agencyCarrierDetails,
        agencyTemplateDetails,
        agencyCarrierLevelList,
        flagflagRenderTableData
    } = useContext(agencyContext).state

    const [searchParams, setSearchParams] = useSearchParams();
    const agencyId = searchParams.get("agency_id")
    const navigate = useNavigate()


    // PopUps States
    const [alertUnsavedChanges, setAlertUnsavedChanges] = useState(false)
    const [flagClearAllCarriersDialog, setFlagClearAllCarriersDialog] = useState(false)
    const [flagAssignTemplate, setFlagAssignTemplate] = useState(false)
    const [flagEditCarrerDialog, setFlagEditCarrerDialog] = useState(false)
    const [flagAssignTemplateDialog, setFlagAssignTemplateDialog] = useState(false)
    const [flagDeleteCarrierRow, setFlagDeleteCarrierRow] = useState(false)
    const [showCarrierPopUp, setShowCarrierPopUp] = useState(false)
    // by default carrierTypeAndId is set to 1 which is Life,
    // all the major operations of the component done this state
    const [carrierTypeAndId, setCarrierTypeAndId] = useState({ carrierTypeName: 'Life', carrierTypeId: 1 })
    const [tableRowId, setTableRowId] = useState({})
    const [editAgencyCarrier, setEditAgencyCarrier] = useState(false)
    const [agencyTemplates, setAgencyTemplates] = useState([])
    const [maxLevelLength, setMaxLevelLength] = useState(10)
    const [deleteAgCommissionCarrierAssignId, setDeleteAgCommissionCarrierAssignId] = useState([])
    const [deleteCarrierFromTableID, setDeleteCarrierFromTableID] = useState(null)
    const [deleteAgCommissionCarriers, setDeleteAgCommissionCarriers] = useState({})
    const [checkedCarrierItem, setCheckedCarrierItem] = useState(checkedItems)
    const [carrierDataToRenderTable, setCarrierDataToRederTable] = useState({})
    const [selectedAssignTemplate, setSelectedAssignTemplate] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);



    // useEffect(() => {
    //     if (carrierDataToRenderTable && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length > 0) {
    //         // FlagApiLoader(false);
    //         setFlagRenderTableData(true)
    //     }
    //     else {
    //         // FlagApiLoader(true);
    //         setFlagRenderTableData(false)
    //     }
    // }, [carrierDataToRenderTable]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (carrierTypeDetailsForAgency?.length > 0) {
            const initialStates = carrierTypeDetailsForAgency?.reduce((object, carrier) => {
                const carrierName = carrier?.carrier_type_name
                object.objectOfObject[carrierName] = {}
                object.arrayOfObject[carrierName] = []
                object.nullCarriers[carrierName] = null
                return object
            }, { arrayOfObject: {}, objectOfObject: {}, nullCarriers: {} })
            const { objectOfObject, arrayOfObject, nullCarriers } = initialStates
            // SETTING INIITAL VALUES OF ALL STATE BASED ON CARRIER TYPES 
            setTableRowId({ ...nullCarriers })
            setDeleteAgCommissionCarriers({ ...arrayOfObject })
            setCarrierDataToRederTable({ ...arrayOfObject })
        }
        setMaxLevelLength(10)
    }, [carrierTypeDetailsForAgency])

    useEffect(() => {
        setCheckedCarrierItem(checkedItems)
    }, [checkedItems])

    const GetDataToRenderTable = (carrierData) => {
        let maxLength = 0

        if (carrierData?.length != 0 && carrierData != undefined && carrierData !== null) {

            const commissionData = (
                carrierData[0]?.template_assign_flag == true ?
                    carrierData[0]?.template_data
                    :
                    carrierData[0]?.agent_carrier_assignment_data
            )

            let carrierAssignmentIdName = carrierData[0]?.template_assign_flag == true ? "template_carrier_assignment_id" : "agent_commission_carrier_assignment_id"
            // console.log('ss commissionData',commissionData)

            const carrierLevelRow = commissionData?.map((data) => {
                const rowData = {
                    carrier_name: data?.carrier_name,
                    [carrierAssignmentIdName]: carrierData[0]?.template_assign_flag == true ? data?.template_carrier_assignment_id : data?.agent_commission_carrier_assignment_id,
                    carrier_id: data?.carrier_id,
                    carrier_ba_level_name: data?.carrier_level_data?.filter(balevel => balevel?.carrier_ba_level_flag),
                    carrier_direct_level_name: data?.carrier_level_data?.filter(directlevel => directlevel?.carrier_direct_level_flag),
                    dl_level: data?.carrier_level_data?.filter(level => (!level.carrier_direct_level_flag && !level.carrier_ba_level_flag)),
                }

                //HERE FINDING WHICH DL LEVEL HAVE MAXIMUM LEVELS.
                if (rowData?.dl_level.length > maxLength) {
                    maxLength = rowData?.dl_level.length
                }

                return rowData
            })
            // console.log('ss carrierLevelRow',carrierLevelRow)

            if (maxLength < 10) {
                maxLength = 10
            }

            // HERE ADDING EMPTY OBJECT WHICH HAVE LESS NUMBER OF DL LEVEL TO MAKE EVERY ROW'S LENGTH SAME. 
            const updatedCarrierLevelRow = carrierLevelRow.map(row => ({
                ...row,
                dl_level: [...row.dl_level, ...Array(maxLength - row.dl_level.length).fill({
                    carrier_ba_level_flag: null,
                    carrier_direct_level_flag: null,
                    carrier_level_name: "",
                    template_carrier_assignment_level_id: null,
                    template_level_sequence: null,
                    carrier_level_id: null
                })
                ]
            }));

            return updatedCarrierLevelRow

        } else {
            return []
        }

    }

    useEffect(() => {
        if (Object.keys(agencyCarrierDetails).length && agencyCarrierDetails?.agency_commission_data) {
            const selectedItems = agencyCarrierDetails?.agency_commission_data?.filter(data => data?.carrier_type_id == carrierTypeAndId?.carrierTypeId)

            if (selectedItems && selectedItems.length > 0) {
                const newCheckedItems = selectedItems[0]?.template_assign_flag == true ? selectedItems[0]?.template_data : selectedItems[0]?.agent_carrier_assignment_data
                const updatedCheckedItems = {}
                newCheckedItems.forEach(carrier => {
                    updatedCheckedItems[carrier?.carrier_id] = {
                        carrier_name: carrier?.carrier_name,
                        checked: true,
                        alreadyChecked: true,
                        carrier_id: carrier?.carrier_id,
                        carrier_type_id: carrierTypeAndId?.carrierTypeId
                    }
                });
                // console.log('carrierCheckBoxes of error updatded carrioer check box', carrierCheckBoxes)
                // console.log('carrierTypeAndId', carrierTypeAndId)


                const updateCarrierCheckBoxes = carrierCheckBoxes?.[carrierTypeAndId?.carrierTypeName]?.map(data => {
                    const updateCheckedStatus = updatedCheckedItems[data?.carrier_id]

                    if (updateCheckedStatus != undefined) {
                        return {
                            ...data,
                            checked: true,
                            alreadyChecked: true,
                        }
                    } else {
                        return data
                    }
                }) || []
                // console.log('updatedCheckedItems', updatedCheckedItems)
                // console.log('updateCarrierCheckBoxes', updateCarrierCheckBoxes)

                setCheckedItems(prevState => ({ ...prevState, [carrierTypeAndId?.carrierTypeName]: { ...updatedCheckedItems } }))
                setCarrierCheckBoxes(prevState => ({ ...prevState, [carrierTypeAndId?.carrierTypeName]: [...updateCarrierCheckBoxes] }))
            }
        }
    }, [agencyCarrierDetails])

    // This useEffect used to prepare data to render below table
    useEffect(() => {
        if (carrierTypeDetailsForAgency?.length > 0) {

            const carrierTableData = carrierTypeDetailsForAgency?.reduce((carrier, types) => {
                const carrierName = types?.carrier_type_name;
                const carrierTypeId = types?.carrier_type_id
                const filteredCarrierdata = agencyCarrierDetails?.agency_commission_data?.filter(data => data?.carrier_type_id === carrierTypeId) || []
                carrier[carrierName] = GetDataToRenderTable(filteredCarrierdata)
                return carrier

            }, {})
            console.log("carrierTableData", carrierTableData);


            setCarrierDataToRederTable({ ...carrierTableData })
        } else {
            setCarrierDataToRederTable({})
        }

    }, [agencyCarrierDetails, carrierTypeDetailsForAgency])

    useLayoutEffect(() => {
        let BATemplates = []
        BATemplates = agencyTemplateDetails?.filter(data => data.carrier_type_id === carrierTypeAndId?.carrierTypeId)
        setAgencyTemplates(BATemplates)
        // this useLayoutEffect will run when we toggle between life and annuity button.
        let maxLevel = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.reduce((maxLevel, data) => data.dl_level.length > maxLevel ? data.dl_level.length : maxLevel, 0);
        if (maxLevel < 10) { maxLevel = 10 }
        setMaxLevelLength(maxLevel)

        const matchedCarrierData = agencyCarrierDetails?.agency_commission_data?.find(data => data?.carrier_type_id == carrierTypeAndId.carrierTypeId)
        setSelectedTemplate({
            template_id: matchedCarrierData?.template_id,
            carrier_type_id: matchedCarrierData?.carrier_type_id,
            template_name: matchedCarrierData?.template_name
        })
    }, [carrierTypeAndId, agencyTemplateDetails, carrierDataToRenderTable])

    const handleAssignTemplateToAgency = (apiCreadentials) => {
        FlagApiLoader(true)
        AssignTemplateToAgency(apiCreadentials,
            (res) => {
                if (res.data.status) {
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message
                    })
                    setEditAgencyCarrier(false)
                    GetAgencyCarrierData(
                        agencyId,
                        (res) => {
                            if (res?.data?.status) {
                                const data = res?.data?.data?.[0]
                                // HERE EXTRACTING ALL THE CARRIER IDs TO GET CARRIER LIST FOR THE TABLE ROWs DROPDOWN.  
                                let carrierIdsObject = {}
                                data?.agency_commission_data?.forEach(agc_data => {
                                    const carrier = carrierTypeDetailsForAgency?.find(carrier => carrier?.carrier_type_id === agc_data.carrier_type_id);
                                    if (carrier) {
                                        const carrierIds = agc_data.template_assign_flag
                                            ? agc_data.template_data?.map(tmp_data => tmp_data.carrier_id)
                                            : agc_data.agent_carrier_assignment_data?.map(aca_data => aca_data.carrier_id);
                                        // Add to carrierIdsObject
                                        carrierIdsObject[carrier?.carrier_type_name] = carrierIds || [];
                                    }
                                })
                                // api call for agency carrier level data list
                                fecthAgencyCarrierLevelDataList(carrierIdsObject)

                                setAgencyCarrierDetails(data)
                                FlagApiLoader(false)
                            }
                            else {
                                if (res?.data?.error?.message) {
                                    console.log("GetAgencyCarrierData ERROR RESPONSE", res)
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: res?.data?.error?.message
                                    })
                                    FlagApiLoader(false)
                                }
                                else {
                                    console.log("GetAgencyCarrierData ERROR RESPONSE", res)
                                    UpdateSnackbarMessage({
                                        status: "error",
                                        message: "Something Went Wrong!"
                                    })
                                    FlagApiLoader(false)
                                }
                            }
                        },
                        (err) => {
                            console.log("GetAgencyCarrierData err>>>>", err)
                            FlagApiLoader(false)
                            UpdateSnackbarMessage({
                                status: "error",
                                message: err?.data?.error?.message
                            })
                        }
                    )
                } else {
                    if (res?.data?.error?.message) {
                        console.log("assign agency ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: res?.data?.error?.message
                        })
                        FlagApiLoader(false)
                    }
                    else {
                        console.log("assign agency ERROR RESPONSE", res)
                        UpdateSnackbarMessage({
                            status: "error",
                            message: "Something Went Wrong!"
                        })
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("AssignTemplateToAgency err>>>>", err)
                FlagApiLoader(false)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )
    }

    const fetchAgencyCarrierData = async () => {
        await GetAgencyCarrierData(agencyId,
            (res) => {
                if (res.data.status) {
                    const data = res?.data?.data?.[0]
                    setAgencyCarrierDetails(data)
                    FlagApiLoader(false)
                    setEditAgencyCarrier(true)
                    setFlagEditCarrerDialog(false)
                } else {
                    console.log("GetAgencyCarrierData Response", res)
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                    setFlagEditCarrerDialog(false)
                    FlagApiLoader(false)
                    setEditAgencyCarrier(true)
                }
            },
            (err) => {
                console.log("GetAgencyCarrierData Error Response ", err)
                FlagApiLoader(false)
                setFlagEditCarrerDialog(false)
                setEditAgencyCarrier(true)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
            }
        )
    }

    const handleEditCarrier = () => {

        let payload = {
            agency_id: agencyId,
            carrier_type_id: carrierTypeAndId?.carrierTypeId
        }
        // console.log("payload", payload);

        FlagApiLoader(true)
        RemoveTemplateToAgencyAPI(payload,
            (res) => {
                console.log("response of remove template agency", res);
                if (res?.data?.status) {
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message,
                    });
                    setSelectedTemplate({
                        ...selectedTemplate,
                        template_id: null,
                        carrier_type_id: null,
                        template_name: null
                    })
                    fetchAgencyCarrierData()
                    FlagApiLoader(false)
                }
                else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                UpdateSnackbarMessage({
                    status: "error",
                    message: "Something Went Wrong!",
                });
                FlagApiLoader(false)
                console.log("err of remove template agency", err);
            })
    }

    const handleClearAllData = () => {

        // carrierTypeAndId is like carrier flag life or annuity
        // const carrierTypeFlag = carrierTypeAndId == "life" ? true : false

        const deleteAgCAssignObj = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.filter(data => data?.agent_commission_carrier_assignment_id != null)
        const deleteAllAgCommissionCarriers = deleteAgCAssignObj.map(data => ({
            assignIds: data?.agent_commission_carrier_assignment_id,
            carrierIds: data?.carrier_id
        }))
        const updateAgencyCarrierLevelDataList = {
            ...agencyCarrierLevelList,
            [carrierTypeAndId?.carrierTypeName]: []
        }
        setAgencyCarrierLevelList(updateAgencyCarrierLevelDataList)
        setCarrierCheckBoxes(prevState => ({
            ...prevState,
            [carrierTypeAndId?.carrierTypeName]: prevState?.[carrierTypeAndId?.carrierTypeName]?.map(carrier => ({ ...carrier, alreadyChecked: false }))
        }))
        setDeleteAgCommissionCarriers(prevState => ({
            ...prevState,
            [carrierTypeAndId?.carrierTypeName]: [...deleteAllAgCommissionCarriers]
        }))
        setDeleteAgCommissionCarrierAssignId(deleteAgCommissionCarrierAssignId)
        setFlagClearAllCarriersDialog(false)
        setCarrierDataToRederTable((prevState) => ({
            ...prevState,
            [carrierTypeAndId?.carrierTypeName]: []
        }))
        setCheckedItems(currentState => ({
            ...currentState,
            [carrierTypeAndId?.carrierTypeName]: {}
        }))
        setCheckedCarrierItem(currentState => ({
            ...currentState,
            [carrierTypeAndId?.carrierTypeName]: {}
        }))
    }
    const handleSaveButton = () => {

        setTableRowId({})

        const payload = {
            agency_id: parseInt(agencyId),
            carrier_type_id: carrierTypeAndId?.carrierTypeId,
            deleted_agent_commission_carrier_assignment_id: deleteAgCommissionCarriers?.[carrierTypeAndId?.carrierTypeName]?.map(data => data.assignIds),
            agent_carrier_assignment_data: carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((cL_data) => {
                const carrier_level_data = [
                    ...cL_data?.carrier_ba_level_name,
                    ...cL_data?.carrier_direct_level_name,
                    ...cL_data?.dl_level
                ]
                return {
                    carrier_id: cL_data?.carrier_id,
                    agent_commission_carrier_assignment_id: cL_data?.agent_commission_carrier_assignment_id,
                    carrier_level_data: carrier_level_data.filter(data => data?.carrier_level_name !== "" && data?.carrier_level_name !== undefined && data?.carrier_level_name !== null)
                }
            })
        }

        FlagApiLoader(true)
        CreateUpdateAgencyCarrierDataAPI(
            payload,
            async (res) => {
                if (res?.data?.status) {
                    UpdateSnackbarMessage({
                        status: "success",
                        message: res?.data?.data?.message
                    })
                    await fetchAgencyCarrierData()

                    const initialStates = carrierTypeDetailsForAgency?.reduce((object, carrier) => {
                        const carrierName = carrier?.carrier_type_name
                        object.objectOfObject[carrierName] = {}
                        object.arrayOfObject[carrierName] = []
                        object.nullCarriers[carrierName] = null
                        return object
                    }, { arrayOfObject: {}, objectOfObject: {}, nullCarriers: {} })
                    const { objectOfObject, arrayOfObject, nullCarriers } = initialStates

                    // HERE HJ UPDATED DUE TO ERROR OCCURS WHILE CONTINUE PRESS
                    setDeleteAgCommissionCarriers({ ...arrayOfObject })
                    // setDeleteAgCommissionCarriers({ Life: {}, Annuity: {}, Health: {} })
                    FlagApiLoader(false)
                    // setCheckedItems({ ...checkedCarrierItem })
                } else {
                    if (res?.data?.error?.message) {
                        UpdateSnackbarMessage({ status: "error", message: res?.data?.error?.message });
                        FlagApiLoader(false)
                    }
                    else {
                        UpdateSnackbarMessage({ status: "error", message: "Something Went Wrong!" });
                        FlagApiLoader(false)
                    }
                }
            },
            (err) => {
                console.log("CreateUpdateAgencyCarrierDataAPI ERROR", err)
                UpdateSnackbarMessage({
                    status: "error",
                    message: err?.data?.error?.message
                })
                FlagApiLoader(false)
            }

        )
    }

    const handleOpenAssignDialog = (event) => {
        setFlagAssignTemplateDialog(true)
        setSelectedAssignTemplate(event.target.value);
    }

    // const handleAssignDetails = () => {
    //     // const apiCreadentials = {
    //     //     agency_id: parseInt(agencyId),
    //     //     template_id: selectedAssignTemplate.template_id,
    //     //     carrier_type_id: selectedAssignTemplate.carrier_type_id,
    //     // };
    //     // const values = {
    //     //     template_id: selectedAssignTemplate.template_id,
    //     //     carrier_type_id: selectedAssignTemplate.carrier_type_id,
    //     //     template_name: selectedTemplate.template_name,
    //     // }
    //     // handleAssignTemplateToAgency(apiCreadentials);
    //     // setSelectedTemplate({
    //     //     ...selectedTemplate, values

    //     // });
    //     // setFlagAssignTemplateDialog(false);

    // }

    const handleAssignDetails = async () => {
        const apiCreadentials = {
            agency_id: parseInt(agencyId),
            template_id: selectedAssignTemplate.template_id,
            carrier_type_id: selectedAssignTemplate.carrier_type_id,
        };

        try {
            let response = await handleAssignTemplateToAgency(apiCreadentials);

            setSelectedTemplate({
                template_id: selectedAssignTemplate.template_id,
                carrier_type_id: selectedAssignTemplate.carrier_type_id,
                template_name: selectedAssignTemplate.template_name,
            });

            setSelectedAssignTemplate(null);
            setFlagAssignTemplateDialog(false)
        }
        catch (error) {
            console.error("Error assigning template:", error);
        }
        finally {
            setFlagAssignTemplateDialog(false);
        }
    };

    return (
        <>
            <GlobalDialog
                open={alertUnsavedChanges}
                data={<GlobalAlertPopUp
                    image={deleteAlert}
                    className="ac-unsaved-popup"
                    primaryText="You have unsaved changes. If you leave without saving, your changes will be lost."
                    primaryButtonName="Yes"
                    secondaryButtonName='No'
                    primaryButtonVarient="outlined"
                    primaryButtonColor="error"
                    secondaryButtonVarient="contained"
                    onClose={() => setAlertUnsavedChanges(false)}
                />}
            />

            {
                flagAssignTemplate &&
                <GlobalDialog
                    open={flagAssignTemplate}
                    data={<GlobalAlertPopUp
                        image={deleteAlert}
                        className="ac-unsaved-popup"
                        primaryText="Are you sure you want to assign a template?"
                        secondaryText="Note: you will lose all the selected carriers"
                        secondaryTextClassName="ac-note-text"
                        primaryButtonName="Yes"
                        secondaryButtonName='No'
                        primaryButtonVarient="outlined"
                        leftButtonClassName="ac-yes-btn"
                        // primaryButtonColor="error"
                        secondaryButtonVarient="contained"
                        onClose={() => setAlertUnsavedChanges(false)}
                    />}
                />
            }

            {
                flagClearAllCarriersDialog &&
                <GlobalDialog
                    open={flagClearAllCarriersDialog}
                    data={<GlobalAlertPopUp
                        image={deleteAlert}
                        className="ac-unsaved-popup"
                        primaryText="Are you sure you want to clear all carriers?"
                        secondaryText="Note: All carrier list will be removed"
                        secondaryTextClassName="ac-carrier-list-note-text"
                        primaryButtonName="Yes"
                        secondaryButtonName='No'
                        primaryButtonVarient="outlined"
                        leftButtonClassName="ac-yes-btn"
                        // primaryButtonColor="error"
                        secondaryButtonVarient="contained"
                        onClickPrimary={() => {
                            handleClearAllData()
                        }}
                        onClickSecondary={() => {
                            setFlagClearAllCarriersDialog(false)
                        }}
                        onClose={() => setFlagClearAllCarriersDialog(false)}
                    />}
                />
            }

            {
                flagDeleteCarrierRow &&
                <GlobalDialog
                    open={flagDeleteCarrierRow}
                    data={<GlobalAlertPopUp
                        image={deleteAlert}
                        className="ac-unsaved-popup"
                        primaryText="Are you sure you want to delete United Home Life?"
                        secondaryTextClassName="ac-carrier-list-note-text"
                        primaryButtonName="Yes"
                        secondaryButtonName='No'
                        primaryButtonVarient="outlined"
                        leftButtonClassName="ac-yes-btn"
                        // primaryButtonColor="error"
                        secondaryButtonVarient="contained"
                        onClose={() => setAlertUnsavedChanges(false)}
                    />}
                />
            }

            {
                flagEditCarrerDialog &&
                <GlobalDialog
                    open={flagEditCarrerDialog}
                    data={
                        <GlobalAlertPopUp
                            image={deleteAlert}
                            className="ac-unsaved-popup"
                            primaryText="Are you sure you want to edit carriers?"
                            // secondaryText={`Note: {} template assignment will be removed.`}
                            secondaryText={`Note: ${selectedTemplate?.template_name ? `${selectedTemplate?.template_name} template assignment will be removed.` : "template assignment will be removed."}`}
                            secondaryTextClassName="ac-carrier-list-note-text"
                            primaryButtonName="Yes"
                            secondaryButtonName='No'
                            primaryButtonVarient="outlined"
                            leftButtonClassName="ac-yes-btn"
                            secondaryButtonVarient="contained"
                            onClickPrimary={() => {
                                handleEditCarrier()
                            }}
                            onClickSecondary={() => {
                                setFlagEditCarrerDialog(false)
                            }}
                            onClose={() => setFlagEditCarrerDialog(false)}
                        />}
                />
            }

            {
                flagAssignTemplateDialog &&
                <GlobalDialog
                    open={flagAssignTemplateDialog}
                    data={<GlobalAlertPopUp
                        image={deleteAlert}
                        className="ac-unsaved-popup"
                        primaryText={`Are you sure you want to change to ${selectedAssignTemplate?.template_name ? selectedAssignTemplate.template_name : ''}?`}
                        secondaryText={`Note: ${selectedTemplate?.template_name ? `${selectedTemplate?.template_name} template assignment will be removed` : "All carriers will be removed"}`}
                        secondaryTextClassName="ac-carrier-list-note-text"
                        primaryButtonName="Yes"
                        secondaryButtonName='No'
                        primaryButtonVarient="outlined"
                        leftButtonClassName="ac-yes-btn"
                        secondaryButtonVarient="contained"
                        onClickPrimary={() => {
                            handleAssignDetails()
                        }}
                        onClickSecondary={() => {
                            setFlagAssignTemplateDialog(false)
                        }}
                        onClose={() => setFlagAssignTemplateDialog(false)}
                    />}
                />
            }


            <Grid container className="ac-main-container">
                {/*___________HEADER_________*/}
                <Grid item xs={12} sx={{ padding: "5px 0px" }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container sx={{ alignItems: "center", height: "100%" }}>
                                <Typography sx={{ fontSize: "16px" }}>
                                    Carrier List
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container className='ac-life-annually-container'>
                                <Grid item className="carrier-type-btn-wrapper" sx={{ marginRight: '8px !important' }}>
                                    {carrierTypeDetailsForAgency?.map((carrier) => {
                                        const carrierName = carrier.carrier_type_name
                                        const carrier_type_id = carrier?.carrier_type_id
                                        const carrierColor = carrier?.carrier_type_color
                                        const presentingCarrier = carrierName == carrierTypeAndId?.carrierTypeName
                                        return (
                                            <Box sx={{ display: "inline-flex" }} key={carrier_type_id}>
                                                <Box
                                                    className="agency-mask-box"
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        key={carrier_type_id}
                                                        disableRipple
                                                        variant="text"
                                                        sx={{
                                                            color: presentingCarrier ? "#000000" : "#000000",
                                                            background: presentingCarrier ? "#ffffff" : "#EFEFF0",
                                                            boxShadow: presentingCarrier ? "0px 0px 4px 0px #00000040" : "none",
                                                            marginRight: '1px',
                                                            "&:hover": {
                                                                backgroundColor: presentingCarrier ? "#ffffff" : "#EFEFF0",
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            if (presentingCarrier) {
                                                                return
                                                            }
                                                            const matchedCarrierData = agencyCarrierDetails?.agency_commission_data?.find(data => data?.carrier_type_id === carrier_type_id)
                                                            // console.log('matchedCarrierData', matchedCarrierData)
                                                            setEditAgencyCarrier(false)
                                                            setCarrierTypeAndId({ carrierTypeName: carrierName, carrierTypeId: carrier_type_id })
                                                            setShowCarrierPopUp(false)
                                                            setSelectedTemplate({
                                                                template_id: matchedCarrierData?.template_id,
                                                                carrier_type_id: matchedCarrierData?.carrier_type_id,
                                                                template_name: matchedCarrierData?.template_name
                                                            })
                                                        }}
                                                    >
                                                        {carrierName}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Grid>
                                {/* <Box className="mask-box">
                                    {carrierTypeDetailsForAgency?.map((carrier) => {
                                        const carrierName = carrier.carrier_type_name
                                        const carrier_type_id = carrier?.carrier_type_id
                                        const carrierColor = carrier?.carrier_type_color
                                        const presentingCarrier = carrierName === carrierTypeAndId?.carrierTypeName
                                        return (
                                            <Button key={carrier_type_id} variant="outlined"
                                                className={`${presentingCarrier ? 'selected-life-button' : ''} sliderButton`}
                                                onClick={() => {
                                                    if (presentingCarrier) {
                                                        return
                                                    }
                                                    const matchedCarrierData = agencyCarrierDetails?.agency_commission_data?.find(data => data?.carrier_type_id === carrier_type_id)
                                                    console.log('matchedCarrierData', matchedCarrierData)
                                                    setEditAgencyCarrier(false)
                                                    setCarrierTypeAndId({ carrierTypeName: carrierName, carrierTypeId: carrier_type_id })
                                                    setShowCarrierPopUp(false)
                                                    setSelectedTemplate({
                                                        template_id: matchedCarrierData?.template_id,
                                                        carrier_type_id: matchedCarrierData?.carrier_type_id,
                                                        template_name: matchedCarrierData?.template_name
                                                    })
                                                }}
                                            >
                                                {carrierName}
                                            </Button>
                                        )
                                    })}                               
                                </Box> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*___________DIVIDER_______*/}
                <Grid item xs={12} marginTop={"8px"}><Divider /></Grid>

                {/*__________TABLE___________ */}
                <Grid item xs={12} className="ac-main-container-tble-item" >
                    <Grid container className="ac-main-table-container">
                        <Grid item xs={12} >
                            <Grid container className="ac-table-header-container" >
                                <Grid item>
                                    <Grid container className="ac-life-annuity-select-container">
                                        <Grid item sx={{ marginBottom: "4px", marginRight: "10px" }}>
                                            <Typography component={"span"} className="ac-life-annuity-typo">
                                                {carrierTypeAndId?.carrierTypeName}
                                            </Typography>
                                            <Typography component={"span"} className="ac-template-typo">
                                                | Template
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <FormControl variant="standard" sx={{ minWidth: 180 }}>
                                                <InputLabel className="ac-select-input-label" shrink={false}>
                                                    {selectedTemplate.template_id == null || editAgencyCarrier ? "Select Template" : selectedTemplate.template_name}
                                                </InputLabel>
                                                <Select
                                                    className="ac-select-template"
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    value={selectedTemplate?.template_id || ""}
                                                    inputProps={{
                                                        className: "select-input"
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            className: 'ac-table-select-paper'
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        if (carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName].length != 0) {
                                                            handleOpenAssignDialog(e)
                                                        }
                                                        else {
                                                            const apiCreadentials = {
                                                                agency_id: parseInt(agencyId),
                                                                template_id: e.target.value.template_id,
                                                                carrier_type_id: e.target.value.carrier_type_id,
                                                            }
                                                            handleAssignTemplateToAgency(apiCreadentials)
                                                        }
                                                    }}
                                                >
                                                    {
                                                        agencyTemplates?.length === 0 ?
                                                            <MenuItem disabled>
                                                                No Templates Found
                                                            </MenuItem>
                                                            :
                                                            agencyTemplates.map((template, ind) => {
                                                                const isSelected = selectedTemplate && selectedTemplate.template_id == template.template_id;
                                                                return (
                                                                    <MenuItem
                                                                        key={ind}
                                                                        value={{
                                                                            template_id: template.template_id,
                                                                            carrier_type_id: template.carrier_type_id,
                                                                            template_name: template.template_name
                                                                        }}
                                                                        sx={{
                                                                            pointerEvents: isSelected ? "none" : "auto",
                                                                            background: isSelected ? "aliceblue" : "transparent"
                                                                        }}
                                                                    >
                                                                        {template.template_name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                </Select>
                                            </FormControl>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {((!apiLoader && (selectedTemplate.template_id == null || editAgencyCarrier) && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0)) ?
                                        (
                                            !showCarrierPopUp &&
                                            <Button variant="contained"
                                                onClick={() => {
                                                    setFlagClearAllCarriersDialog(true)
                                                    // setEditAgencyCarrier(false)
                                                    // setTableRowId(null)
                                                }}
                                            >
                                                Clear All
                                            </Button>
                                        )
                                        :
                                        (
                                            (!apiLoader && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0) &&
                                            <Button variant="contained" onClick={() => {
                                                setFlagEditCarrerDialog(true)
                                            }}>
                                                Edit Carrier
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ height: "calc(100% - 51px)", position: "relative" }}>

                            {
                                showCarrierPopUp &&
                                <Box className="ac-select-carrier-popover">
                                    <SelectCarrierPopUp
                                        carrierCheckBoxes={carrierCheckBoxes?.[carrierTypeAndId?.carrierTypeName]}
                                        checkedItems={checkedCarrierItem}
                                        maxLevelLength={maxLevelLength}
                                        section={carrierTypeAndId?.carrierTypeName}
                                        alreadyCheckedKey={alreadyCheckedKey}
                                        deleteAgCommissionCarriers={deleteAgCommissionCarriers?.[carrierTypeAndId?.carrierTypeName]}
                                        setCarrierCheckBoxes={setCarrierCheckBoxes}
                                        setCheckedCarrierItem={setCheckedCarrierItem}
                                        setDeleteAgCommissionCarriers={setDeleteAgCommissionCarriers}
                                        setShowCarrierPopUp={setShowCarrierPopUp}
                                        setCarrierDataToRederTable={setCarrierDataToRederTable}
                                    />
                                </Box>
                            }
                            <Grid container className="ac-grid-table-container">
                                <Grid item xs={12} sx={{
                                    maxHeight: editAgencyCarrier || (selectedTemplate.template_id == null && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0) ? "calc(100% - 92px)" : "100%",
                                    minHeight: editAgencyCarrier || (selectedTemplate.template_id == null && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0) ? "calc(100% - 92px)" : "100%",
                                }}>
                                    {
                                        carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length == 0 ?
                                            <Grid container className="ac-new-carrier-button-container">
                                                <Button variant="contained" startIcon={<AddIcon />}
                                                    onClick={() => setShowCarrierPopUp(true)}
                                                >
                                                    New Carrier
                                                </Button>
                                            </Grid>
                                            :
                                            <TableContainer className="ac-table-container">
                                                <Table className="ac-table">
                                                    <TableHead>
                                                        <TableRow className="ac-table-head-row">
                                                            <TableCell className="ac-width-first-cell ac-table-head-cell ac-sticky-first-cell ">
                                                                #
                                                            </TableCell>
                                                            <TableCell className="ac-width-carrier ac-table-head-cell ac-sticky-carrier ">
                                                                Carrier
                                                            </TableCell>
                                                            <TableCell className="ac-width-ba-level ac-table-head-cell ac-sticky-ba-level">
                                                                BA Level
                                                            </TableCell>
                                                            <TableCell className="ac-width-direct-carrier ac-table-head-cell ac-sticky-direct-carrier ac-border-right">
                                                                Direct Carrier<span>*</span>
                                                            </TableCell>
                                                            {
                                                                Array.from({ length: maxLevelLength }).map((rr, ind) => {
                                                                    return (
                                                                        <TableCell key={ind} className={`ac-width-common-for-all ac-table-head-cell`}>
                                                                            DL
                                                                        </TableCell>
                                                                    );
                                                                })
                                                            }
                                                            {
                                                                ((!apiLoader && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0 && selectedTemplate.template_id == null) || editAgencyCarrier) &&
                                                                <TableCell className="ac-table-head-cell ac-sticky-pencil-btn ac-width-pencil-btn ">
                                                                    {/* Pencil Button*/}
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((data, index) => {
                                                                const rowId = data?.carrier_id

                                                                const selectedTableRowId = tableRowId[carrierTypeAndId?.carrierTypeName]
                                                                const carrierLevelsObj = agencyCarrierLevelList?.[carrierTypeAndId?.carrierTypeName]?.filter(carriers => carriers?.carrier_id == rowId)
                                                                console.log("carrierLevelsObj", carrierLevelsObj);

                                                                // const carrierLevelsObj = agencyCarrierLevelList?.[carrierTypeAndId?.carrierTypeName]?.find(carriers => carriers?.carrier_id == rowId)
                                                                // let bALevelList = carrierLevelsObj?.carrier_direct_level_data?.filter(data => data?.carrier_level_color_hex_code === "#000000")
                                                                // if (bALevelList?.length === 0) {
                                                                //     bALevelList = carrierLevelsObj?.carrier_direct_level_data
                                                                // }
                                                                // const directLevelList = carrierLevelsObj?.carrier_direct_level_data?.filter(data => data?.carrier_level_color_hex_code !== "#000000")
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell className="ac-width-first-cell ac-table-body-cell ac-sticky-first-cell">
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell className="ac-width-carrier ac-table-body-cell ac-sticky-carrier ">
                                                                            {data.carrier_name}
                                                                        </TableCell>
                                                                        <TableCell className="ac-width-ba-level ac-table-body-cell ac-sticky-ba-level">
                                                                            {
                                                                                selectedTableRowId == rowId ?
                                                                                    (<FormControl variant="standard">
                                                                                        <Select className="ac-table-select" IconComponent={KeyboardArrowDownIcon}
                                                                                            value={data.carrier_ba_level_name?.length != 0 && data.carrier_ba_level_name[0]?.carrier_level_name}
                                                                                            displayEmpty={true}
                                                                                            inputProps={{
                                                                                                className: "select-input"
                                                                                            }}
                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    className: 'ac-table-select-paper'
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                const carrierLevelName = e.target.value
                                                                                                const ba_Level = carrierLevelsObj[0]?.carrier_ba_level_data?.find(data => data?.carrier_level_name == carrierLevelName)
                                                                                                // 
                                                                                                const updatedCarrierLevels = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((obj_data) => {
                                                                                                    if (obj_data?.carrier_name == carrierLevelsObj[0]?.carrier_name) {
                                                                                                        return ({
                                                                                                            ...obj_data,
                                                                                                            carrier_ba_level_name: [{
                                                                                                                // agent_commission_carrier_assignment_level_id: null,
                                                                                                                carrier_level_name: ba_Level?.carrier_level_name,
                                                                                                                carrier_ba_level_flag: true,
                                                                                                                carrier_direct_level_flag: false,
                                                                                                                carrier_level_sequence: ba_Level?.carrier_level_sequence,
                                                                                                                carrier_level_id: ba_Level?.carrier_level_id
                                                                                                            }]
                                                                                                        })
                                                                                                    }
                                                                                                    return obj_data
                                                                                                })
                                                                                                setCarrierDataToRederTable(prevState => ({
                                                                                                    ...prevState,
                                                                                                    [carrierTypeAndId?.carrierTypeName]: [...updatedCarrierLevels]
                                                                                                }))
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem value={""}>None</MenuItem>
                                                                                            {
                                                                                                carrierLevelsObj[0]?.carrier_ba_level_data?.map(level => {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            key={level.carrier_level_id}
                                                                                                            value={level.carrier_level_name}
                                                                                                        >
                                                                                                            {level.carrier_level_name}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Select>
                                                                                    </FormControl>)
                                                                                    :
                                                                                    (data?.carrier_ba_level_name.length != 0 ? data?.carrier_ba_level_name[0]?.carrier_level_name : "")
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell className="ac-width-direct-carrier ac-table-body-cell ac-sticky-direct-carrier ac-border-right ">
                                                                            {
                                                                                selectedTableRowId == rowId ?
                                                                                    (<FormControl variant="standard">
                                                                                        <Select className="ac-table-select" IconComponent={KeyboardArrowDownIcon}
                                                                                            value={data?.carrier_direct_level_name?.length != 0 && data?.carrier_direct_level_name[0]?.carrier_level_name}
                                                                                            displayEmpty
                                                                                            inputProps={{
                                                                                                className: "select-input"
                                                                                            }}
                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    className: 'ac-table-select-paper'
                                                                                                }
                                                                                            }}
                                                                                            onChange={(e) => {
                                                                                                const carrierLevelName = e.target.value
                                                                                                const emptyCarrierLevelObj = {
                                                                                                    carrier_ba_level_flag: null,
                                                                                                    carrier_direct_level_flag: null,
                                                                                                    carrier_level_name: "",
                                                                                                    template_carrier_assignment_level_id: null,
                                                                                                    template_level_sequence: null,
                                                                                                    carrier_level_id: ""

                                                                                                }
                                                                                                if (carrierLevelName === '') {
                                                                                                    const updatedCarrierLevels = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((obj_data) => {

                                                                                                        const updatedl_Levels = obj_data?.dl_level?.filter(data => data?.carrier_level_name != "")

                                                                                                        if (obj_data?.carrier_id == rowId) {
                                                                                                            return {
                                                                                                                ...obj_data,
                                                                                                                carrier_direct_level_name: [],
                                                                                                                dl_level: []
                                                                                                            }
                                                                                                        } else {
                                                                                                            return {
                                                                                                                ...obj_data,
                                                                                                                dl_level: [...updatedl_Levels]
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                    // HERE CALCULATING THE WHICH DL LEVEL MAX COLUMNS.
                                                                                                    const updateMaxLength = updatedCarrierLevels?.reduce((max, data) => {
                                                                                                        return Math.max(max, data?.dl_level?.filter(data => data?.carrier_level_name != "").length)
                                                                                                    }, 10)
                                                                                                    // ADDING PADDING TO OTHER DL LEVEL TO MAKE EVERYONE SAME.
                                                                                                    const paddedCarrierLevelData = updatedCarrierLevels?.map(carrier => ({
                                                                                                        ...carrier,
                                                                                                        dl_level: [...carrier?.dl_level, ...Array(updateMaxLength - carrier?.dl_level?.length).fill(emptyCarrierLevelObj)]
                                                                                                    }))

                                                                                                    setMaxLevelLength(updateMaxLength)
                                                                                                    setCarrierDataToRederTable(prevState => ({
                                                                                                        ...prevState,
                                                                                                        [carrierTypeAndId?.carrierTypeName]: [...paddedCarrierLevelData]
                                                                                                    }))


                                                                                                    return
                                                                                                }
                                                                                                const dc_Index = carrierLevelsObj[0]?.carrier_direct_level_data?.findIndex(data => data.carrier_level_name == carrierLevelName)
                                                                                                const dC_Obj = carrierLevelsObj[0]?.carrier_direct_level_data[dc_Index]
                                                                                                const menuItemCarrierLevels = carrierLevelsObj[0]?.carrier_direct_level_data

                                                                                                let updatedDlLevel = [...menuItemCarrierLevels?.slice(dc_Index + 1,)].map(menu_cl_data => ({
                                                                                                    // agent_commission_carrier_assignment_level_id: null,
                                                                                                    carrier_level_name: menu_cl_data?.carrier_level_name,
                                                                                                    carrier_ba_level_flag: false,
                                                                                                    carrier_direct_level_flag: false,
                                                                                                    carrier_level_sequence: menu_cl_data?.carrier_level_sequence,
                                                                                                    carrier_level_id: menu_cl_data?.carrier_level_id
                                                                                                }))

                                                                                                const updateDirectCarrierLevel = {
                                                                                                    // agent_commission_carrier_assignment_level_id: null,
                                                                                                    carrier_level_name: dC_Obj?.carrier_level_name,
                                                                                                    // carrier_ba_level_flag: dC_Obj?.carrier_ba_level_flag,
                                                                                                    carrier_ba_level_flag: false,
                                                                                                    carrier_direct_level_flag: true,
                                                                                                    carrier_level_sequence: dC_Obj?.carrier_level_sequence,
                                                                                                    carrier_level_id: dC_Obj?.carrier_level_id
                                                                                                }


                                                                                                const updatedCarrierLevels = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((obj_data) => {

                                                                                                    const updatedl_Levels = obj_data?.dl_level?.filter(data => data?.carrier_level_name != "")

                                                                                                    if (obj_data?.carrier_name == carrierLevelsObj[0]?.carrier_name) {
                                                                                                        return {
                                                                                                            ...obj_data,
                                                                                                            carrier_direct_level_name: [{ ...updateDirectCarrierLevel }],
                                                                                                            dl_level: [...updatedDlLevel]
                                                                                                        }
                                                                                                    } else {
                                                                                                        return {
                                                                                                            ...obj_data,
                                                                                                            dl_level: [...updatedl_Levels]
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                                const updateMaxLength = updatedCarrierLevels?.reduce((max, data) => {
                                                                                                    return Math.max(max, data?.dl_level?.filter(data => data?.carrier_level_name != "").length)
                                                                                                }, 10)
                                                                                                const paddedCarrierLevelData = updatedCarrierLevels?.map(carrier => ({
                                                                                                    ...carrier,
                                                                                                    dl_level: [...carrier?.dl_level, ...Array(updateMaxLength - carrier?.dl_level?.length).fill(emptyCarrierLevelObj)]
                                                                                                }))
                                                                                                setMaxLevelLength(updateMaxLength)
                                                                                                setCarrierDataToRederTable(prevState => ({
                                                                                                    ...prevState,
                                                                                                    [carrierTypeAndId?.carrierTypeName]: [...paddedCarrierLevelData]
                                                                                                }))
                                                                                            }}
                                                                                        >
                                                                                            <MenuItem value={""}>None</MenuItem>
                                                                                            {
                                                                                                carrierLevelsObj[0]?.carrier_direct_level_data?.map(level => {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            key={level.carrier_level_name}
                                                                                                            value={level.carrier_level_name}
                                                                                                        >
                                                                                                            {level.carrier_level_name}
                                                                                                        </MenuItem>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Select>
                                                                                    </FormControl>)
                                                                                    :
                                                                                    (data.carrier_direct_level_name.length != 0 ? data.carrier_direct_level_name[0].carrier_level_name : "")
                                                                            }
                                                                        </TableCell>
                                                                        {
                                                                            data?.dl_level?.map((level, dl_ind) => {
                                                                                return (
                                                                                    <TableCell key={dl_ind} className={`ac-width-common-for-all ac-table-body-cell `}>
                                                                                        {
                                                                                            selectedTableRowId == rowId ?
                                                                                                (<FormControl variant="standard">
                                                                                                    <Select className="ac-table-select" IconComponent={KeyboardArrowDownIcon}
                                                                                                        value={level.carrier_level_name != "" && level.carrier_level_name}
                                                                                                        displayEmpty
                                                                                                        inputProps={{
                                                                                                            className: "select-input"
                                                                                                        }}
                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                className: 'ac-table-select-paper'
                                                                                                            }
                                                                                                        }}
                                                                                                        onChange={(e) => {
                                                                                                            const carrierLevelName = e.target.value
                                                                                                            const emptyCarrierLevelObj = {
                                                                                                                carrier_ba_level_flag: null,
                                                                                                                carrier_direct_level_flag: null,
                                                                                                                carrier_level_name: "",
                                                                                                                template_carrier_assignment_level_id: null,
                                                                                                                template_level_sequence: null,
                                                                                                                carrier_level_id: null
                                                                                                            }
                                                                                                            if (carrierLevelName != "") {
                                                                                                                const dL_Level = carrierLevelsObj[0]?.carrier_direct_level_data?.find(data => data.carrier_level_name == carrierLevelName)
                                                                                                                console.log(carrierLevelsObj[0], "dL_Level")

                                                                                                                const updatedCarrierLevels = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map((cL_data) => {

                                                                                                                    if (cL_data.carrier_name == carrierLevelsObj[0]?.carrier_name) {
                                                                                                                        const dl_levelUpdating = cL_data.dl_level
                                                                                                                        dl_levelUpdating[dl_ind] = {
                                                                                                                            // agent_commission_carrier_assignment_level_id: null,
                                                                                                                            carrier_level_name: dL_Level?.carrier_level_name,
                                                                                                                            carrier_ba_level_flag: false,
                                                                                                                            carrier_direct_level_flag: false,
                                                                                                                            carrier_level_sequence: dL_Level?.carrier_level_sequence,
                                                                                                                            carrier_level_id: dL_Level?.carrier_level_id,
                                                                                                                        }
                                                                                                                        return ({
                                                                                                                            ...cL_data,
                                                                                                                            dl_level: dl_levelUpdating
                                                                                                                        })
                                                                                                                    }
                                                                                                                    return cL_data

                                                                                                                })
                                                                                                                setCarrierDataToRederTable(prevState => ({
                                                                                                                    ...prevState,
                                                                                                                    [carrierTypeAndId?.carrierTypeName]: [...updatedCarrierLevels]
                                                                                                                }))
                                                                                                            } else {
                                                                                                                const updatedCarrierLevels = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.map(carrierItem => {

                                                                                                                    const updatedl_Levels = carrierItem?.dl_level?.filter(data => data?.carrier_level_name != "")

                                                                                                                    if (carrierItem?.carrier_id == rowId) {
                                                                                                                        updatedl_Levels?.splice(dl_ind, 1)
                                                                                                                        return {
                                                                                                                            ...carrierItem,
                                                                                                                            dl_level: updatedl_Levels
                                                                                                                        }
                                                                                                                    }
                                                                                                                    return {
                                                                                                                        ...carrierItem,
                                                                                                                        dl_level: updatedl_Levels
                                                                                                                    }
                                                                                                                })
                                                                                                                const updateMaxLength = updatedCarrierLevels?.reduce((max, data) => {
                                                                                                                    return Math.max(max, data?.dl_level?.filter(data => data.carrier_level_name != "").length)
                                                                                                                }, 10)
                                                                                                                const paddedCarrierLevelData = updatedCarrierLevels?.map(carrier => ({
                                                                                                                    ...carrier,
                                                                                                                    dl_level: [...carrier.dl_level, ...Array(updateMaxLength - carrier?.dl_level?.length).fill(emptyCarrierLevelObj)]
                                                                                                                }))
                                                                                                                setMaxLevelLength(updateMaxLength)
                                                                                                                setCarrierDataToRederTable(prevState => ({
                                                                                                                    ...prevState,
                                                                                                                    [carrierTypeAndId?.carrierTypeName]: [...paddedCarrierLevelData]
                                                                                                                }))
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <MenuItem value={""}>None</MenuItem>
                                                                                                        {
                                                                                                            carrierLevelsObj[0]?.carrier_direct_level_data?.map(level => {
                                                                                                                return (
                                                                                                                    <MenuItem
                                                                                                                        key={level.carrier_level_name}
                                                                                                                        value={level.carrier_level_name}
                                                                                                                    >
                                                                                                                        {level.carrier_level_name}
                                                                                                                    </MenuItem>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </Select>
                                                                                                </FormControl>)
                                                                                                :
                                                                                                level.carrier_level_name
                                                                                        }
                                                                                    </TableCell>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            ((!apiLoader && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0 && selectedTemplate.template_id == null) || editAgencyCarrier) &&
                                                                            <TableCell className="ac-width-pencil-btn ac-table-body-cell ac-sticky-pencil-btn ">
                                                                                <Grid container sx={{ justifyContent: "space-between" }}>
                                                                                    {
                                                                                        selectedTableRowId == rowId ?
                                                                                            <IconButton className="ac-table-body-pencil-btn" onClick={() => {
                                                                                                setTableRowId((prevState) => { return { ...prevState, [carrierTypeAndId?.carrierTypeName]: null } })
                                                                                            }} >
                                                                                                <DoneIcon className="ac-icon" />
                                                                                            </IconButton>
                                                                                            :
                                                                                            <IconButton className="ac-table-body-pencil-btn" onClick={() => {
                                                                                                setTableRowId((prevState) => { return { ...prevState, [carrierTypeAndId?.carrierTypeName]: rowId } })
                                                                                            }} >
                                                                                                <CreateIcon />
                                                                                            </IconButton>
                                                                                    }
                                                                                    <IconButton
                                                                                        aria-label="more"
                                                                                        id="long-button"
                                                                                        aria-controls={open ? 'long-menu' : undefined}
                                                                                        aria-expanded={open ? 'true' : undefined}
                                                                                        aria-haspopup="true"
                                                                                        onClick={handleClick}
                                                                                        size="small"
                                                                                        sx={{ padding: "2px" }}
                                                                                    >
                                                                                        <MoreVertIcon
                                                                                            style={{ padding: "0px" }}
                                                                                            onClick={(event) => {
                                                                                                setAnchorEl(event.currentTarget);
                                                                                                setDeleteCarrierFromTableID(rowId)
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <Menu
                                                                                        id="long-menu"
                                                                                        MenuListProps={{ 'aria-labelledby': 'long-button', }}
                                                                                        onClose={() => setAnchorEl(null)}
                                                                                        anchorEl={anchorEl}
                                                                                        open={open}
                                                                                        keepMounted
                                                                                        PaperProps={{
                                                                                            style: {
                                                                                                boxShadow: "none",
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        <MenuItem
                                                                                            style={{
                                                                                                fontSize: "16px", fontWeight: "500",
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                handleClose()
                                                                                                let deleteAg_C_C_AssignId
                                                                                                let deleteAg_C_CarrierId
                                                                                                let updateCheckedItems = { ...checkedCarrierItem[carrierTypeAndId?.carrierTypeName] }
                                                                                                const emptyCarrierLevelObj = {
                                                                                                    carrier_ba_level_flag: null,
                                                                                                    carrier_direct_level_flag: null,
                                                                                                    carrier_level_name: "",
                                                                                                    template_carrier_assignment_level_id: null,
                                                                                                    template_level_sequence: null
                                                                                                }
                                                                                                // removing the empty objects from the dl level array.
                                                                                                const removedPaddedObjectFromLevelArray = carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName].map(carrierItem => {
                                                                                                    const updated_dl_Levels = carrierItem?.dl_level?.filter(data => data?.carrier_level_name != "")
                                                                                                    return { ...carrierItem, dl_level: updated_dl_Levels }
                                                                                                })
                                                                                                // deleted require dl leve from the array
                                                                                                const filteredCarrierLevelData = removedPaddedObjectFromLevelArray?.filter((data) => {
                                                                                                    if (data.carrier_id != deleteCarrierFromTableID) {
                                                                                                        return data
                                                                                                    } else {
                                                                                                        deleteAg_C_C_AssignId = data.agent_commission_carrier_assignment_id
                                                                                                        deleteAg_C_CarrierId = data.carrier_id
                                                                                                        delete updateCheckedItems?.[data.carrier_id]
                                                                                                    }
                                                                                                })
                                                                                                // finding which dl have max length, because we dont want any extra column when dl length>10.
                                                                                                // therefore we need to update max length. 
                                                                                                const updateMaxLength = filteredCarrierLevelData?.reduce((max, data) => {
                                                                                                    return Math.max(max, data?.dl_level?.filter(data => data.carrier_level_name != "").length)
                                                                                                }, 10)
                                                                                                //here adding require padding to the dl level who have less dl level to match the length with every one.
                                                                                                const paddedCarrierLevelData = filteredCarrierLevelData?.map(carrier => ({
                                                                                                    ...carrier,
                                                                                                    dl_level: [...carrier.dl_level, ...Array(updateMaxLength - carrier?.dl_level?.length).fill(emptyCarrierLevelObj)]
                                                                                                }))
                                                                                                setMaxLevelLength(updateMaxLength)
                                                                                                setCarrierDataToRederTable(prevState => ({
                                                                                                    ...prevState, [carrierTypeAndId?.carrierTypeName]: [...paddedCarrierLevelData]
                                                                                                }))
                                                                                                setCheckedCarrierItem(currentState => ({
                                                                                                    ...currentState, [carrierTypeAndId?.carrierTypeName]: { ...updateCheckedItems }
                                                                                                }))
                                                                                                setCarrierCheckBoxes(prevState => ({
                                                                                                    ...prevState,
                                                                                                    [carrierTypeAndId?.carrierTypeName]: prevState[carrierTypeAndId?.carrierTypeName].map((carrier) => {
                                                                                                        if (carrier.carrier_id == deleteCarrierFromTableID) {
                                                                                                            return ({ ...carrier, alreadyChecked: false })
                                                                                                        } else { return carrier }
                                                                                                    })
                                                                                                }))
                                                                                                if (deleteAg_C_C_AssignId != null) {
                                                                                                    // console.log('deleteAgCommissionCarriers', setDeleteAgCommissionCarriers)
                                                                                                    setDeleteAgCommissionCarriers((prevState) => {
                                                                                                        const existingEntries = prevState?.[carrierTypeAndId?.carrierTypeName] || [];
                                                                                                        return {
                                                                                                            ...prevState,
                                                                                                            [carrierTypeAndId?.carrierTypeName]: [
                                                                                                                ...existingEntries,
                                                                                                                { assignIds: deleteAg_C_C_AssignId, carrierIds: deleteAg_C_CarrierId }
                                                                                                            ]
                                                                                                        };
                                                                                                    });
                                                                                                }
                                                                                            }}
                                                                                        >Delete</MenuItem>
                                                                                    </Menu>
                                                                                </Grid>
                                                                            </TableCell>
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                    }
                                </Grid>
                                {
                                    ((!apiLoader && (selectedTemplate.template_id == null || editAgencyCarrier) && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0)) &&
                                    <Grid item xs={12}>
                                        <Button variant="text" startIcon={<AddIcon />}
                                            onClick={() => {
                                                setShowCarrierPopUp(true)
                                                setTableRowId({ life: null, annuity: null })
                                            }}
                                        >
                                            Add New Carrier
                                        </Button>
                                    </Grid>
                                }
                                {
                                    ((!apiLoader && (selectedTemplate.template_id == null || editAgencyCarrier) && carrierDataToRenderTable?.[carrierTypeAndId?.carrierTypeName]?.length != 0)) &&
                                    < Grid item xs={12} sx={{ maxHeight: "39px" }}>
                                        <Grid container className='ac-button-container'>

                                            <Button
                                                size='large'
                                                variant="outlined"
                                                sx={{ width: "140px", fontWeight: "500 !important", padding: "6px 22px" }}
                                                onClick={() => navigate('/agency')}
                                            >
                                                Cancel
                                            </Button>


                                            <Button
                                                size='large'
                                                variant="contained"
                                                sx={{ width: "140px", fontWeight: "400 !important", padding: "6px 22px" }}
                                                onClick={handleSaveButton}
                                            >
                                                Save
                                            </Button>

                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}

export default AgencyCarrier;