import { Close } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react'


const CarrierPDFViewerDialog = (props) => {

    const { setFlagPdfPopup, imageUrl } = props
    return (
        <>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign={"end"} sx={{ margin: "10px 10px 0px 0px" }}>
                    <Close sx={{ cursor: "pointer", }} onClick={() => { setFlagPdfPopup(false); }} width="100%" />
                </Grid>
                <Grid item xs={10}>
                    <iframe style={{ height: "100vh", width: "100%", }} src={imageUrl} title="Doc" />
                </Grid>
            </Grid>
        </>
    )
}

export default CarrierPDFViewerDialog