import React from 'react'

const Symboles = (props) => {

    const { symboleName } = props

    return (
        <>
            {symboleName == "astrics" &&
                <span style={{ color: "#FF0000" }}>*</span>
            }
        </>
    )
}

export default Symboles