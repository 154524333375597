import React, { useContext } from 'react'
import { Context as authContext } from "../../../contexts/reducerContexts/authContext";
import { ClickAwayListener, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';


const ViewCarrierPreviewDialog = (props) => {

    const { userDetail } = useContext(authContext).state
    const {
        editDetails,
        flagAfterLevelPopoverRender,
        flagCustomizationCarriarSectionDialog,
        flagCarrierCustomizationGlobalDeleteDialog,
        deleteRowPopupFlag,
        isSelectedRow,
        hideTransparentCell,
        flagCarrierCustomizationDeleteDialog,
        selectedRowGroupIdOfDialog,
        setFlagLevelTablePopup,
        setSelectedRowGroupOfDialog,
        carrierDetails,
        rightSideHeaders,
        leftSideHeaders,
        userRoleAdmin,
        userRoleMember,
        agencyNameBrokersAlliance,
        brokersAllianceAgent,
    } = props

    const generateTooltipContent = (criteria) => {
        if (!criteria || criteria.length == 0) return "";
        const criteriaMap = {};

        criteria?.forEach((crit) => {
            const { criteria_name, carrier_product_criteria_data } = crit;

            if (!criteriaMap[criteria_name]) {
                criteriaMap[criteria_name] = [];
            }

            switch (criteria_name) {
                case "Sub Type":
                    const subtypeValue = carrier_product_criteria_data?.map((data) => data.criteria_field_value)?.join(", ");
                    criteriaMap[criteria_name]?.push(subtypeValue);
                    break;

                case "Issue Age":
                    const ageOrYearValues = carrier_product_criteria_data?.map(element => {
                        if (element?.criteria_field_name == "From Age") {
                            return element?.criteria_field_value
                        }
                        else {
                            return (
                                element?.criteria_field_option_value == "Not Applicable" ? "" :
                                    element?.criteria_field_option_value == "Lifetime" ? "+" :
                                        element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
                                            " "
                            );
                        }
                    })
                    criteriaMap[criteria_name]?.push(ageOrYearValues?.join(""));
                    break;

                case "Renewal Year":
                    // Handle Issue Age and Renewal Year.
                    const ageOrYearValues1 = carrier_product_criteria_data?.map(element => {
                        if (element?.criteria_field_name == "Start Year") {
                            return element?.criteria_field_value
                        }
                        else {
                            return (
                                element?.criteria_field_option_value == "Not Applicable" ? "" :
                                    element?.criteria_field_option_value == "Lifetime" ? "+" :
                                        element?.criteria_field_option_value == "Custom Text Field" ? " to " + element?.criteria_field_value :
                                            " "
                            );
                        }
                    })
                    criteriaMap[criteria_name]?.push(ageOrYearValues1.join(""));
                    break;

                case "State":
                    // Handle State.
                    const stateValues = carrier_product_criteria_data?.filter((data) => data?.criteria_field_option_value)

                    if (stateValues?.filter(element => element?.criteria_field_option_value == "Select All")?.length) {
                        criteriaMap[criteria_name].push("All Available");
                    } else {
                        criteriaMap[criteria_name].push(stateValues?.filter((val) => val)?.map(element => element?.criteria_field_option_value).join(", "));
                    }
                    break;
                default:
            }
        });

        // OLD CODE BFR TABLE
        // const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
        //   const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
        //   return `
        //       <div style="margin-bottom: 5px">
        //         <table>
        //           <thead>
        //           <tbody>
        //             <tr>
        //             <td>
        //               <span
        //               class="input-span"
        //               style="font-size: 13px;
        //               font-weight: 500 !important;
        //               color: #A0A0A0;
        //               margin-bottom: 5px"
        //           >
        //             ${capitalizedKey}:
        //              </span>
        //             </td>
        //             <td>
        //               <span
        //               style="font-size: 13px;
        //               font-weight: 500 !important;
        //               color: #FFFFFF;"
        //           >
        //             ${values.join(", ")}
        //           </span>
        //             </td>
        //             </tr>
        //           </tbody>
        //         </table>
        //       </div>
        //   `;
        // });

        const criteriaDescriptions = Object.entries(criteriaMap)?.map(([key, values]) => {
            const capitalizedKey = key?.charAt(0)?.toUpperCase() + key?.slice(1)?.toLowerCase();
            return `
    <div style="margin-bottom: 5px">
      <table>
        <thead></thead> <!-- Empty thead for structure -->
        <tbody>
          <tr>
            <td
             style="
                text-overflow: ellipsis; 
                padding-right: 10px; 
                vertical-align: baseline;
                min-width: 98px;
              "
            >
              <span
                class="input-span"
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #A0A0A0;
                margin-bottom: 5px"
              >
                ${capitalizedKey}:
              </span>
            </td>
            <td>
              <span
                style="font-size: 13px;
                font-weight: 500 !important;
                color: #FFFFFF;"
              >
                ${values.join(", ")} <!-- Combine all values into one row -->
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      `;
        });
        return criteriaDescriptions.join("")
    }

    const generateInputContent = (criteria) => {
        if (!criteria || criteria.length === 0) return "";

        const criteriaMap = {};

        criteria.forEach((crit) => {
            const { criteria_name, carrier_product_criteria_data } = crit;

            if (!criteriaMap[criteria_name]) {
                criteriaMap[criteria_name] = [];
            }

            switch (criteria_name) {
                case "Sub Type":
                    // Handle Sub Type directly using criteria_field_value.
                    const subtypeValue = carrier_product_criteria_data
                        .map((data) => data.criteria_field_value)
                        .join(", ");
                    criteriaMap[criteria_name].push(subtypeValue);
                    break;

                case "Issue Age":
                    const issueAgeValues = carrier_product_criteria_data.map((rr) => {
                        if (rr.criteria_field_name == "From Age") {
                            return rr.criteria_field_value;
                        } else {
                            return rr.criteria_field_option_value === "Not Applicable"
                                ? ""
                                : rr.criteria_field_option_value === "Lifetime"
                                    ? "+"
                                    : rr.criteria_field_option_value === "Custom Text Field"
                                        ? " to " + rr.criteria_field_value
                                        : " ";
                        }
                    });
                    criteriaMap[criteria_name].push(issueAgeValues.join(""));
                    break;

                case "Renewal Year":
                    const renewalYearValues = carrier_product_criteria_data.map((rr) => {
                        if (rr.criteria_field_name == "Start Year") {
                            return rr.criteria_field_value;
                        } else {
                            return rr.criteria_field_option_value === "Not Applicable"
                                ? ""
                                : rr.criteria_field_option_value === "Lifetime"
                                    ? "+"
                                    : rr.criteria_field_option_value === "Custom Text Field"
                                        ? " to " + rr.criteria_field_value
                                        : " ";
                        }
                    });
                    criteriaMap[criteria_name].push(renewalYearValues.join(""));
                    break;

                case "State":
                    const stateValues = carrier_product_criteria_data.filter(
                        (data) => data.criteria_field_option_value
                    );
                    if (
                        stateValues.filter((rr) => rr.criteria_field_option_value == "Select All")
                            .length
                    ) {
                        criteriaMap[criteria_name].push("All Available");
                    } else {
                        criteriaMap[criteria_name].push(
                            stateValues
                                .filter((val) => val)
                                .map((rr) => rr.criteria_field_option_value)
                                .join(", ")
                        );
                    }
                    break;

                default:
                    break;
            }
        });

        // Convert the map into a readable format.
        const criteriaDescriptions = Object.entries(criteriaMap).map(([key, values]) => {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
            return `${capitalizedKey}: ${values.join(", ")}`;
        });

        return criteriaDescriptions.join(" | ");
    };



    return (
        <>
            <Grid item xs={12} textAlign="center">
                <Grid container className="" sx={{ justifyContent: "center", padding: { xs: "10px", sm: "20px" } }}>
                    <Grid item xs={12} textAlign={"end"} marginBottom={{ xs: "5px", sm: "10px" }}>
                        <Close
                            sx={{ cursor: "pointer" }}
                            width="100%"
                            onClick={() => {
                                setFlagLevelTablePopup(false)
                                setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: null }))
                            }}
                        />
                    </Grid>
                    <TableContainer
                        className=""
                        sx={{ height: "80vh" }}
                        component={Paper}
                    >
                        <Table
                            className="templateCarrierTable"
                            aria-label="a dense table"
                            size="small"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell className="header-color sticky-table-head ltp_sticky-carrier-table-head-1" sx={{ padding: "6px 8px" }} >
                                        Product
                                    </TableCell>

                                    <TableCell className="header-color sticky-table-head ltp_sticky-carrier-table-head-2">
                                        Criteria
                                    </TableCell>

                                    <TableCell className="header-color sticky-table-head ltp_sticky-carrier-table-head-3"
                                        sx={{
                                            borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0" : "",
                                        }}
                                    >
                                        Notes
                                    </TableCell>

                                    {/* <TableCell className="header-color sticky-table-head ltp_sticky-carrier-table-head-4">
                                        Sub Name
                                    </TableCell> */}

                                    {(userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance ?
                                        <TableCell
                                            // className="header-color sticky-table-head ltp_sticky-carrier-table-head-5"
                                            className="header-color sticky-table-head ltp_sticky-carrier-table-head-4"
                                            sx={{
                                                borderRight: "2px solid white",
                                                // HERE UPDATE BG COLOR FOR THIS COLUMN 
                                                backgroundColor: "#F1C232 !important",
                                            }}
                                        >
                                            BA Total Comp
                                        </TableCell>
                                        :
                                        <>
                                            {/* HERE I COMMENTED CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                            {/* {agencyNameBrokersAlliance && */}
                                            <>
                                                {leftSideHeaders?.map((leftCell, leftIndex) => {
                                                    return (
                                                        <TableCell
                                                            className="header-color"
                                                            sx={{
                                                                minWidth: '100px',
                                                                maxWidth: '100px',
                                                                borderRight: leftIndex == leftSideHeaders?.length - 1 ? "2px solid white" : "",
                                                                background: `${leftCell?.carrier_level_color_hex_code} !important`
                                                            }}
                                                        >
                                                            {leftCell?.carrier_level_name}
                                                        </TableCell>
                                                    )
                                                })}
                                            </>
                                            {/* } */}
                                            {/* HERE I COMMENTED CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                        </>
                                    }

                                    {
                                        (userRoleAdmin || userRoleMember) ?
                                            carrierDetails?.carrier_level?.map((levelRow, i) => {
                                                return (
                                                    <TableCell
                                                        className="header-color"
                                                        align="left"
                                                        stickyHeader
                                                        sx={{ minWidth: '60px', background: `${levelRow?.carrier_level_color_hex_code} !important` }}
                                                    >
                                                        {levelRow?.carrier_level_name}
                                                    </TableCell>
                                                );
                                            })
                                            :
                                            <>
                                                {
                                                    <>
                                                        {rightSideHeaders?.map((rightCell, i) => {
                                                            return (
                                                                <TableCell
                                                                    className="header-color"
                                                                    align="left"
                                                                    stickyHeader
                                                                    sx={{
                                                                        minWidth: '60px',
                                                                        backgroundColor: `${rightCell?.carrier_level_color_hex_code} !important`,
                                                                        borderRight: (i === 0) ? '2px solid white' : ""
                                                                    }}
                                                                >
                                                                    {rightCell?.carrier_level_name}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </>
                                                }
                                            </>
                                    }

                                    {1000 - carrierDetails?.carrier_level?.length * 60 >= 60 &&
                                        <TableCell
                                            id="blank-tablecell"
                                            className={`sticky-table-head ${editDetails ? "addLeft_Border" : ""}`}
                                            sx={{
                                                width: `${1000 - carrierDetails?.carrier_level?.length * 60}px`,
                                                backgroundColor: flagAfterLevelPopoverRender || flagCustomizationCarriarSectionDialog || flagCarrierCustomizationDeleteDialog ? "#525963" : deleteRowPopupFlag ? "#8a9099 " : "#1b2430",
                                                color: "#ffffff"
                                            }}
                                            colSpan={5}
                                        >
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <ClickAwayListener onClickAway={() => setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: null }))} >
                                <TableBody>
                                    {carrierDetails?.carrier_product?.map((groups) => {
                                        return (
                                            groups?.carrier_product_data?.map((groupData, i) => {
                                                let flagSaprator = groups?.carrier_product_data.length == i + 1 ? true : false;
                                                let leftSideData = groupData?.carrier_product_commission_data?.filter((obj) => obj.carrier_ba_level_flag == true)
                                                let rightSideData = groupData?.carrier_product_commission_data?.filter((obj) => !obj.carrier_ba_level_flag)


                                                const selectedGroupId = groups?.carrier_product_id || groups.carrier_group_id_custom
                                                const sectedRowId = groups?.carrier_product_data[i].carrier_product_data_id || groups?.carrier_product_data[i].carrier_group_data_id_custom
                                                const carrierGroupDataLen = groups?.carrier_product_data?.length
                                                let present = groups?.carrier_product_id || groups?.carrier_group_id_custom
                                                return (
                                                    <TableRow
                                                        className={`
                                                            vc-table-row 
                                                            ${selectedGroupId == selectedRowGroupIdOfDialog.selectedId ? carrierGroupDataLen <= 1 ? "border-all border-container" :
                                                                `${i === 0 ? "border-top" : flagSaprator ? "border-bottom" : "bordered-left-right"} border-container` :
                                                                sectedRowId == isSelectedRow ? "border-all border-container" : ""
                                                            }`
                                                        }
                                                        onClick={() => {
                                                            setSelectedRowGroupOfDialog((prevId) => ({ ...prevId, selectedId: selectedGroupId }))
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                                                            className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-1"
                                                        >
                                                            {groupData?.carrier_product_name?.length > 18 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        placement="right-end"
                                                                        title={groupData?.carrier_product_name}
                                                                        disableHoverListener={groupData?.carrier_product_name?.length < 18}
                                                                        disableFocusListener={groupData?.carrier_product_name?.length < 18}
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, cursor: 'default' },
                                                                        }}
                                                                    >
                                                                        <div className='vc-dialog-product-tooltip-styles'>
                                                                            {groupData?.carrier_product_name}
                                                                        </div>
                                                                    </Tooltip>
                                                                ) :
                                                                <div style={{ cursor: 'default' }}>
                                                                    {groupData?.carrier_product_name}
                                                                </div>
                                                            }
                                                        </TableCell>

                                                        {/* <TableCell
                                                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", }}
                                                            className={`sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-2`}
                                                        >
                                                            {groupData?.carrier_product_state?.length > 18 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={groupData?.carrier_product_state}
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, cursor: 'default' },
                                                                        }}
                                                                        placement="bottom"
                                                                    >
                                                                        <span style={{ cursor: 'default' }}>
                                                                            {groupData?.carrier_product_state}
                                                                        </span>
                                                                    </Tooltip>
                                                                ) :
                                                                <span style={{ cursor: 'default' }}>
                                                                    {groupData?.carrier_product_state}
                                                                </span>
                                                            }
                                                        </TableCell> */}

                                                        <TableCell
                                                            sx={{ borderBottom: flagSaprator ? "7px solid #EFEFF0" : "", cursor: "pointer" }}
                                                            className={`sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-2`}

                                                        // className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-3"
                                                        >
                                                            {groupData?.carrier_product_criteria?.length > 0 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        placement="right"
                                                                        title={
                                                                            groupData?.carrier_product_criteria?.length > 0 ?
                                                                                (<div dangerouslySetInnerHTML={{ __html: generateTooltipContent(groupData?.carrier_product_criteria) }} />) : (<></>)
                                                                        }
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100 },
                                                                        }}
                                                                    >
                                                                        <div className='vc-dialog-product-tooltip-styles' style={{ maxWidth: "150px", display: "inline-block", border: "none" }}>{generateInputContent(groupData?.carrier_product_criteria)}</div>
                                                                    </Tooltip>
                                                                ) :
                                                                (<div className='vc-dialog-product-tooltip-styles' style={{ maxWidth: "150px", display: "inline-block", border: "none", }}>{generateInputContent(groupData?.carrier_product_criteria)}</div>)
                                                            }
                                                        </TableCell>

                                                        <TableCell
                                                            sx={{
                                                                borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                // borderRight: leftSideData?.length == 0 ? "2px solid #EFEFF0" : ""
                                                                borderRight: (userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() != "brokers alliance" || brokersAllianceAgent) ? "2px solid #EFEFF0" : ""
                                                            }}
                                                            className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-3"
                                                        // className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-4"
                                                        >
                                                            {groupData?.carrier_product_note?.replace(/->/g, "").trim()?.length > 30 ?
                                                                (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                        PopperProps={{
                                                                            className: 'vc-tabel-tooltip',
                                                                            style: { zIndex: 2100, cursor: 'default' },
                                                                        }}
                                                                        placement="right-end"
                                                                    >
                                                                        <div className="vc-dialog-product-tooltip-styles" style={{ cursor: 'default' }}>
                                                                            {groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div style={{ cursor: 'default' }}>
                                                                        {groupData?.carrier_product_note?.replace(/->/g, "").trim()}
                                                                    </div>
                                                                )
                                                            }
                                                        </TableCell>

                                                        {((userRoleAdmin || userRoleMember) && agencyNameBrokersAlliance) ?
                                                            (
                                                                <TableCell
                                                                    sx={{
                                                                        borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                        borderRight: "2px solid #EFEFF0"
                                                                    }}
                                                                    className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-4"
                                                                // className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-5"
                                                                >
                                                                    {groupData?.carrier_product_ba_total_comp?.length > 13 ?
                                                                        (
                                                                            <Tooltip
                                                                                arrow
                                                                                title={groupData?.carrier_product_ba_total_comp}
                                                                                PopperProps={{
                                                                                    className: 'vc-tabel-tooltip',
                                                                                    style: { zIndex: 2100, cursor: 'default' },
                                                                                }}
                                                                                placement="right-end"
                                                                            >
                                                                                <div className="vc-dialog-product-tooltip-styles" style={{ cursor: 'default' }}>
                                                                                    {groupData?.carrier_product_ba_total_comp}
                                                                                </div>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <div style={{ cursor: 'default' }}>
                                                                                {groupData?.carrier_product_ba_total_comp}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                                                    {/* {userDetail[0]?.user_agency_data?.agency_name?.trim()?.toLowerCase() == "brokers alliance" && */}
                                                                    <>
                                                                        {leftSideData?.map((leftDataCell) => {
                                                                            return (
                                                                                <TableCell
                                                                                    sx={{
                                                                                        padding: "0px 15px !important",
                                                                                        borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                                        borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : ""
                                                                                    }}
                                                                                    // className="sticky-table-color ltp_sticky-carrier-table-body-5"
                                                                                    className="sticky-table-body sticky-table-color ltp_sticky-carrier-table-body-5"
                                                                                >
                                                                                    {leftDataCell?.carrier_product_commission_value}
                                                                                </TableCell>
                                                                            )
                                                                        })}
                                                                    </>
                                                                    {/* } */}
                                                                    {/* HERE I REMOVE CONDITION REASON : WHEN I ENTER IN THE AGENCY THEN BA LEVEL IS NOT APPEARED THAT'S WHY */}
                                                                </>
                                                            )
                                                        }

                                                        {(userDetail[0]?.user_role_name == "Admin" || userDetail[0]?.user_role_name == "Member") ?
                                                            // THIS IS BODY DATA OF ADMIN AND MEMBER
                                                            groupData?.carrier_product_commission_data?.map((commissionData) => {
                                                                return (
                                                                    <TableCell
                                                                        sx={{
                                                                            padding: "0px 15px !important",
                                                                            borderBottom: flagSaprator ? "7px solid #EFEFF0" : ""
                                                                        }}
                                                                    >
                                                                        {commissionData?.carrier_product_commission_value}
                                                                    </TableCell>
                                                                )
                                                            })
                                                            :
                                                            // THIS IS BODY DATA OF AGENT 
                                                            rightSideData?.map((rightDataCell, i) => {
                                                                return (
                                                                    <TableCell
                                                                        sx={{
                                                                            padding: "0px 15px !important",
                                                                            borderBottom: flagSaprator ? "7px solid #EFEFF0" : "",
                                                                            borderRight: i == 0 ? "2px solid #EFEFF0" : ""
                                                                        }}
                                                                    >
                                                                        {rightDataCell?.carrier_product_commission_value}
                                                                    </TableCell>
                                                                )
                                                            })
                                                        }
                                                        {

                                                            ((carrierDetails?.carrier_level?.length > 0 || carrierDetails?.carrier_level?.length == 0) && (1000 - carrierDetails?.carrier_level?.length * 60 >= 60)) &&
                                                            (<TableCell
                                                                className={`sticky-table-body sticky-table-color table-left-padding-tableCell ${editDetails ? "addLeft_Border add_padding" : ""}`}
                                                                colSpan={5}
                                                                sx={{
                                                                    borderBottom: (!editDetails && flagSaprator) || (!hideTransparentCell && flagSaprator) ? "7px solid #EFEFF0" : "",
                                                                    borderRight: leftSideData?.length - 1 == i ? "2px solid #EFEFF0" : "",
                                                                    width: `${1000 - carrierDetails?.carrier_level?.length * 60}px `
                                                                }}
                                                            >

                                                            </TableCell>)
                                                        }
                                                    </TableRow>
                                                )
                                            }))
                                    })}
                                </TableBody>
                            </ClickAwayListener>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}


export default ViewCarrierPreviewDialog