import { Button, Grid } from '@mui/material'
import React from 'react'
import "../../css/CarrierTable.css"
import deleteAlert from '../../../assets/Delete-alert.png'
import { Close } from '@mui/icons-material'


const DeleteResourceAlertDialog = (props) => {

    const { deleteResourceData, setFlagDeletePopup, DeleteResouceFile } = props

    return (
        <>
            <Grid item xs={12}>
                <Grid container className="delete-main-container">
                    <Grid item xs={12} sx={{ paddingRight: "15px" }} textAlign={"end"}>
                        <Close sx={{ cursor: "pointer" }} onClick={() => { setFlagDeletePopup(false) }} />
                    </Grid>
                    <Grid item xs={12} className="delete-alert-item">
                        <img src={deleteAlert}></img>
                    </Grid>
                    <Grid item xs={12} className="delete-mesg-item">
                        Are you sure you want to delete resource {deleteResourceData?.file_detail?.carrier_resource_file_name}?
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container className="delete-btn-container">
                            <Grid item xs={12}>
                                <Grid container className="popup-delete-button-container" >
                                    <Button
                                        variant="contained"
                                        sx={{ width: "100px" }}
                                        onClick={() => {
                                            DeleteResouceFile()
                                        }}
                                    >
                                        Yes
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: "100px" }}
                                        onClick={() => { setFlagDeletePopup(false); }}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}


export default DeleteResourceAlertDialog